import translations from './translations'

translations.add('en', {
  tids: 'TIDS:',
  icPlus: 'IC++',
  blended: 'Blended',
  transactionFees: 'Other Payment Schemes Fees',
  diners: 'Discover Global Network',
  baseLabel: 'Base fee (p.)',
  percentLabel: 'Percent fee (%)',
  percentPlaceholder: '0.00%',
  disableFee: (disabled: boolean) => `Tap to ${disabled ? 'disable' : 'enable'} fee`,
  discoverGlobalDescription: 'Charge per Transaction applies for all Discover Global Network cards accepted',
  amex: 'American Express Cards fee',
  amexDescription: 'Charge per Transaction applies for all American Express cards accepted',
  chinaUnionPay: 'UnionPay Cards fee',
  payByBank: 'Pay By Bank',
  payByBankDescription: 'Charge per Transaction for using Pay By Bank',
  chinaUnionPayDescription: 'Charge per Transaction applies for all UnionPay cards accepted',
  authorisationOkFee: 'Authorisation Fees',
  authorisationFee: 'Authorisation fee (per transaction) (£)',
  zeroPlaceholder: '0.00',
  authorisationOkFeeDescription: 'Fee applied in sending an authorisation request to a Payment Scheme.',
  nonTransactionFees: 'Non-transactional fees',
  refundFee: 'Refund fee (per instance) (£)',
  refundFeeDescription: 'Fee applied for processing of each instance of the Refund request',
  chargebackFee: 'Chargeback fee (per instance) (£)',
  chargebackFeeDescription:
    'Fee applied as soon as the Chargeback process has been initiated (does not apply to reported fraud).',
  pciFee: 'PCI Fee (£)',
  pciFeeDescription: 'Fee applied monthly for ongoing PCI compliance certification.',
  zashTitle: 'Zash EPOS',
  zashSolution: 'Zash Solution',
  zAndPayByBankApp: 'Zapp and the Pay by Bank app',
  zashApp: 'Zash App (monthly) (£)',
  zashAppDescription: 'Fee applied monthly for using the Zash App',
  paymentFacilitatingServices: 'Payment Facilitating Services',
  chargedAtOnce: 'A fee charged once',
  terminalPurchase: 'Terminal Purchase',
  regularCharges: 'Regular Charges (charged monthly)',
  airtimeServiceDescription: 'Monthly fee charged for Airtime (GPRS / 3G / 4G)',
  airtimeService: 'Airtime Service (£) (per terminal)',
  paymentTerminalSupportAndTransactionFeeDescription:
    'Monthly fee charged for terminal support, enabling the Merchant to accept Transactions',
  paymentTerminalSupportAndTransactionFee: 'Subscription Fee Per Month (per terminal)',
  gatewayServices: 'Gateway Services',
  other: 'Other Charges',
  gatewaySetUpFee: 'Gateway Set Up Fee',
  gatewaySetUpFeePerTransaction: 'Gateway Fees (per Transaction) (£)',
  feeForEachTransaction: 'The fee charged for each Transaction that is submitted via the Gateway Services',
  monthlyGatewayFee: 'Monthly Gateway Fee',
  monthlyGatewayFeeDescription: 'A fee charged on the monthly basis',
  minMonthlyServiceCharge: 'Minimum Monthly Service Charge (£)',
  minMonthlyServiceChargeDescription:
    'The minimum value the Merchant will be charged when credit and debit card Transaction Charges total less than per month.',
  headlineRate: 'Headline rate (%)',
  dnapConsumerCredit: 'Consumer Credit %',
  dnapConsumerDebit: 'Consumer Debit %',
  dnapCorporateCredit: 'Corporate Credit %',
  dnapCorporateDebit: 'Corporate Debit %',
  dnapConsumerCreditShort: 'Cons. Credit %',
  dnapConsumerDebitShort: 'Cons. Debit %',
  dnapCorporateCreditShort: 'Corp. Credit %',
  dnapCorporateDebitShort: 'Corp. Debit %',
  transactionType: 'Transaction Type (Merchant Services Charges (per Transaction) *)',
  cardScheme: 'Card Scheme',
  location: 'Location',
  corporateOrConsumer: 'Corporate or Consumer',
  cardType: 'Card Type',
  baseFee: 'Base fee (GBP)',
  percentFee: 'Percent fee (%)',
  save: 'Save',
  mcUkConsumerDebit: 'MasterCard UK only Consumer Debit',
  mcUkConsumerCredit: 'MasterCard UK only Consumer Credit',
  mcUkCorporateDebit: 'MasterCard UK only Corporate Debit',
  mcUkCorporateCredit: 'MasterCard UK only Corporate Credit',
  visaUkConsumerDebit: 'Visa UK only Consumer Debit',
  visaUkConsumerCredit: 'Visa	UK only	Consumer Credit',
  visaUkCorporateDebit: 'Visa UK only Corporate Debit',
  visaUkCorporateCredit: 'Visa UK only Corporate Credit',
  mcEuConsumerDebit: 'MasterCard Europe (non UK) Consumer Debit',
  mcEuConsumerCredit: 'MasterCard Europe (non UK) Consumer Credit',
  mcEuCorporateDebit: 'MasterCard Europe (non UK) Corporate Debit',
  mcEuCorporateCredit: 'MasterCard Europe (non UK) Corporate Credit',
  visaEuConsumerDebit: 'Visa Europe (non UK) Consumer Debit',
  visaEuConsumerCredit: 'Visa	Europe (non UK) Consumer Credit',
  visaEuCorporateDebit: 'Visa Europe (non UK) Corporate Debit',
  visaEuCorporateCredit: 'Visa Europe (non UK) Corporate Credit',
  mcIntConsumerDebit: 'MasterCard International (non EU, non UK) Consumer Debit',
  mcIntConsumerCredit: 'MasterCard International (non EU, non UK) Consumer Credit',
  mcIntCorporateDebit: 'MasterCard International (non EU, non UK) Corporate Debit',
  mcIntCorporateCredit: 'MasterCard International (non EU, non UK) Corporate Credit',
  visaIntConsumerDebit: 'Visa	International (non EU, non UK) Consumer Debit',
  visaIntConsumerCredit: 'Visa International (non EU, non UK) Consumer Credit',
  visaIntCorporateDebit: 'Visa International (non EU, non UK) Corporate Debit',
  visaIntCorporateCredit: 'Visa International (non EU, non UK) Corporate Credit',
  confirmationMessage: 'Please, confirm the following changes:',
  comment: 'Comment',
  settlementPeriod: 'Settlement Period (business days)',
  settlementFee: 'Settlement fee (per settlement) (£)',
  fasterFundingFee: 'Faster Funding Fee (per settlement) (£)',
  fasterFundingMonthly: 'Faster Funding (Monthly) (£)',
  settlementPeriodDescription: 'Remittance Timescale',
  settlementFeeDescription: 'Fee applied for processing of each Settlement',
  fasterFundingFeeDescription: 'Fee applied for reducing the Remittance Timescale to 1 Business Day',
  gatewayFee: 'Gateway fee (per transaction) (£)',
  openBanking: 'Open Banking',
  klarna: 'Klarna fee',
  chargebackFeeVisaGBP: 'VISA Chargeback Fee (GBP)',
  chargebackFeeMCGBP: 'MC Chargeback Fee (GBP) ',
  chargebackFeeVisaEUR: 'VISA Chargeback Fee (EUR)',
  chargebackFeeMCEUR: 'MC Chargeback Fee (EUR)',
  declineAuthenticationFeeVisaGBP: 'VISA Decline Authentication Fee (GBP)',
  declineAuthenticationFeeMCGBP: 'MC Decline Authentication Fee (GBP)',
  declineAuthenticationFeeVisaEUR: 'VISA Decline Authentication Fee (EUR)',
  declineAuthenticationFeeMCEUR: 'MC Decline Authentication Fee (EUR)',
  monthlyFeeVisaEUR: 'VISA Monthly fee (EUR)',
  monthlyFeeMCEUR: 'MC Monthly fee (EUR)',
  refundFeeVisaGBP: 'VISA Refund Fee (GBP)',
  refundFeeMCGBP: 'MC Refund Fee (GBP)',
  refundFeeVisaEUR: 'VISA Refund Fee (EUR)',
  refundFeeMCEUR: 'MC Refund Fee (EUR)',
  retrievalRequestFeeVisaEUR: 'VISA Retrieval Request Fee (EUR)',
  retrievalRequestFeeMCEUR: 'MC Retrieval Request Fee (EUR)',
  transactionFeeVisaGBP: 'VISA Transaction Fee (GBP)',
  transactionFeeMCGBP: 'MC Transaction Fee (GBP)',
  transactionFeeVisaEUR: 'VISA Transaction Fee (EUR)',
  transactionFeeMCEUR: 'MC Transaction Fee (EUR)',
  wireFeeVisaEUR: 'VISA Wire Fee (EUR)',
  wireFeeMCEUR: 'MC Wire Fee (EUR)',
  contractRates: '* Contract rates',
  cardNotPresentFee: 'MOTO Fee (per transaction) (%)',
  effectiveFrom: 'Subscription Fee Per Month (Effective Date)',
  aliPay: 'Alipay',
  weeChat: 'WeChat',
  back: 'Back',
  serviceArea: 'Location',
  interchangeFee: 'Interchange',
  schemeFee: 'Scheme',
  dnaStandardFee: 'DNA fee',
  totalFee: 'Total fee',
  changeFromIcPlusToBlended: 'From: IC++ To: Blended',
  changeFromBlendedToIcPlus: 'From: Blended To: IC++'
})
