import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Card, Col, Row } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { onSelectMenu } from './utils'
import { tabs } from '~/code/pages/PortalAccess/constants'
import { hasPermissions } from '~/code/services/auth'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessTabKeyType } from '~/code/pages/PortalAccess/types/PortalAccessTabType'
import { PageHeader } from '~/code/components'
import {
  PortalAccessMerchantSelect,
  PortalAccessEmailInput,
  PortalAccessDrawer,
  PortalAccessUserEditDrawer,
  PortalAccessEditSecurityDrawer,
  PortalAccessTable,
  PortalAccessMenuDropdown
} from '~/code/pages/PortalAccess/components'

import translations from './translations'
import styles from './styles.scss'

export const PortalAccessStoreSymbol = Symbol('PortalAccessStore')

export const PortalAccess = observer(() => {
  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)
  const {
    activeTab,
    isLoading,
    selectedMerchant,
    loginsTableStore,
    merchantsTableStore,
    isMerchantsTabActive,
    merchants,
    isMerchantsLoading,
    isUserAccessDrawerOpen,
    isUserEditDrawerOpen,
    isSecurityDrawerOpen,
    searchLoginValue,
    resetTabs,
    setActiveTab,
    onMerchantSelect,
    setSearchMerchantValue,
    loadMerchants,
    setIsUserAccessDrawerOpen,
    closeUserAccessDrawer,
    closeUserEditDrawer
  } = store

  const isEmailFound = Boolean(merchantsTableStore?.data?.length)

  useEffect(() => () => resetTabs(), [])
  useEffect(() => resetTabs(), [activeTab])

  const renderAdd = () =>
    isMerchantsTabActive ? (
      <Button
        type='primary'
        disabled={!Boolean(selectedMerchant || !hasPermissions([PermissionMap.portalAccess.create]))}
        onClick={() => {
          store.loadStoresByMerchant(selectedMerchant?.id)
          setIsUserAccessDrawerOpen(true)
        }}
      >
        {translations().addLogin}
      </Button>
    ) : (
      <Button type='primary' onClick={() => setIsUserAccessDrawerOpen(true)} disabled={!isEmailFound}>
        {translations().addMerchant}
      </Button>
    )

  return (
    <>
      <div className={styles.head}>
        <PageHeader
          tabList={tabs}
          tabActiveKey={activeTab}
          onTabChange={(key: PortalAccessTabKeyType) => setActiveTab(key)}
        />
        <div className={styles.headButtons}>{renderAdd()}</div>
      </div>

      <Card className={styles.filterCard}>
        {isMerchantsTabActive ? (
          <Row>
            <Col sm={24} md={12}>
              <label>{translations().merchant}:</label>

              <PortalAccessMerchantSelect
                loading={isMerchantsLoading}
                merchants={merchants}
                className={styles.select}
                onSelect={onMerchantSelect}
                onClear={() => store.resetSelect()}
                value={selectedMerchant?.companyName}
                onSearch={v => {
                  setSearchMerchantValue(v)
                  loadMerchants()
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={22} md={12} xs={22}>
              <PortalAccessEmailInput />
            </Col>

            <Col sm={2} md={12} xs={2}>
              {isEmailFound && <PortalAccessMenuDropdown email={searchLoginValue} store={store} />}
            </Col>
          </Row>
        )}
      </Card>

      <Card className={styles.tableCard}>
        {isMerchantsTabActive ? (
          <PortalAccessTable
            type={PortalAccessTabKeyType.merchants}
            store={loginsTableStore as any}
            dataSource={loginsTableStore.viewData}
            loading={isLoading}
            onSelectMenu={(key, item) => onSelectMenu(key, item, store)}
          />
        ) : (
          <PortalAccessTable
            type={PortalAccessTabKeyType.logins}
            store={merchantsTableStore as any}
            dataSource={merchantsTableStore.viewData}
            loading={isLoading}
            onSelectMenu={(key, item) => onSelectMenu(key, item, store)}
          />
        )}
      </Card>

      <PortalAccessDrawer open={isUserAccessDrawerOpen} onClose={() => closeUserAccessDrawer()} />
      <PortalAccessUserEditDrawer open={isUserEditDrawerOpen} destroyOnClose onClose={() => closeUserEditDrawer()} />
      <PortalAccessEditSecurityDrawer open={isSecurityDrawerOpen} />
    </>
  )
})
