import { computed, makeObservable } from 'mobx'
import moment from 'moment'
import { injectable } from 'inversify'
import { PeriodType } from '~/code/models'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { AcquiringType, EcomPosType, TransactionTabType } from '../../models'
import { StoreMidTerminalFilterStore } from '~/code/stores/StoreMidTerminalFilterStore'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'
import { HandbooksStoreInjectable } from '~/code/stores/HandbooksStoreInjectable'
import { IAcquiringFilterStore } from './models'

@injectable()
export class AcquiringFilterStore implements IAcquiringFilterStore {
  acquiringType: AcquiringType
  acquiringEcomPosType: EcomPosType
  tab: TransactionTabType
  dateStore: RangePickerStore
  handbooksStore: HandbooksStoreInjectable
  storeAndTerminalFilterStore: StoreMidTerminalFilterStore
  merchantSelectStore: IMerchantSelectStore
  
  constructor(options: {
    acquiringTab: TransactionTabType,
    acquiringEcomPosType: EcomPosType,
    acquiringType: AcquiringType,
    handbooksStore: HandbooksStoreInjectable,
    merchantSelectStore: IMerchantSelectStore
  }) {
    this.tab = options.acquiringTab
    this.acquiringEcomPosType = options.acquiringEcomPosType
    this.acquiringType = options.acquiringType
    this.handbooksStore = options.handbooksStore
    this.merchantSelectStore = options.merchantSelectStore
    
    this.dateStore = new RangePickerStore()
    this.storeAndTerminalFilterStore = new StoreMidTerminalFilterStore(this, 'pos')

    makeObservable(this, {
      merchants: computed,
      merchantId: computed,
      merchantTradeName: computed,
      acquisitionChannelId: computed,
      acquisitionChannels: computed
    })

    this.init()
  }

  get merchants() {
    return this.merchantSelectStore.merchants
  }

  get merchantId() {
    return this.merchantSelectStore.merchant?.id
  }

  get merchantTradeName() {
    return this.merchantSelectStore.merchant?.tradeName
  }

  get acquisitionChannelId() {
    return this.merchantSelectStore.acquisitionChannel?.id
  }

  public get acquisitionChannels() {
    return this.merchantSelectStore.acquisitionChannels
  }

  public init = () => {
    if (this.tab === 'statement') {
      this.setPeriod(PeriodType.Day)
    } else {
      this.setPeriod(PeriodType.Yesterday)
    }
  }

  public setPeriod = (period: PeriodType) => {
    this.dateStore.setPeriod(period)
  }

  public setDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.dateStore.setDates(startDate, endDate)
  }
}
