import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Button, Skeleton } from 'antd'
import { ISettlementsStatusModalStore } from './ISettlementsStatusModalStore'
import { SettlementsStatusTable } from './components/SettlementsStatusTable'
import translations from './translations'
import styles from './SettlementStatus.scss'

export const SettlementsStatusModalStoreSymbol = Symbol('SettlementsStatusModalStore')
export const SettlementsStatusModal = observer(() => {
  const store = useInjection<ISettlementsStatusModalStore>(SettlementsStatusModalStoreSymbol)

  useEffect(() => {
    if (!store.storesDossierV2Store.isSettlementsLoading) store.init()
  }, [store.merchantsManagementStore.selectedMerchant, store.storesDossierV2Store.isSettlementsLoading])

  return store.isDataLoading ? (
    <Skeleton active />
  ) : (
    <div>
      <SettlementsStatusTable tableData={store.holdTableData} isLoading={store.isSubmitButtonLoading} />
      <div className={styles.buttonContainer}>
        <Button type='primary' loading={store.isSubmitButtonLoading} onClick={() => store.updateHoldStatus()}>
          {translations().submit}
        </Button>
      </div>
    </div>
  )
})
