import translations from './translations'

translations.add('en', {
  transactionVolume: 'Transactions volume, selected month',
  transactionCount: 'Transactions count, selected month',
  netRevenue: 'Net Revenue, selected month',
  totalCommission: 'Total Commission, selected month',
  order: 'Order',
  partner: 'Partner',
  trVol: 'Transactions volume, £',
  netRev: 'Net Revenue, £',
  comVol: 'Partner commissions volume, £',
  selectPartner: 'Select Partner',
  all: 'ALL',
  tableInfo: 'Partners commissions breakdown for the selected period',
  netRevMonthOnMonth: 'Net Revenue, month on month',
  selectMonthDate: 'Select year and month',
  totalCommissions: 'Total: partner commissions volume, £',
  commissionsVolume: 'Partner commissions volume, month on month',
  ok: 'OK',
  transactionsVolumeMonth: 'Transactions volume, month on month',
  downloadDetailedReport: 'Download detailed report',
  downloadSummaryReport: 'Download summary report',
  totalTransactions: 'Total: transactions volume, £',
  partnerCommission: 'Partner Commission, selected month'
})
