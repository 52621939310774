import React from 'react'
import { message } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { isEmpty } from 'dna-common'
import { hasPermissions } from '~/code/services/auth'
import { fetchPosBundlesById, fetchPosBundlesISO } from '~/code/services/fetchers'
import { PosBundlesResponseType } from '~/code/models'
import { CategoryType, ItemModel } from '~/code/models/handbooks'
import { CompleteApplicationStore } from './stores/CompleteApplicationStore'
import { CompanyNameFormStore } from './stores/CompanyNameFormStore'
import { StartProcessDataStore } from './stores/StartProcessDataStore'
import { ContactInfoStore } from './stores/ContactInfoStore'
import { SupportingDocumentsStore } from './stores/SupportingDocumentsStore'
import { PricingStore } from './stores/PricingStore'
import { ProductStore } from './stores/ProductStore'
import { BankAccountStore } from './stores/BankAccountStore'
import {
  DocusignMemberResponseType,
  LoadingPropertyType,
  OpportunityOwnerInfoResponseType,
  ParentStartApplicationStore,
  StoreItem,
  TabKey
} from './models'
import { MCCByIndustryResponseType } from './models/MCCByIndustryResponseType'
import { OnboardingSettingsResponseType } from './models/onboarding/OnboardingSettingsResponseType'
import {
  fetchDocusignSettings,
  fetchMccOptionsByIndustry,
  fetchOBQuestionnaireSettings,
  getPaymentSolution,
  isEquivalent,
  onboardingSettings,
  opportunityOwnerInfo
} from './services'
import translations from './translations'
import { BankInfo } from './models/BankInfo'

export class StartProcessStore {
  isInitiated: boolean = false

  supportingDocumentsStore = new SupportingDocumentsStore(this)
  completeApplicationStore = new CompleteApplicationStore(this)
  pricingStore = new PricingStore(this)
  dataStore: StartProcessDataStore = new StartProcessDataStore(this.supportingDocumentsStore, this)
  contactInfoStore = new ContactInfoStore(this)
  productStore = new ProductStore(this)
  companyNameFormStore = new CompanyNameFormStore(this)
  bankAccountStore = new BankAccountStore(this)

  activeTabKey: TabKey = 'start'
  public industry: string = ''
  isPosBundlesLoading: boolean = false
  bundleOptions: ItemModel[] = []
  bundlesForSelectedPos: PosBundlesResponseType[] = []
  selectedCategoryID: number = null
  selectedSubCategoryID: number = null
  isMCCOptionsLoading: boolean = false
  selectedMCCOptions: MCCByIndustryResponseType[] = []
  isOnboardingSettingsLoading: boolean = false
  onboardingSettings: OnboardingSettingsResponseType = {}
  docusignCredentials: DocusignMemberResponseType = null
  isDocusignSettingsLoading: boolean = false
  selectedOnboardingCountry: string = 'UK'
  isOppOwnerInfoLoading: boolean = false
  oppOwnerInfo: OpportunityOwnerInfoResponseType = null
  isOBQuestionnaireSettingsLoading: boolean = false
  oBQuestionnaireCredentials: DocusignMemberResponseType = null
  selectedSubCategoryPartnerRequired: boolean = false
  subscriptionPriceFeeForSelectedPos: string = ''
  aliPayEnabled: boolean = false

  constructor(private parentStore: ParentStartApplicationStore) {
    makeObservable(this, {
      activeTabKey: observable,
      industry: observable,
      isPosBundlesLoading: observable,
      bundleOptions: observable,
      bundlesForSelectedPos: observable,
      selectedCategoryID: observable,
      selectedSubCategoryID: observable,
      isMCCOptionsLoading: observable,
      selectedMCCOptions: observable,
      isOnboardingSettingsLoading: observable,
      onboardingSettings: observable,
      docusignCredentials: observable,
      isDocusignSettingsLoading: observable,
      selectedOnboardingCountry: observable,
      isOppOwnerInfoLoading: observable,
      oppOwnerInfo: observable,
      isOBQuestionnaireSettingsLoading: observable,
      selectedSubCategoryPartnerRequired: observable,
      subscriptionPriceFeeForSelectedPos: observable,
      aliPayEnabled: observable,

      onFinishGeneralInformation: action.bound,
      onFinishProductsInformation: action.bound,
      reset: action.bound,
      setActiveTabKey: action.bound,

      currentStep: computed,
      acquisitionChannels: computed,
      posModels: computed,
      mccList: computed,
      isDocusignDisabled: computed,
      isSubscriptionPricesLoading: computed,
      subscriptionPrices: computed,
      posModelsFull: computed,
      isSME: computed,
      existingBankAccounts: computed,

      isAcquisitionChannelsLoading: computed,
      isFastTrackAvailable: computed,
      acquisitionChannelsWithAll: computed,
      isPosModelsLoading: computed,
      isLoadApplicationByIdLoading: computed,
      isCheckDocumentsRequiredLoading: computed,
      onSelectMcc: action.bound,
      segmentSubCategoryOptions: computed,
      onSelectSegmentCategory: action.bound,
      onSelectSegmentSubCategory: action.bound,
      segmentCategoryOptions: computed,
      segmentCategoryLoading: computed,
      segmentSubCategories: computed,
      segmentSubCategoriesLoading: computed,
      onSelectIndustry: action.bound,
      onPartnerSelect: action.bound,
      isObrCountriesLoading: computed,
      obrCountries: computed,
      checkDocusignMember: action.bound,
      getMccDescriptionByValue: action.bound,
      setSelectedOnboardingCountry: action.bound,
      getOppIdOwnerInfo: action.bound,
      onSelectSegmentCategoryByValue: action.bound,
      onSelectSegmentSubCategoryByValue: action.bound,
      getBundlesForSelectedPos: action.bound,
      setSubscriptionPriceFeeForSelectedPos: action.bound,
      setAliPayEnabled: action.bound
    })
  }

  async init() {
    this.isInitiated = true
    await Promise.all([
      this.parentStore.handbooksStore.loadPosModels(),
      this.parentStore.handbooksStore.loadStartApplicationAcquisitionChannels(),
      this.parentStore.handbooksStore.loadMccList(),
      this.parentStore.handbooksStore.loadBusinessCategories(),
      this.parentStore.handbooksStore.loadBusinessSubCategories(),
      this.parentStore.handbooksStore.loadOnboardingCountries(),
      this.onPartnerSelect(),
      this.checkDocusignMember(),
      this.checkQuestionnaireMember(),
      this.parentStore.handbooksStore.loadSubscriptionPrices()
    ])
  }

  setAliPayEnabled(val: boolean) {
    this.aliPayEnabled = val
  }

  setSubscriptionPriceFeeForSelectedPos(value: string) {
    this.subscriptionPriceFeeForSelectedPos = value
  }

  setSelectedOnboardingCountry(val: string) {
    this.selectedOnboardingCountry = val
  }

  get isSME() {
    return this.dataStore.contactInfoData?.segmentCategory === 'SME_DIRECT'
  }

  get isISO() {
    return this.dataStore.contactInfoData?.segmentCategory === 'SME_ISO'
  }

  get isDocusignDisabled() {
    return (
      !this.supportingDocumentsStore.isDocsSectionCompleted ||
      (this.dataStore.sentToDocusign && this.dataStore.docuSignStatus !== 'success') ||
      !this.dataStore.isApplicationValid
    )
  }

  get isSubscriptionPricesLoading() {
    return this.parentStore.handbooksStore.isSubscriptionPricesLoading
  }

  get subscriptionPrices() {
    return this.parentStore.handbooksStore.subscriptionPrices
  }

  get isObrCountriesLoading() {
    return this.parentStore.handbooksStore.isOnboardingCountriesLoading
  }

  get obrCountries() {
    return this.parentStore.handbooksStore.onboardingCountries?.filter(c => c.isActive === true && !isEmpty(c.iso))
  }

  get isAcquisitionChannelsLoading() {
    return this.parentStore.handbooksStore.isStartProcessAcquisitionChannelsLoading
  }

  get isFastTrackAvailable() {
    return (
      (this.dataStore.contactInfoData.acquisitionChannel === 'fpms' ||
        this.dataStore.contactInfoData.acquisitionChannel === 'headmasters') &&
      hasPermissions(['onboarding.processes.fast_track.create'])
    )
  }

  get isPosModelsLoading() {
    return this.parentStore.handbooksStore.isPosModelsLoading
  }

  get posModels() {
    return this.parentStore.handbooksStore.posModels.map(item => ({ value: item.code, label: item.value, id: item.id }))
  }

  get posModelsFull() {
    return this.parentStore.handbooksStore.posModels
  }

  get mccList() {
    return this.parentStore.handbooksStore.mccList
  }

  get acquisitionChannels() {
    return this.parentStore.handbooksStore.startProcessAcquisitionChannels
  }

  get acquisitionChannelsWithAll() {
    return this.parentStore.handbooksStore.startApplicationAcquisitionChannelsWithAll
  }

  get currentStep() {
    switch (this.activeTabKey) {
      case 'contactInfo':
        return 0
      case 'productsInformation':
        return 1
      case 'completeApplication':
        return 2
      case 'pricing':
        return 3
      case 'supportingDocuments':
        return 4
      case 'result':
        return 5
      default:
        return 0
    }
  }

  get existingBankAccounts(): BankInfo[] {
    const bankAccounts = []
    this.productStore.stores?.forEach(st => {
      const existST = bankAccounts?.find((ex: BankInfo) => ex?.sortCode === st.bankInfo?.sortCode)
      if (!existST && st.bankInfo && st.removable === false) {
        bankAccounts.push(st.bankInfo)
      }

      const existDD = bankAccounts?.find((ex: BankInfo) => ex?.sortCode === st.directDebitBankInfo?.sortCode)
      if (!existDD && st.directDebitBankInfo && st.removable === false) {
        bankAccounts.push(st.directDebitBankInfo)
      }
    })
    return bankAccounts
  }

  get isLoadApplicationByIdLoading(): boolean {
    return this.contactInfoStore.isLoadApplicationByIdLoading
  }

  get isCheckDocumentsRequiredLoading() {
    return this.supportingDocumentsStore.isCheckDocumentsRequiredLoading
  }

  onSelectMcc(value: string) {
    const mccValue = this.selectedMCCOptions?.find(i => i.code === value)?.name
    mccValue && this.productStore.setMccValue(mccValue.trim())
  }

  getMccDescriptionByValue(value: string) {
    return this.selectedMCCOptions?.find(i => i.code === value)?.name?.trim()
  }

  async onSelectIndustry(value: string) {
    try {
      runInAction(() => {
        this.isMCCOptionsLoading = true
      })

      const { status, result, error } = await fetchMccOptionsByIndustry(value)
      if (status !== 200 || error) {
        message.error('error')
      } else {
        let ress: MCCByIndustryResponseType[] = []

        result?.forEach(item => {
          if (!ress.find(t => t.code === item.code)) {
            ress.push(item)
          }
        })
        this.selectedMCCOptions = ress
      }
    } catch (error) {
    } finally {
      runInAction(() => {
        this.isMCCOptionsLoading = false
      })
    }
  }

  setActiveTabKey(key: TabKey) {
    this.activeTabKey = key
  }

  openGeneralInformationPage() {
    this.setActiveTabKey('productsInformation')
  }

  openCompleteApplicationPage() {
    this.completeApplicationStore.selectTab('financialInformation')
    this.setActiveTabKey('completeApplication')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  openPricingPage(shouldReset = false) {
    if (shouldReset) {
      this.pricingStore.reset()
    }
    if (this.onboardingSettings?.needPricing) {
      this.setActiveTabKey('pricing')
    } else {
      this.completeApplicationStore.openSupportingDocumentsPage()
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  openSupportingDocumentsPage() {
    this.setActiveTabKey('supportingDocuments')
  }

  openResultPage() {
    this.setActiveTabKey('result')
  }

  reset() {
    this.companyNameFormStore.reset()
    this.completeApplicationStore.reset()
    this.contactInfoStore.reset()
    this.dataStore.reset()
    this.pricingStore.reset()
    this.productStore.reset()
    this.bankAccountStore.reset()
  }

  async onFinishGeneralInformation(values: any, loadingProperty: LoadingPropertyType) {
    const { paymentSolutionView, locationAddress, ...rest } = values
    const paymentSolution = getPaymentSolution(paymentSolutionView)
    const generalInformation = { paymentSolution, ...rest }

    if (await this.completeApplicationStore.init(generalInformation)) {
      this.dataStore.setTerminalOrders(this.productStore.stores)

      let isOk = true
      const isValuesChanged = !isEquivalent(
        JSON.parse(JSON.stringify(this.dataStore.generalInformationData)),
        JSON.parse(JSON.stringify(values))
      )

      if (isValuesChanged) {
        isOk = await this.dataStore.saveOnPaymentSolution(generalInformation, loadingProperty)
      }
      if (loadingProperty === 'isNextLoading' && isOk) {
        this.openCompleteApplicationPage()
      }
    }
  }

  async onFinishProductsInformation(values: StoreItem[], loadingProperty: LoadingPropertyType) {
    if (isEmpty(values)) {
      this.productStore.setProductAddMessage(translations().addStoreMsg)
      return
    }
    if (values.length > 0) {
      const isOk = await this.dataStore.saveOnProducts(values, loadingProperty)
      if (loadingProperty === 'isNextLoading' && isOk) {
        this.openCompleteApplicationPage()
      }
    }
  }

  async onFinishPricing(values: any, loadingProperty: LoadingPropertyType) {
    await this.dataStore.saveOnTariffs(values, loadingProperty)
  }

  checkDocumentsRequired() {
    return this.supportingDocumentsStore.checkDocumentsRequired()
  }

  async getBundlesForSelectedPos(posCode: string, isSubscription?: boolean, subscriptionPeriod?: string) {
    const id = this.posModels.find(item => item.value === posCode)?.id

    if (id) {
      runInAction(() => {
        this.isPosBundlesLoading = true
      })

      this.aliPayEnabled = this.posModelsFull.find(t => t.id === id)?.openBanking || false

      if (isSubscription && subscriptionPeriod) {
        this.setSubscriptionPriceFeeForSelectedPos(
          this.subscriptionPrices?.find(s => s.posModel === Number(id) && s.code === subscriptionPeriod)?.value || ''
        )
      }

      const { status, result, error } = this.isISO
        ? await fetchPosBundlesISO(id, this.dataStore.contactInfoData.acquisitionChannel)
        : await fetchPosBundlesById(id)
      if (status !== 200 || error) {
        message.error(translations().error)
      }

      this.bundlesForSelectedPos = result

      const items = result && !error ? result.map(item => ({ value: item.code, label: item.value })) : []

      this.bundleOptions = items

      runInAction(() => {
        this.isPosBundlesLoading = false
      })
    }
  }

  get segmentCategoryOptions() {
    return this.parentStore.handbooksStore.segmentCategories
  }

  get segmentCategoryLoading() {
    return this.parentStore.handbooksStore.isSegmentCategoriesLoading
  }

  onSelectSegmentCategory(code: string) {
    this.selectedCategoryID = this.parentStore.handbooksStore.segmentCategories.find(
      (item: CategoryType) => item.code === code
    )?.id
  }

  onSelectSegmentCategoryByValue(value: string, form: FormInstance) {
    if (value) {
      const category = this.parentStore.handbooksStore.segmentCategories?.find(
        (item: CategoryType) => item.value?.toLocaleUpperCase() === value?.toLocaleUpperCase()
      )

      this.selectedCategoryID = category?.id

      form.setFieldsValue({ segmentCategory: category?.code })
    }
  }

  get segmentSubCategories() {
    return this.parentStore.handbooksStore.segmentSubCategories
  }

  get segmentSubCategoryOptions(): CategoryType[] {
    return this.selectedCategoryID
      ? this.parentStore.handbooksStore.segmentSubCategories.filter(
          (item: CategoryType) => item.segmentCategory === this.selectedCategoryID.toString()
        )
      : this.parentStore.handbooksStore.segmentSubCategories
  }

  get segmentSubCategoriesLoading() {
    return this.parentStore.handbooksStore.isSegmentSubCategoriesLoading
  }

  onSelectSegmentSubCategory(code: string) {
    const selected = this.parentStore.handbooksStore.segmentSubCategories.find(
      (item: CategoryType) => item.code === code
    )
    this.selectedSubCategoryID = selected?.id

    this.selectedSubCategoryPartnerRequired = selected?.partnerRequired
  }

  onSelectSegmentSubCategoryByValue(value: string, form: FormInstance) {
    if (value) {
      const subCategory = this.parentStore.handbooksStore.segmentSubCategories?.find(
        (item: CategoryType) => item.value?.toLocaleUpperCase() === value?.toLocaleUpperCase()
      )

      this.selectedSubCategoryID = subCategory?.id
      this.selectedSubCategoryPartnerRequired = subCategory?.partnerRequired

      form.setFieldsValue({ segmentSubCategory: subCategory?.code })
    }
  }

  async onPartnerSelect(partner?: string, country?: string) {
    try {
      runInAction(() => {
        this.isOnboardingSettingsLoading = true
      })

      const { status, result, error } = await onboardingSettings(partner || 'default', country)
      if (status !== 200 || error) {
        message.error(translations().obrSettingsError)
      }

      this.onboardingSettings = result

      runInAction(() => {
        this.isOnboardingSettingsLoading = false
      })
    } catch {
      message.error(translations().obrSettingsError)
    }
  }

  async checkDocusignMember() {
    runInAction(() => {
      this.isDocusignSettingsLoading = true
    })

    const { status, result, error } = await fetchDocusignSettings()
    if (status !== 200 || error) {
      message.error(translations().docusignSettingsError)
    }
    this.docusignCredentials = result

    runInAction(() => {
      this.isDocusignSettingsLoading = false
    })
  }

  async checkQuestionnaireMember() {
    try {
      runInAction(() => {
        this.isOBQuestionnaireSettingsLoading = true
      })

      const { status, result } = await fetchOBQuestionnaireSettings()
      if (status === 200) {
        this.oBQuestionnaireCredentials = result
      }
    } catch (e) {
      message.error(translations().docusignSettingsError)
    } finally {
      runInAction(() => {
        this.isOBQuestionnaireSettingsLoading = false
      })
    }
  }

  async getOppIdOwnerInfo(oppId: string) {
    runInAction(() => {
      this.isOppOwnerInfoLoading = true
    })

    const { status, result, error } = await opportunityOwnerInfo(oppId)
    if (status !== 200 || error) {
      message.error(translations().docusignSettingsError)
    }

    this.oppOwnerInfo = result

    runInAction(() => {
      this.isOppOwnerInfoLoading = false
    })
  }
}

export const StartProcessStoreContext = React.createContext<StartProcessStore>(null)
