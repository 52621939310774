import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  companyName: string
  storeName: string
  address: string
  changeBankDetails: string
  changeSortCodeAccountNumber: string
  tipJar: string
  directDebitAlias: string
  settlementAlias: string
  edit: string
  viewAttributes: string
  viewAttributesBtnLabel: string
  closeStore: string
  accountName: string
  sortCode: string
  storeStatus: string
  closedStore: string
}>()
