import moment from 'moment'
import { cloneObject, isEmpty } from 'dna-common'
import { DATE_PATTERN } from '~/code/constants/Patterns'
import detailsFormTranslations from 'startapp/components/DetailsForm/translations'
import {
  CompanyProfile,
  CompanyProfileScheme,
  defaultFinancialInfo,
  JumioShortResultsDataType,
  OfficerProfile,
  OfficerProfileScheme,
  PCSProfile,
  StoreItem
} from '../models'
import { getAge, isEquivalent } from './utils'
import { ITarrifs } from '../models/ITarrifs'
import { StoreScheme } from '../models/schemes/StoreScheme'
import { BankInfo } from '../models/BankInfo'
import { BankAccountScheme } from '../models/schemes/BankAccountScheme'
import { VerifyOfficerResponseType } from '../models/verification'
import { ListItemProps } from '../components/ListItem/props'

export function isCountryUK(country: string) {
  return country === 'United Kingdom'
}

export const isPCSCorporate = (pcsProfile: PCSProfile) => {
  if (
    pcsProfile &&
    pcsProfile.kind &&
    (pcsProfile.kind.toLowerCase().includes('corporate') ||
      pcsProfile.kind.toLowerCase().includes('company') ||
      pcsProfile.kind.toLowerCase().includes('other'))
  ) {
    return true
  }
  return false
}

export const isCorporateShareholder = (shareholder: any) => {
  if (shareholder?.shareholderType?.toLowerCase() !== 'person') {
    return true
  }
  return false
}

export const isCompanyProfileValid = (companyProfile: CompanyProfile) => {
  return CompanyProfileScheme.isValidSync(companyProfile)
}

export const isCompanyOfficerValid = (officerProfile: OfficerProfile) => {
  let result = false

  if (
    officerProfile.officerVerificationResult?.addressVerifications[0]?.verified &&
    officerProfile.officerVerificationResult?.idVerifications[0].verified
  ) {
    result = true
  } else {
    if (officerProfile.jumioCheckResults?.addressVerified && officerProfile.jumioCheckResults?.identityVerified) {
      result = true
    } else {
      if (officerProfile.uploadDocManually) {
        result = true
      }
    }
  }

  return result && OfficerProfileScheme.isValidSync(cloneObject(officerProfile))
}

export const isCompanyOfficerValidWithoutChecks = (officerProfile: OfficerProfile) => {
  return OfficerProfileScheme.isValidSync(cloneObject(officerProfile))
}

export const isCompanyPCSValid = (pcsProfile: PCSProfile) => {
  if (isPCSCorporate(pcsProfile)) {
    return Boolean(pcsProfile?.identification?.registrationNumber) && Boolean(pcsProfile?.ownershipPercentage)
  }

  let result = false

  if (
    pcsProfile.officerVerificationResult?.addressVerifications?.[0]?.verified &&
    pcsProfile.officerVerificationResult?.idVerifications?.[0]?.verified
  ) {
    result = true
  } else {
    if (pcsProfile.jumioCheckResults?.addressVerified && pcsProfile.jumioCheckResults?.identityVerified) {
      result = true
    } else {
      if (pcsProfile.uploadDocManually) {
        result = true
      }
    }
  }

  return result && OfficerProfileScheme.isValidSync(cloneObject(pcsProfile))
}

export const isCompanyPCSValidWithoutChecks = (pcsProfile: PCSProfile) => {
  if (isPCSCorporate(pcsProfile)) {
    return Boolean(pcsProfile?.identification?.registrationNumber) && Boolean(pcsProfile?.ownershipPercentage)
  }

  return OfficerProfileScheme.isValidSync(cloneObject(pcsProfile))
}

export const isDirectorsValid = (directors: OfficerProfile[], withoutChecks?: boolean) => {
  return (
    !isEmpty(directors) &&
    directors.every(officerProfile =>
      withoutChecks ? isCompanyOfficerValidWithoutChecks(officerProfile) : isCompanyOfficerValid(officerProfile)
    )
  )
}

export const isOwnersValid = (owners: OfficerProfile[]) => {
  return !isEmpty(owners) && owners.every(officerProfile => isCompanyOfficerValid(officerProfile))
}

export const isCompanyPCSListValid = (shareholders: PCSProfile[], withoutChecks?: boolean) => {
  return (
    isEmpty(shareholders.slice()) ||
    shareholders.every(pcsProfile =>
      withoutChecks ? isCompanyPCSValidWithoutChecks(pcsProfile) : isCompanyPCSValid(pcsProfile)
    )
  )
}

export const isAuthorisedSignatoriesValid = (signatories: OfficerProfile[]) => {
  return isEmpty(signatories) || signatories.every(item => isCompanyOfficerValid(item))
}

export const isBusinessOwnersValid = (owners: OfficerProfile[], withoutChecks?: boolean) => {
  return (
    !isEmpty(owners) &&
    owners.every(officerProfile =>
      withoutChecks ? isCompanyOfficerValidWithoutChecks(officerProfile) : isCompanyOfficerValid(officerProfile)
    )
  )
}

export function isFormValid(fieldsError: { errors: string[] }[]) {
  return !fieldsError || fieldsError.filter(f => f.errors.length > 0).length === 0
}

export function validatePostalCodeOfUK(postalCode: string) {
  const UK_POSTCODE_PATTERN = /^[A-Z]{1,2}[0-9][A-Z0-9]?\s?[0-9][A-Z]{2}$/i
  return (
    postalCode &&
    UK_POSTCODE_PATTERN.test(postalCode) &&
    !/[QVX]/i.test(postalCode[0]) &&
    !/[IJZ]/i.test(postalCode[1]) &&
    (!/[A-Z]/i.test(postalCode[2]) || /[ABCDEFGHJKSTUW]/i.test(postalCode[2])) &&
    !/[CIKMOV]/i.test(postalCode[postalCode.length - 2])
  )
}

export function isDateOfCreationValid(value: string) {
  const m = moment(value, 'YYYY-MM-DD')
  const oneYearLaterFromNow = moment().add(1, 'years')

  return !value || (m.isValid() && m.isBefore(oneYearLaterFromNow))
}

export function isDateOfBirthValid(value: string) {
  return !validateDateOfBirth(value)
}

export function validateDateOfBirth(value: string) {
  if (!value) {
    return ''
  }

  const m = moment(value, 'DD-MM-YYYY')
  if (!DATE_PATTERN.test(value) || !m.isValid() || !m.isBefore()) {
    return detailsFormTranslations().dateBirthInvalid
  }

  const age = getAge(m)
  if (age < 18 || age > 100) {
    return detailsFormTranslations().dateBirthAgeLimit
  }

  return ''
}

export const isCorporate = (props: ListItemProps) => {
  return props.corporateName !== undefined
}

export const isTariffsValid = (tariffs: ITarrifs[], products: string[]) => {
  return (
    tariffs.length > 0 &&
    products.length > 0 &&
    tariffs.length === products.length &&
    tariffs.every(t => t.completed === true)
  )
}

export const isFinancialInfoValid = (stores: StoreItem[]) => {
  return !isEmpty(stores) && stores.every(store => isStoreFinancialInfoValid(store))
}

export const isStoreFinancialInfoValid = (st: StoreItem) => {
  let result = isEquivalent(st.financialInfo, defaultFinancialInfo) ? false : true

  if (result) {
    result = st.processingMethods?.reduce((sum, itm) => sum + Number(itm.percent), 0) === 100
  }

  if (st?.paymentsInAdvance === false && result) {
    result = st.advancePayments?.reduce((sum, itm) => sum + Number(itm.percent), 0) === 100
  }

  return result
}

export const isStoreMainInfoValid = (store: StoreItem) => {
  return StoreScheme.isValidSync(cloneObject(store))
}

export const isAllStoresMainInfoValid = (stores: StoreItem[]) => {
  return !isEmpty(stores) && stores.every(store => isStoreMainInfoValid(store))
}

export const isBankAccountValid = (account: BankInfo) => {
  return (
    (account.creditSafeVerificationResults?.verified
      ? true
      : account.uploadDocManually
      ? true
      : account.openBankVerificationResult === 'MATCH') && BankAccountScheme.isValidSync(cloneObject(account))
  )
}

export const isBankAccountValidEuro = (account: BankInfo) => {
  return !!account.bankAccountNumber
}

export const isAllBankAccountsValid = (accounts: BankInfo[], withoutChecks?: boolean) => {
  return (
    !isEmpty(accounts) &&
    accounts.every(account =>
      withoutChecks || account.fromApi ? isBankAccountValidEuro(account) : isBankAccountValid(account)
    )
  )
}

export const isOfficerVerificationResultPassed = (
  officerVerificationResult: VerifyOfficerResponseType,
  jumioCheckResults: JumioShortResultsDataType
) => {
  let result = false

  if (
    officerVerificationResult?.addressVerifications?.[0]?.verified &&
    officerVerificationResult?.idVerifications?.[0].verified
  ) {
    result = true
  } else if (jumioCheckResults?.addressVerified && jumioCheckResults?.identityVerified) {
    {
      result = true
    }
  }

  return result
}
