import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { DataLoadingStatus, SelectItem, SelectItemWithTwoLabel } from '~/code/models'
import { fetchMerchantTerminals } from '~/code/services/fetchers'
import { getUniqueValues, convertToOptions } from '~/code/services/converters'
import { TerminalType } from '~/code/components/StoreAndTerminalFilter/constants'
import {
  Terminal,
  IStoreMidTerminalFilterParentStore,
  IStoreMidTerminalFilterStore
} from '~/code/components/StoreAndTerminalFilter/models'
import rootTranslations from '~/code/translations'

const all = 'all'
type DataType = Terminal & { location?: string }

const getStoreNameLocation = (store: DataType) =>
  `${store.name?.toLocaleUpperCase()}/${store.location?.toLocaleUpperCase()}`

export class StoreMidTerminalFilterStore implements IStoreMidTerminalFilterStore {
  _data: DataType[] = []
  loadingStatus: DataLoadingStatus = 'idle'

  terminalType: TerminalType = all
  store: string = all
  mid: string = all
  terminal: string = all

  get isLoading() {
    return this.loadingStatus === 'loading'
  }

  get merchantId() {
    return this.parentStore.merchantId
  }

  get availableTerminalTypes() {
    const types =  Array.from(new Set(this._data.map((el) => el.type)))
    return ((types.length > 1) ? [all, ...types] : types) as TerminalType[]
  }
  
  get _allMids() {
    return Array.from(new Set(this._data.map((el) => el.mid)))
  }

  get _allTerminals() {
    return Array.from(new Set(this._data.map((el) => el.number)))
  }

  get _dataForStores() {
    if (this.terminalType === 'all') return this._data
    return this._data.filter(el => el.type === this.terminalType)
  }

  get _dataForMids() {
    if (this.store === all) return this._dataForStores
    return this._dataForStores.filter(el => getStoreNameLocation(el) === this.store)
  }

  get _dataForTerminals() {
    if (this.mid === all) return this._dataForMids
    return this._dataForMids.filter(el => el.mid === this.mid)
  }

  get _isTerminalTypeSelected() {
    return this.terminalType !== all && this.terminalType !== this.initialTerminalType
  }

  get _mids(): string[] {
    return getUniqueValues(this._dataForMids.map(el => el.mid))
  }

  get _terminals(): string[] {
    return getUniqueValues(this._dataForTerminals.map(el => el.number))
  }

  get requestForServer() {
    /**
     * For SQL optimization: if there is only one available mid option, and this option is selected, it will not be included in the request. The same applies to terminals.
     */
    const hasSingleMid = this._allMids.length === 1
    const hasSingleTerminal = this._allTerminals.length === 1

    if (this.terminal !== all) {
      return hasSingleTerminal ? {} : { terminalId: [this.terminal] }
    }

    if (this.hasMidsFilter) {
      if (this.mid !== all) {
        return hasSingleMid ? {} : { mids: [this.mid] }
      }

      if (this.store !== all || this._isTerminalTypeSelected) {
        return hasSingleMid ? {} : { mids: this._mids }
      }
    } else {
      if (this.mid !== all || this.store !== all || this._isTerminalTypeSelected) {
        return hasSingleTerminal ? {} : { terminalId: this._terminals }
      }
    }

    return {}
  }

  get terminalTypeOptions(): SelectItem<TerminalType>[] {
    return this.availableTerminalTypes.map((value) => ({ value, label: rootTranslations().terminalTypes[value] }))
  }

  get storeOptions(): SelectItemWithTwoLabel[] {
    if (this.loadingStatus === 'idle' || this.loadingStatus === 'failed') {
      this.loadMerchantTerminals()
    }

    const valuesMap: Record<string, boolean> = {}
    const stores = this._dataForStores.reduce<SelectItemWithTwoLabel[]>((result, el) => {
      const value = getStoreNameLocation(el)
      if (!valuesMap[value]) {
        result.push({ value, label: el.name, subLabel: el.location })
        valuesMap[value] = true
      }
      return result
    }, [])

    if (stores.length > 1) {
      stores.unshift({ value: all, label: rootTranslations().all })
    }

    return stores
  }

  get midOptions(): SelectItem[] {
    if (this._mids.length > 1) return convertToOptions([all, ...this._mids])
    return convertToOptions(this._mids)
  }

  get terminalOptions(): SelectItem[] {
    if (this._terminals.length > 1) return convertToOptions([all, ...this._terminals])
    return convertToOptions(this._terminals)
  }

  constructor(
    private parentStore: IStoreMidTerminalFilterParentStore,
    private initialTerminalType: TerminalType,
    /* TODO: Only in Chargebacks does not added mids filter in endpoint */
    private hasMidsFilter: boolean = true
  ) {
    this.terminalType = initialTerminalType

    makeAutoObservable(this)

    reaction(
      () => this.merchantId,
      () => {
        this.reset()
        this.loadMerchantTerminals()
      }
    )
  }

  setLoading = (loadingStatus: DataLoadingStatus) => {
    this.loadingStatus = loadingStatus
  }

  setTerminalType = (terminalType: TerminalType) => {
    this.terminalType = terminalType
    this.setStore(this.storeOptions[0].value)
  }

  setStore = (store: string) => {
    this.store = store
    this.setMid(this.midOptions[0].value)
  }

  setMid = (mid: string) => {
    this.mid = mid
    this.setTerminal(this.terminalOptions[0].value)
  }

  setTerminal = (terminal: string) => {
    this.terminal = terminal
  }

  init = () => {
    /** auto select option, if there is one option */
    if (this.terminalTypeOptions.length === 1 && this.terminalType !== this.terminalTypeOptions[0].value) {
        this.setTerminalType(this.terminalTypeOptions[0].value)
    } else if (this.storeOptions.length === 1 && this.store !== this.storeOptions[0].value) {
        this.setStore(this.storeOptions[0].value)
    } else if (this.midOptions.length === 1 && this.mid !== this.midOptions[0].value) {
        this.setMid(this.midOptions[0].value)
    } else if (this.terminalOptions.length === 1 && this.terminal !== this.terminalOptions[0].value) {
        this.setMid(this.terminalOptions[0].value)
    } 
  }

  reset = () => {
    this._data = []
    this.loadingStatus = 'idle'
    this.terminalType = this.initialTerminalType
    this.store = all
    this.mid = all
    this.terminal = all
  }

  loadMerchantTerminals = async () => {
    if (!this.merchantId || this.merchantId === 'all' || this.loadingStatus === 'loading') {
      return
    }

    this.setLoading('loading')
    const { result, error } = await fetchMerchantTerminals(this.merchantId, { type: this.initialTerminalType })
    if (error) {
      this.setLoading('failed')
    } else {
      this.setLoading('finished')
      runInAction(() => (this._data = result || []))
      this.init()
    }
  }
}
