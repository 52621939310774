import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'Settlement Terms',
  details: (
    <>Please apply Settlement Type, Settlement Period and corresponding fees</>
  ),
  addTitle: 'Add store',
  productName: (prod: string) => (
    <>
      {prod === 'pos' && (
        <>
          <p>POS payments</p>
          <p>Card machines</p>
        </>
      )}

      {prod === 'ecom' && (
        <>
          <p>Online payments</p>
          <p>Website payments, VT, Payment links</p>
        </>
      )}
    </>
  ),
  settlementsName: 'Settlement terms'
})
