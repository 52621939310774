import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  errorDefiningSettlementType: string
  settlementType: string
  changeGrossSettlementConfirmation: string
  changeDirectDebitConfirmation: string
  chargeMerchant: string
  mandate: string
  changeSettlement: string
  dailyNet: string
  monthlyNet: string
  grossDD: string
  dailyGross: string
  submit: string
}>()
