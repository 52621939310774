import { message } from 'antd'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import moment from 'moment'
import { injectable } from 'inversify'
import { Moment } from 'moment-timezone'
import { PeriodType, SelectItem } from '~/code/models'
import { FileType } from '~/code/models/FileType'
import { isPartner } from '~/code/services/auth'
import { StoreMidTerminalFilterStore } from '~/code/stores/StoreMidTerminalFilterStore'
import {
  fetchAccountNumbers,
  isYesterdayPeriodDisabled
} from '~/code/pages/settlements/SettlementsStatement/components/SettlementsFilters/services'
import { HandbooksStoreInjectable } from '~/code/stores/HandbooksStoreInjectable'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'
import { ISettlementsFilterStore } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsFilters/ISettlementsFilterStore'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'
import { DownloadStatementFileParamsType } from './types'

import translations from './translations'

// @ts-ignore
@injectable()
export abstract class BaseSettlementsFiltersStore implements ISettlementsFilterStore {
  dateStore: RangePickerStoreInjectable
  handbooksStore: HandbooksStoreInjectable
  storeAndTerminalFilterStore: StoreMidTerminalFilterStore
  merchantSelectStore: IMerchantSelectStore

  isDownloadingStatement: boolean = false
  isPdfSatementModalOpen: boolean = false
  isPdfSatementLoading: boolean = false

  isAccountNumberListLoading: boolean = false
  _accountNumber: string = ''

  changesInProgress: boolean = false

  accountNumberList: SelectItem[] = []

  constructor(
    handbooksStore: HandbooksStoreInjectable,
    rangePickerStore: RangePickerStoreInjectable,
    merchantSelectStore: IMerchantSelectStore
  ) {
    this.dateStore = rangePickerStore
    this.handbooksStore = handbooksStore
    this.storeAndTerminalFilterStore = new StoreMidTerminalFilterStore(this, 'all')
    this.merchantSelectStore = merchantSelectStore

    makeObservable(this, {
      isAccountNumberListLoading: observable,
      _accountNumber: observable,
      accountNumber: computed,
      accountNumberList: observable,
      acquisitionChannelId: computed,
      merchantId: computed,

      isDownloadingStatement: observable,
      isPdfSatementLoading: observable,
      isPdfSatementModalOpen: observable,
      startDownloadingStatement: action,
      stopDownloadingStatement: action,
      setAccountNumber: action.bound,
      downloadPdfStatement: action.bound,

      setPeriod: action,
      setIsPdfSatementModalOpen: action.bound,

      loadAccountNumberList: action,

      changesInProgress: observable,

      clear: action
    })

    reaction(
      () => this.merchantId,
      () => {
        this.storeAndTerminalFilterStore.loadMerchantTerminals()
      }
    )

    this.init()
  }

  get acquisitionChannelId() {
    return this.merchantSelectStore.acquisitionChannel?.id
  }

  get merchantId() {
    return this.merchantSelectStore.merchant?.id
  }

  get accountNumber() {
    return isPartner() ? undefined : this._accountNumber
  }

  public async init() {
    const { startDate, endDate, period } = this.dateStore
    if (startDate && endDate) {
      this.setDates(startDate, endDate)
    } else if (period) {
      this.setPeriod(period)
    } else {
      if (!isYesterdayPeriodDisabled()) {
        this.setPeriod(PeriodType.Yesterday)
      }
    }
  }

  get period(): PeriodType {
    return this.dateStore.period
  }

  get startDate(): Moment {
    return this.dateStore.startDate
  }

  get endDate(): Moment {
    return this.dateStore.endDate
  }

  public setPeriod = (period: PeriodType) => {
    this.dateStore.setPeriod(period)
  }

  public setDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.dateStore.setDates(startDate, endDate)
  }

  public startDownloadingStatement() {
    this.isDownloadingStatement = true
  }

  public stopDownloadingStatement() {
    this.isDownloadingStatement = false
  }

  protected abstract downloadStatementFunc(
    format: FileType,
    id?: string,
    isOld?: boolean
  ): (merchantName: string, params: DownloadStatementFileParamsType) => Promise<void>

  public downloadStatement = async (format: FileType) => {
    if (format === FileType.PDF) {
      this.setIsPdfSatementModalOpen(true)
      return
    }

    this.startDownloadingStatement()
    const { requestForServer } = this.storeAndTerminalFilterStore
    try {
      await this.downloadStatementFunc(format)(this.merchantSelectStore.merchant?.tradeName, {
        merchantId: this.merchantId || undefined,
        acquisitionChannel: this.acquisitionChannelId === 'all' ? undefined : this.acquisitionChannelId,
        from: this.dateStore.startDate.clone().format('YYYY-MM-DD'),
        to: this.dateStore.endDate.clone().format('YYYY-MM-DD'),
        format,
        accountNumber: this.accountNumber ? this.accountNumber : undefined,
        ...requestForServer
      })
      this.stopDownloadingStatement()
    } catch (e) {
      this.stopDownloadingStatement()
      message.error(e.message)
    }
  }

  public downloadPdfStatement = async (data: DownloadStatementFileParamsType) => {
    this.isPdfSatementLoading = true

    const { period, from, to } = data

    const isOld = Boolean(from)

    const params = {
      merchantId: this.merchantId || undefined,
      ...(!isOld ? { period } : {}),
      ...(isOld ? { from, to, format: FileType.PDF } : {})
    }

    try {
      await this.downloadStatementFunc(
        FileType.PDF,
        this.merchantId,
        isOld
      )(this.merchantSelectStore.merchant?.tradeName, params)
    } catch (e) {
      message.error(e.message)
    } finally {
      this.isPdfSatementLoading = false
    }
  }

  public setAccountNumber(value: string) {
    this._accountNumber = value
  }

  public loadAccountNumberList = async () => {
    runInAction(() => {
      this.accountNumberList = []
      this.setAccountNumber(null)
    })

    if (!this.merchantId) {
      return
    }

    try {
      runInAction(() => {
        this.isAccountNumberListLoading = true
      })

      const { status, error, result } = await fetchAccountNumbers(this.merchantId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().errLoadingAccountNumbers)
      } else {
        runInAction(() => {
          if (!result) {
            this.accountNumberList = []
            this._accountNumber = ''
          } else {
            if (result?.length > 1) {
              this.accountNumberList = [
                { label: 'ALL', value: '' },
                ...result.map(item => ({ value: item, label: item }))
              ]
            } else {
              this.accountNumberList = [...result.map(item => ({ value: item, label: item }))]
            }
            this._accountNumber = this.accountNumberList?.length > 0 && this.accountNumberList[0].value
          }
        })
      }
    } catch (error) {
      message.error(translations().errLoadingAccountNumbers)
    } finally {
      runInAction(() => {
        this.isAccountNumberListLoading = false
      })
    }
  }

  public setIsPdfSatementModalOpen(isOpen: boolean) {
    this.isPdfSatementModalOpen = isOpen
  }

  public clear = () => {
    this.isDownloadingStatement = false
    this.isPdfSatementLoading = false

    this.isAccountNumberListLoading = false
    this._accountNumber = ''

    this.changesInProgress = false

    this.accountNumberList = []
  }
}
