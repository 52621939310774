import React from 'react'
import { Card, Col, Divider, Row } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Page } from '~/code/pages'
import { StoreAndTerminalFilter } from '~/code/components/StoreAndTerminalFilter'
import { IAcquiringFilterStore } from '../../components/AcquiringFilter/models'
import { ITransactionStatementStore } from '../Statement/models'
import { AcquiringFilter } from '~/code/pages/Acquiring'
import styles from './MonitoringPos.scss'
import { MonitoringPosAmexExportsModal, MonitoringPosAmexTransactions, MonitoringPosAmexTransactionsFilter } from "~/code/pages/Acquiring/pages/MonitoringPosAmex/components";

export const MonitoringPosAmexStoreSymbol = Symbol('MonitoringStoreDnaPosAmex')

export const MonitoringPosAmex = observer((props: {
    transactionsStatementStoreSymbol: symbol,
    acquiringFilterStoreSymbol: symbol,
    merchantSelectStoreSymbol: symbol
}) => {
    const { transactionsStatementStoreSymbol, acquiringFilterStoreSymbol, merchantSelectStoreSymbol }  = props
    const transactionsStatementStore = useInjection<ITransactionStatementStore>(transactionsStatementStoreSymbol)
    const acquiringFilterStore = useInjection<IAcquiringFilterStore>(acquiringFilterStoreSymbol)
    const { storeAndTerminalFilterStore } = acquiringFilterStore

    return <Page>
        <MonitoringPosAmexExportsModal />
        <Card className={styles.MonitoringPos}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <AcquiringFilter
                        storeSymbol={acquiringFilterStoreSymbol}
                        merchantSelectStoreSymbol={merchantSelectStoreSymbol}
                    />
                </Col>
                <Col xs={24}>
                    <StoreAndTerminalFilter store={storeAndTerminalFilterStore} />
                </Col>
            </Row>
            <Divider/>
            <div className={styles.monitoringPosFilterWrapper}>
                <MonitoringPosAmexTransactionsFilter
                    store={transactionsStatementStore}
                    handbooksStore={transactionsStatementStore.handbooksStore}
                />
            </div>
        </Card>
        <MonitoringPosAmexTransactions store={transactionsStatementStore} />
    </Page>
})
