import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Modal, Tabs, Button, Popover } from 'antd'
import { BackButton } from '~/code/components'
import { GroupedTerminalsDataType, TariffType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { EntityItemList } from '../../../EntityItemList'
import { IEditTariffsModalStore } from './IEditTariffsModalStore'
import { EditTariffsTable } from './components/EditTariffsTable'
import { posProductIds, maxTidsLength } from './constants'
import { EditTariffsModalProps } from './props'
import translations from './translations'
import styles from './EditTariffsModal.scss'

export const EditTariffsModalStoreSymbol = Symbol('EditTariffsModalStore')
export const EditTariffsModal = observer((props: EditTariffsModalProps) => {
  const store = useInjection<IEditTariffsModalStore>(EditTariffsModalStoreSymbol)

  const renderTabs = (
    terminal: GroupedTerminalsDataType,
    contractTariffs: TariffType[],
    terminalList: string[],
    terminalGroup: string
  ): JSX.Element => {
    const product = store.defineProduct(terminalList)
    if (product === 'POS/ECOM') {
      const posList = terminalList.filter(terminalId => posProductIds.includes(terminalId.substring(0, 2)))
      const ecomList = terminalList.filter(terminalId => !posList.includes(terminalId))
      return (
        <Tabs
          centered
          defaultActiveKey='0'
          items={[
            {
              label: translations().pos,
              key: '0',
              children: (
                <EditTariffsTable
                  terminal={terminal}
                  contractTariffs={contractTariffs}
                  product={'POS'}
                  terminalList={posList}
                  terminalGroup={terminalGroup}
                />
              )
            },
            {
              label: translations().ecom,
              key: '1',
              children: (
                <EditTariffsTable
                  terminal={terminal}
                  contractTariffs={contractTariffs}
                  product={'ECOM'}
                  terminalList={ecomList}
                  terminalGroup={terminalGroup}
                />
              )
            }
          ]}
        ></Tabs>
      )
    }
    return (
      <EditTariffsTable
        terminal={terminal}
        contractTariffs={contractTariffs}
        product={product}
        terminalList={terminalList}
        terminalGroup={terminalGroup}
      />
    )
  }

  return (
    <Modal
      width={'90%'}
      title={translations().editTariffs}
      open={store.isEditTariffsModalOpen}
      footer={[
        <Button key='cancel' onClick={() => store.closeEditTariffstModal()}>
          {translations().cancel}
        </Button>
      ]}
      onCancel={() => {
        store.setSelectedTerminal(null)
        store.closeEditTariffstModal()
      }}
    >
      {store.selectedTerminal ? (
        <>
          {store.showBackButton && (
            <BackButton onClick={() => store.setSelectedTerminal(null)}>{translations().back}</BackButton>
          )}
          {renderTabs(
            store.selectedTerminal,
            props.data.tariffs,
            store.selectedTerminal.terminal?.[0]?.terminalId,
            store.selectedTerminal.terminal?.[0]?.terminalGroup
          )}
        </>
      ) : (
        <EntityItemList
          title={translations().terminalTariffs}
          text={translations().editViewTariffs}
          list={props.data?.terminals?.map((terminal, idx) => {
            const terminalId = terminal?.terminal?.[0]?.terminalId?.join(', ')
            const terminalLabel = translations().getTabLabel((idx + 1).toString())
            return {
              name: terminalLabel,
              onClick: () => store.setSelectedTerminal(terminal),
              additionalInfo: (
                <div className={styles.popoverBlock}>
                  {translations().tids}
                  <span className='nowrap'>
                    {terminalId?.length > maxTidsLength ? (
                      <Popover content={terminalId}>{terminalId}</Popover>
                    ) : (
                      terminalId
                    )}
                  </span>
                </div>
              )
            }
          })}
        />
      )}
    </Modal>
  )
})
