import translations from './translations'

translations.add('en', {
  errorDefiningSettlementType: 'Settlement type is not defined. Please, contact process-team',
  settlementType: 'Current settlement type: ',
  changeGrossSettlementConfirmation: 'Set Monthly Net settlement?',
  changeDirectDebitConfirmation: 'Set Gross settlement?',
  chargeMerchant: 'To charge the merchant by:',
  mandate: 'Mandate',
  changeSettlement: 'Change Settlement Type',
  dailyNet: 'Daily Net',
  monthlyNet: 'Monthly Net',
  grossDD: 'Gross Direct Debit',
  dailyGross: 'Daily Gross',
  submit: 'Submit'
})
