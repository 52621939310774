import React from 'react'
import { Card, Col, DatePicker, Divider, Form, Popover, Row, Select, Spin, Table } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { useInjection } from 'dna-react-ioc'
import { CustomButton, CustomModal, DNALoader } from '~/code/components'
import { TransactionContainer, YearsCompare } from './components'
import { Page } from '../Page'
import { transactionColumns } from './services/generators'
import { PartnerCommissionsStoreType } from './types/PartnerCommissionsStoreType'
import translations from './translations'
import styles from './PartnerCommissions.scss'

export const PartnerCommissionStoreSymbol = Symbol('AnalyticsStoreOptomanyEcom')

export const PartnerCommissions = observer(() => {
  const store = useInjection<PartnerCommissionsStoreType>(PartnerCommissionStoreSymbol)

  const disableFutureDates = current => current && current > moment().subtract(1, 'month').startOf('day')

  return (
    <>
      <Page>
        <Card>
          <Row justify={'space-between'}>
            <Col>
              <Form.Item label={translations().partner} className={styles.merchantFormItem}>
                <span onClick={() => store.setShowModal(true)} className={styles.showModalBtn}>
                  {store.selectedPartner?.name}
                </span>
              </Form.Item>
            </Col>
            <Col>
              <Row gutter={12}>
                <Col>
                  <DatePicker
                    placeholder={`${moment().year()}-${
                      moment().month().toLocaleString().length === 1 ? `0${moment().month()}` : moment().month()
                    }`}
                    picker='month'
                    onChange={store.changeMainYearAndMonth}
                    disabledDate={disableFutureDates}
                  />
                </Col>
                <div className={classNames(styles.iconContainer, styles.ml10)}>
                  <Popover content={translations().downloadSummaryReport}>
                    {store.isExcelFileLoading ? (
                      <Spin />
                    ) : (
                      <FileExcelOutlined onClick={() => store.loadExcellFile()} />
                    )}
                  </Popover>
                </div>
                <div className={styles.iconContainer}>
                  <Popover content={translations().downloadDetailedReport}>
                    {store.isExcelFileDetailedLoading ? (
                      <Spin />
                    ) : (
                      <FileExcelOutlined onClick={() => store.loadDetailedExcellFile()} />
                    )}
                  </Popover>
                </div>
              </Row>
            </Col>
          </Row>
          <Divider />
          <div>
            <Row gutter={[24, 12]}>
              <Col span={12}>
                <TransactionContainer
                  header={translations().transactionVolume}
                  subHeader={`£${store.groupedDataResult?.salesVolume?.toLocaleString() || '0'}`}
                />
              </Col>
              <Col span={12}>
                <TransactionContainer
                  header={translations().transactionCount}
                  subHeader={store.groupedDataResult?.salesCount?.toLocaleString() || '0'}
                />
              </Col>
              <Col span={12}>
                <TransactionContainer
                  header={translations().partnerCommission}
                  subHeader={`£${store.groupedDataResult?.partnerCommission?.toLocaleString() || '0'}`}
                />
              </Col>
            </Row>
          </div>

          <Divider />
          <div className={styles.mt20}>
            <YearsCompare
              title={translations().commissionsVolume}
              loading={store.isGroupedDataYearCommissionsLoading}
              data={store._yearsComparisonDataCommissions && toJS(store._yearsComparisonDataCommissions)}
              subtitle={translations().totalCommissions}
              year1={store.yearsComparisonYear1Commissions}
              year2={store.yearsComparisonYear2Commissions}
              onYearsChange={(year1, year2) => {
                store.changeYearsCompareCommissions(year1, year2)
              }}
              currencySymbol={store.configStore.currency.symbol}
            />
          </div>
          <Divider />
          <div className={styles.mt20}>
            <YearsCompare
              title={translations().transactionsVolumeMonth}
              loading={store.isGroupedDataYearTransactionVolLoading}
              data={store._yearsComparisonDataTransactionsVol && toJS(store._yearsComparisonDataTransactionsVol)}
              subtitle={translations().totalTransactions}
              year1={store.yearsComparisonYear1TransactionsVol}
              year2={store.yearsComparisonYear2TransactionsVol}
              onYearsChange={(year1, year2) => {
                store.changeYearsCompareTransactionVol(year1, year2)
              }}
              currencySymbol={store.configStore.currency.symbol}
            />
          </div>
          <div className={styles.mt20}>
            <p>{translations().tableInfo}</p>
            <Table
              pagination={false}
              columns={transactionColumns}
              dataSource={store.ungroupedDataResult?.map((u, i) => ({
                order: i + 1,
                ...u,
                salesVolume: u.salesVolume?.toLocaleString(),
                salesFeeVolume: u.partnerCommission?.toLocaleString()
              }))}
            />
          </div>
        </Card>
      </Page>
      <CustomModal
        title={translations().selectPartner}
        open={store.showModal}
        onOk={() => {
          store.setShowModal(false)
        }}
        onCancel={() => {
          store.setShowModal(false)
        }}
        footer={<CustomButton onClick={() => store.setShowModal(false)}>{translations().ok}</CustomButton>}
      >
        <Select
          showSearch={true}
          filterOption={(searchText, option) =>
            String(option.value || '')
              .toLocaleLowerCase()
              .indexOf(searchText?.toLocaleLowerCase()) >= 0
          }
          className={styles.styledSelect}
          value={store.selectedPartner?.id}
          loading={store.isPartnerSelectLoading}
          onChange={store.onSelectPartner}
        >
          {store.partners?.map(a => (
            <Select.Option value={a.id} key={a.id}>
              {a.name}
            </Select.Option>
          ))}
        </Select>
      </CustomModal>
    </>
  )
})
