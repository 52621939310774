import { Col, Form, Input, Row, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormSearch, MultiSelect } from '~/code/components'
import { MonitoringPosTransactionsFilterProps } from './props'
import { MonitoringPosTransactionFilterModel } from '~/code/stores/MonitoringPosTransactionStore/models'
import { FormColumnModel } from '~/code/components/FormSearch/models'
import { isPartner } from '~/code/services/auth'
import { removeSpaces } from '~/code/pages/Acquiring/services/utils'
import translations from './translations'

const { Option } = Select

export const MonitoringPosTestTransactionsFilter: React.FC<MonitoringPosTransactionsFilterProps> = observer((props) => {
  const { className, store } = props
  const [ form ] = useForm()

  const rulesMin3: Rule[] = [{ type: 'string', min: 3, message: translations().validationMinLength(3) }]
  const onEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      form.validateFields()
      store.onFilterBlur()
    }
  }

  useEffect(() => {
    form.setFieldsValue(store.filter)
  }, [])

  const setFilterValue = (field, value, shouldLoaded) => {
    form.setFieldsValue({[field]: removeSpaces(value)})
    store.setFilter({ [field]: form.getFieldValue(field) }, shouldLoaded)
  }

  const filter = store.filter as MonitoringPosTransactionFilterModel
  const items: FormColumnModel[] = [
    {
      key: 'status',
      value: filter.status,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 6, xl: 5 },
      component: <Form.Item
        name='status'
        label={translations().status}
      >
        <Select
          listHeight={300}
          dropdownStyle={{minWidth: 250}}
          defaultValue={filter.status}
          onChange={value => store.setFilter({ status: value }, false)}
          onBlur={store.onFilterBlur}
          onKeyDown={onEnterKey}
        >
          <Option value={'all'}>{translations().all}</Option>
          <Option value={'authorised'}>{translations().statuses.authorised}</Option>
          <Option value={'charged'}>{translations().statuses.charged}</Option>
          <Option value={'declined'}>{translations().statuses.declined}</Option>
          <Option value={'refunded'}>{translations().statuses.refunded}</Option>
          <Option value={'cancelled'}>{translations().statuses.cancelled}</Option>
          <Option value={'chargeback'}>{translations().statuses.chargeback}</Option>
          <Option value={'chargeback-reversal'}>{translations().statuses['chargeback-reversal']}</Option>
          <Option value={'second-chargeback-lost'}>{translations().statuses['second-chargeback-lost']}</Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'transactionChannel',
      value: filter.transactionChannel,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      collapsedColProps: { xs: 0, md: 11, xl: 7 },
      component: <Form.Item label={translations().transactionChannel}>
        <MultiSelect
            isDefaultAll={true}
            value={filter.transactionChannel}
            onChange={(v) => store.setFilter({ transactionChannel: v }, false)}
            onBlur={store.onFilterBlur}
            options={store.transactionChannels}
        />
      </Form.Item>
    },
    {
      key: 'transactionId',
      value: filter.transactionId,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      collapsedColProps: { xs: 0, xl: 7 },
      component: <Form.Item
        name='transactionId'
        label={translations().transactionId}
        rules={rulesMin3}
      >
        <Input
          value={filter.transactionId}
          onChange={(e) => {setFilterValue('transactionId', e.target.value?.trim(), false)}}
          onBlur={store.onFilterBlur}
          onKeyDown={onEnterKey}
        />
      </Form.Item>
    },
    {
      key: 'captureMethod',
      value: filter.captureMethod,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      component: <Form.Item
        name='captureMethod'
        label={translations().captureMethod}
      >
        <Select
          defaultValue={filter.captureMethod}
          onChange={value => store.setFilter({ captureMethod: value }, false)}
          onBlur={store.onFilterBlur}
          onKeyDown={onEnterKey}
        >
          <Option value={'all'}>{translations().all}</Option>
          <Option value={'pos'}>{translations().captureMethods.pos}</Option>
          <Option value={'pos-contactless'}>{translations().captureMethods.posContactless}</Option>
          <Option value={'stored'}>{translations().captureMethods.stored}</Option>
          <Option value={'moto'}>{translations().captureMethods.moto}</Option>
          <Option value={'recurring'}>{translations().captureMethods.recurring}</Option>
          <Option value={'pos-openbanking'}>{translations().captureMethods.posOpenbanking}</Option>
          <Option value={'pos-alipay'}>{translations().captureMethods.posAlipay}</Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'cardScheme',
      value: filter.cardScheme,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      component: <Form.Item
        name='cardScheme'
        label={translations().cardScheme}
      >
        <Select
          defaultValue={filter.cardScheme}
          onChange={value => store.setFilter({ cardScheme: value }, false)}
          onBlur={store.onFilterBlur}
          onKeyDown={onEnterKey}
        >
          <Option value={'all'}>{translations().all}</Option>
          <Option value={'visa'}>{translations().cardSchemes.visa}</Option>
          <Option value={'mastercard'}>{translations().cardSchemes.mastercard}</Option>
          <Option value={'americanexpress'}>{translations().cardSchemes.amex}</Option>
          <Option value={'upi'}>{translations().cardSchemes.upi}</Option>
          <Option value={'diners'}>{translations().cardSchemes.diners}</Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'cardMask',
      value: filter.cardMask,
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      component: <Form.Item
        name='cardMask'
        label={translations().cardMask}
        rules={rulesMin3}
      >
        <Input
          value={filter.cardMask}
          onChange={(e) => {setFilterValue('cardMask', e.target.value?.trim(), false)}}
          onBlur={store.onFilterBlur}
          onKeyDown={onEnterKey}
        />
      </Form.Item>
    },
    {
      key: 'amount',
      value: {amountFrom: filter.amountFrom, amountTo: filter.amountTo},
      colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
      component: <Form.Item label={translations().amount}>
        <Input.Group>
          <Row gutter={6}>
            <Col xs={12} sm={12} md={12}>
              <Input
                  type={'number'}
                  placeholder={translations().amountFrom}
                  value={filter.amountFrom}
                  onChange={(e) => store.setFilter({amountFrom: e.target.value}, false)}
                  onBlur={store.onFilterBlur}
              />
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input
                  type={'number'}
                  placeholder={translations().amountTo}
                  value={filter.amountTo}
                  onChange={(e) => store.setFilter({amountTo: e.target.value}, false)}
                  onBlur={store.onFilterBlur}
              />
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
    }
  ]

  const partnerItems = items
    .filter((item) => ['status', 'captureMethod', 'cardScheme'].includes(item.key))
    .map((item) => ({ ...item, collapsedColProps: { xs: 24, sm: 15, md: 12, xl: 8, xxl: 6 } }))

  return <FormSearch
    className={className}
    form={form}
    onReset={() => {
      store.resetFilter()
      form.resetFields()
    }}
    isCollapsible={!isPartner()}
    items={isPartner() ? partnerItems : items}
  />
})
