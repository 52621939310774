import translations from './translations'

translations.add('en', {
  editTariffs: 'Edit Tariffs',
  getTabLabel: (id: string) => `Terminals Group-${id}`,
  pos: 'POS',
  ecom: 'ECOM',
  cancel: 'Cancel',
  back: 'Back',
  terminalTariffs: 'Terminal Tariffs',
  editViewTariffs: 'View/Edit terminal tariffs',
  tids: 'TIDs: '
})
