import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, computed, observable, action, runInAction, reaction } from 'mobx'
import { UploadFile } from 'antd/lib/upload/interface'
import { deleteDocument, uploadDocument } from '~/code/pages/StartApplication/components/SupportingDocuments/services'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { CompanyFullDossierV2Type } from './models/merchant-dossier-v2/CompanyFullDossierV2Type'
import { getDictionaryMappingValues } from './services/fetchers'
import {
  ContractType,
  BankDetailType,
  ChangeBankDetailsResponseType,
  GetContractsInfoResultType,
  PhysicalStoreAttributesType,
  ProductType,
  DictionaryMappingValueType,
  IndividualInfoType,
  SettlementLogResType,
  HoldDetailsType,
  StoreType
} from './models/merchant-dossier-v2'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { getBalances, loadSettlementLog } from './services/fetchers'
import { UploadDocumentRes } from 'startapp/components/SupportingDocuments/models'
import { CheckedDocumentType } from 'startapp/models/DocumentsRequiredModel'
import { FileKey } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { camelToKebabCase } from 'startapp/services'
import {
  posProductPrefixes,
  ecomProductPrefixes,
  tipJarMidParamId,
  tipJarVanueParamId,
  tipJarDictionaryParamList,
  companyBusinessStructure,
  defaultContractTariffs,
  defaultContractSettlementsList,
  incorrectTerminalsPrefix,
  contractOnHoldStatus,
  contractWithoutHoldStatus,
  defaultDirectDebitAccount,
  onlySettlementAccount
} from './constants'
import translations from './translations'

@injectable()
export class StoresDossierV2Store implements IStoresDossierV2Store {
  selectedBankDetail: BankDetailType = null
  isChangeBankDetailsModalOpen: boolean = false
  changeBankDetailsRes: ChangeBankDetailsResponseType = null
  isDocumentLoading: boolean = false
  documentsData: UploadDocumentRes[] = []
  businessOwnerResult: IndividualInfoType = null
  isChangeSettlementLoading: boolean = false
  isGrossSettlementModalOpen: boolean = false
  isTipJarModalOpen: boolean = false
  isEditTariffsModalOpen: boolean = false
  editContractTariffs: GetContractsInfoResultType = defaultContractTariffs
  contractSettlementsList: SettlementLogResType[] = defaultContractSettlementsList
  documentsMap: Record<FileKey, UploadFile[]> = {
    bankStatement: [],
    other: [],
    processingStatement: []
  }
  documentTypes: FileKey[] = []
  selectedEditTariffsContract: string = ''
  selectedMidStoreInfo: string = ''
  company: string = companyBusinessStructure
  tipJarInfo: DictionaryMappingValueType[] = []
  isTipJarLoading: boolean = false
  selectedStoreId: number = null
  selectedAttributesStoreId: number = null
  isViewAttributesModalOpen: boolean = false
  isCloseStoreModalOpen: boolean = false
  selectedOriginStoreId: string = null
  isSettlementsLoading: boolean = false
  constructor(@inject(MerchantsManagementStoreSymbol) private merchantsManagementStore: MerchantsManagementStore) {
    this.documentTypes = []
    makeObservable(this, {
      companyFullDossierV2: computed,
      isMerchantActive: computed,
      posSettlementPeriod: computed,
      ecomSettlementPeriod: computed,
      isChangeBankDetailsModalOpen: observable,
      selectedBankDetail: observable,
      isDocumentLoading: observable,
      documentsData: observable,
      isChangeSettlementLoading: observable,
      isGrossSettlementModalOpen: observable,
      isEditTariffsModalOpen: observable,
      editContractTariffs: observable,
      contractSettlementsList: observable,
      documentsMap: observable,
      documentTypes: observable,
      selectedMidStoreInfo: observable,
      selectedEditTariffsContract: observable,
      tipJarInfo: observable,
      isTipJarLoading: observable,
      isTipJarModalOpen: observable,
      selectedStoreId: observable,
      selectedAttributesStoreId: observable,
      isViewAttributesModalOpen: observable,
      isCloseStoreModalOpen: observable,
      selectedOriginStoreId: observable,
      isSettlementsLoading: observable,
      openChangeBankDetailsModal: action,
      defineProductType: action,
      defineTipJarVenueByMid: action,
      openViewAttributesModal: action,
      closeViewAttributesModal: action,
      loadStoreAttributes: action,
      closeCloseStoreModal: action,
      openCloseStoreModal: action
    })

    this.init()

    reaction(
      () => this.companyFullDossierV2,
      () => {
        if (this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.topLevelSubjectId) {
          this.init()
        }
      }
    )
  }

  async init() {
    runInAction(() => (this.isSettlementsLoading = true))
    await this.getSettlementsStatus()
    await this.loadBalances()
    runInAction(() => (this.isSettlementsLoading = false))
  }

  getBankDetailsData(store: StoreType): BankDetailType[] {
    const storeId = store.storeInfo.originId

    const subjectBankDetails =
      this.companyFullDossierV2.subjectBankDetail
        ?.filter(detailItem => detailItem?.originStoreId === storeId)
        ?.map(bankDetail => ({ ...bankDetail?.bankDetail, originStoreId: bankDetail?.originStoreId })) || []

    const contractBankDetails =
      store.contracts
        ?.filter(contract => contract?.contract?.bankDetail)
        ?.map(contract => ({ ...contract?.contract?.bankDetail, originStoreId: storeId })) || []

    const data = subjectBankDetails?.length ? subjectBankDetails : contractBankDetails

    if (data.length === onlySettlementAccount) {
      data.push({ ...defaultDirectDebitAccount, originStoreId: storeId })
    }

    return data
  }

  getEmptySettlementInfo(contractNumber: { contractNumber: string }) {
    const settlementLogEmpty = {
      settlementStatus: contractWithoutHoldStatus
    }
    const holdInfo = { holdInfo: [] }
    return { ...contractNumber, ...settlementLogEmpty, ...holdInfo }
  }

  async getSettlementsStatus() {
    const contractSettlementsList = []
    const { stores } = this.companyFullDossierV2?.processing

    for (const store of stores) {
      const onHoldContractNumbers = store.contracts
        .filter(contract => contract.contract.settlementStatus === contractOnHoldStatus)
        .map(contract => contract.contract.contractNumber)

      const withoutHoldContractNumbers = store.contracts
        .filter(contract => contract.contract.settlementStatus !== contractOnHoldStatus)
        .map(contract => contract.contract.contractNumber)

      await Promise.all(
        onHoldContractNumbers.map(async contractNumber => {
          const settlementLog = await this.getSettlementLog(contractNumber)
          const contractNum = { contractNumber }
          if (settlementLog && settlementLog.length) {
            const holdInfo = { holdInfo: settlementLog }
            contractSettlementsList.push({ ...settlementLog[0], ...contractNum, ...holdInfo })
          } else {
            contractSettlementsList.push(this.getEmptySettlementInfo(contractNum))
          }
        })
      )

      withoutHoldContractNumbers.forEach(contractNumber => {
        const contractNum = { contractNumber }
        contractSettlementsList.push(this.getEmptySettlementInfo(contractNum))
      })
    }

    this.contractSettlementsList = contractSettlementsList
  }

  async loadBalances() {
    try {
      let { status } = { status: null }
      if (this.merchantsManagementStore.companyFullDossierV2) {
        const dossierV2 = this.merchantsManagementStore.companyFullDossierV2
        const resultReq = await getBalances(dossierV2.mainInfo?.topLevelSubjectId)
        status = resultReq.status
        if (status !== 200) {
          message.error(translations().errLoadingBalances)
        } else {
          if (resultReq.result?.length > 0) {
            resultReq.result.map(balance => {
              const filteredIndex = this.contractSettlementsList.findIndex(
                settlement => settlement.contractNumber === balance.ContractNumber
              )
              if (filteredIndex !== -1) {
                this.contractSettlementsList[filteredIndex].balance = balance.CurrentBalance
              }
            })
          }
        }
      }
    } catch (error) {
      message.error(`${translations().errLoadingBalances} ${error}`)
    }
  }

  get companyFullDossierV2(): CompanyFullDossierV2Type {
    return this.merchantsManagementStore.companyFullDossierV2
  }

  get isSubsidiary(): boolean {
    return this.merchantsManagementStore.isSubsidiary
  }

  get isEuroClient(): boolean {
    return this.merchantsManagementStore.isEuroClient
  }

  get payneticsMidList(): DictionaryMappingValueType[] {
    return this.merchantsManagementStore.payneticsMidsList
  }

  get isMerchantActive(): boolean {
    return this.merchantsManagementStore.isMerchantActive
  }

  get editTariffsContract(): string {
    return this.selectedEditTariffsContract
  }

  get posSettlementPeriod() {
    return this.merchantsManagementStore.settlementPeriodPos?.toString() || null
  }

  get ecomSettlementPeriod() {
    return this.merchantsManagementStore.settlementPeriodEcom?.toString() || null
  }

  get settlementType(): { code: string | null; label: string | null } {
    const { settlementType, settlementTypeDisplayValue } =
      this.companyFullDossierV2.processing?.stores?.[0]?.contracts?.[0]?.contract || {}
    return { code: settlementType, label: settlementTypeDisplayValue }
  }

  setEditContractTariffs(data: GetContractsInfoResultType): void {
    this.editContractTariffs = data
  }

  async openChangeBankDetailsModal(bankDetail: BankDetailType, storeId: number) {
    if (!bankDetail.bankAccountNumber || !bankDetail.sortCode) return
    this.changeBankDetailsRes = null
    this.selectedBankDetail = bankDetail
    this.isChangeBankDetailsModalOpen = true
    this.selectedStoreId = storeId
  }

  closeChangeBankDetailsModal() {
    this.selectedBankDetail = null
    this.selectedStoreId = null
    this.isChangeBankDetailsModalOpen = false
  }

  async closeTipJarModal() {
    this.isTipJarModalOpen = false
  }

  async openEditTariffsModal(contract: ContractType, midText: string) {
    if (!contract.contractNumber) return
    this.selectedEditTariffsContract = contract.contractNumber
    this.selectedMidStoreInfo = `${this.companyFullDossierV2.mainInfo.companyName} ${midText}`
    this.isEditTariffsModalOpen = true
  }

  async closeEditTariffsModal() {
    this.editContractTariffs = { contract: null, tariffs: [], terminals: [] }
    this.selectedEditTariffsContract = ''
    this.selectedMidStoreInfo = ''
    this.isEditTariffsModalOpen = false
  }

  clearMessage = () => {
    this.changeBankDetailsRes = null
  }

  searchTopLevelSubjectId(): number | null {
    const relations = this.companyFullDossierV2.relation.filter(
      relation => relation.subjectRelation.relationType === '7'
    )
    if (relations && relations.length > 0) {
      return relations[0].subjectRelation.topLevelSubjectId2
    }
    return null
  }

  async uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
    if (!file) return false

    let isUploaded: boolean

    runInAction(() => {
      this.isDocumentLoading = true
    })

    try {
      const { companyName = '', companyNumber = '' } = this.companyFullDossierV2?.mainInfo || {}
      const acquisitionChannel =
        this.companyFullDossierV2?.merchantAcquisitionInfo?.[0]?.acquisitionChannelValue || null

      const { status, error, result } = await uploadDocument({
        companyName,
        companyNumber,
        acquisitionChannel: acquisitionChannel,
        documentType: camelToKebabCase(documentType),
        file
      })
      if (status !== 200 || error || !result || result.length <= 0) {
        message.error(error.message || translations().errorUploadingDocument)
        isUploaded = false
      } else {
        runInAction(() => {
          this.documentsData.push({ ...result[0], internalFileName: file.name, type: documentType })
        })
        isUploaded = true
      }
    } catch (error) {
      message.error(translations().errorUploadingDocument)
      isUploaded = false
    } finally {
      runInAction(() => {
        this.isDocumentLoading = false
      })
    }
    return isUploaded
  }

  addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, [...this.documentsMap[fileKey], ...fileList])
    this.addDocumentTypesArray(fileKey)
  }

  public addDocumentTypesArray(value: FileKey) {
    if (this.documentTypes.includes(value)) return
    this.documentTypes.push(value)
  }

  public setDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
    this.documentsMap[fileKey] = fileList
  }

  public removeDocumentType(value: FileKey) {
    this.documentTypes = this.documentTypes.filter(doc => doc !== value)
  }

  public clearData() {
    this.documentsData = []
    this.documentTypes = []
    this.documentsMap = {
      bankStatement: [],
      other: [],
      processingStatement: []
    }
  }

  async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
    let isDeleted: boolean
    try {
      const path = this.documentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
      if (path) {
        const { status, error } = await deleteDocument(path)
        if (status !== 200 || error) {
          message.error(error.message || translations().errorDeletingDocument)
          isDeleted = false
        } else {
          this.documentsData = this.documentsData.filter(d => d?.path !== path)
          isDeleted = true
        }
      }
    } catch (error) {
      message.error(translations().errorDeletingDocument)
      isDeleted = false
    }
    return isDeleted
  }

  public removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, fileList)
    if (fileList.length === 0) this.removeDocumentType(fileKey)
  }

  defineProductType = (terminalsList: string[]): ProductType => {
    let product: ProductType = ''
    const validTerminalPrefixes = terminalsList
      .map(terminal => terminal.substring(0, 2))
      .filter(terminal => !incorrectTerminalsPrefix.includes(terminal))

    const posCount = validTerminalPrefixes.filter(terminalPrefix => posProductPrefixes.includes(terminalPrefix)).length
    const ecomCount = validTerminalPrefixes.filter(terminalPrefix =>
      ecomProductPrefixes.includes(terminalPrefix)
    ).length
    if (posCount > 0 && ecomCount === 0) {
      return 'POS'
    }
    if (ecomCount > 0 && posCount === 0) {
      return 'ECOM'
    }
    if (ecomCount > 0 && posCount > 0) {
      return 'POS/ECOM'
    }
    return product
  }

  async getSettlementLog(contractNumber: string) {
    const dsrId = this.merchantsManagementStore?.companyFullDossierV2?.mainInfo?.dsrId
    const { status, result } = await loadSettlementLog(dsrId, contractNumber)
    if (status === 200 && result) {
      return result
    }
    message.error(translations().errorLoadingSettlementsInfo)
    return []
  }

  isHoldEnabled(contractNumber: string): HoldDetailsType {
    const settlementsValue = this.contractSettlementsList.find(data => data?.contractNumber === contractNumber)
    if (settlementsValue) {
      const { settlementStatus, initiator, balance, reason, holdInfo } = settlementsValue
      return {
        team: initiator,
        enabled: settlementStatus !== contractOnHoldStatus,
        balance: balance,
        reason: reason,
        holdInfo: holdInfo,
        comment: null
      }
    }
    return {
      team: null,
      enabled: true,
      balance: null,
      reason: null,
      holdInfo: [],
      comment: null
    }
  }

  getMidsFromContractInfo(contractInfo: PhysicalStoreAttributesType): string {
    const midList = contractInfo.terminals.map(t => t.terminal.merchantId)
    const uniqueMidList = Array.from(new Set(midList))
    return uniqueMidList.join(',')
  }

  defineTipJarVenueByMid = (mid: string): string => {
    const tipJarInfo = this.tipJarInfo
    const groupId = tipJarInfo.find(item => item.paramId === tipJarMidParamId && item.paramValue === mid)?.groupId
    const venueId = groupId
      ? tipJarInfo.find(item => item.paramId === tipJarVanueParamId && item.groupId === groupId)?.paramValue || ''
      : ''
    return venueId
  }

  async loadTipJarInfo() {
    try {
      runInAction(() => {
        this.isTipJarLoading = true
      })
      const topLevelSubjectId = this.companyFullDossierV2.mainInfo.topLevelSubjectId
      const { status, error, result } = await getDictionaryMappingValues(topLevelSubjectId, tipJarDictionaryParamList)
      if (status === 200 && !error) {
        this.tipJarInfo = result
        this.isTipJarModalOpen = true
      } else {
        this.tipJarInfo = []
        message.error(error.message || translations().errorLoadingTipJarInfo)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => {
        this.isTipJarLoading = false
      })
    }
  }

  openViewAttributesModal() {
    this.isViewAttributesModalOpen = true
  }

  closeViewAttributesModal() {
    this.isViewAttributesModalOpen = false
    this.selectedAttributesStoreId = null
  }

  loadStoreAttributes(storeId: number) {
    this.selectedAttributesStoreId = storeId
    this.openViewAttributesModal()
  }

  openCloseStoreModal(storeOriginId: string, storeId: number) {
    this.selectedOriginStoreId = storeOriginId
    this.selectedStoreId = storeId
    this.isCloseStoreModalOpen = true
  }

  closeCloseStoreModal() {
    this.selectedOriginStoreId = null
    this.selectedStoreId = null
    this.isCloseStoreModalOpen = false
  }

  getMidsByStore(): string[] {
    if (!this.selectedStoreId) {
      return []
    }
    const currentStore = this.companyFullDossierV2?.processing?.stores?.find(
      store => store.storeInfo.id === this.selectedStoreId
    )
    if (!currentStore || !currentStore.contracts) {
      return []
    }
    const midsList = currentStore.contracts.flatMap(contract =>
      contract.terminals.map(terminal => terminal.terminal.merchantId)
    )
    const uniqueMids = Array.from(new Set(midsList))
    return uniqueMids
  }
}
