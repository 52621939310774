import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IEditTariffsModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/IEditTariffsModalStore'
import { ProductType, ContractTerminalType } from './models/merchant-dossier-v2'

@injectable()
export class EditTariffsModalStore implements IEditTariffsModalStore {
  isTariffsLoading: boolean = false
  storesDossierV2Store: IStoresDossierV2Store
  selectedTerminal: ContractTerminalType = null
  showBackButton: boolean = true
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    makeObservable(this, {
      isTariffsLoading: observable,
      selectedTerminal: observable,
      showBackButton: observable,
      closeEditTariffstModal: action,
      setSelectedTerminal: action,
      setBackButtonVisibility: action.bound
    })
  }

  get isEditTariffsModalOpen() {
    return this.storesDossierV2Store.isEditTariffsModalOpen
  }

  closeEditTariffstModal() {
    this.storesDossierV2Store.closeEditTariffsModal()
  }

  defineProduct(terminalsList: string[]): ProductType {
    return this.storesDossierV2Store.defineProductType(terminalsList)
  }

  setSelectedTerminal(terminal: ContractTerminalType) {
    this.selectedTerminal = terminal
  }

  setBackButtonVisibility(isShown: boolean) {
    this.showBackButton = isShown
  }
}
