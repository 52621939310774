import { inject, injectable } from 'inversify'
import commonTranslations from '~/code/translations'
import { message } from 'antd'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import moment from 'moment-timezone'
import {
  fetchCompanyFullDossierV2,
  getRiskLimitNamesDossierV2,
  getDictionaryValue,
  getDictionaryMappingValues,
  getSettlementPeriod
} from './services/fetchers'
import { chunkArrayInGroups } from './services/utils'
import {
  CompanyFullDossierV2Type,
  RiskLimitsDictionaryResponseType,
  RiskLimitsType,
  DictionaryMappingValueType,
  MerchantAcquisitionInfoType
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { ChangeRiskLimitsStore } from '~/code/stores/MerchantsManagementStore/ChangeRiskLimitsStore'
import { MerchantsManagementSelectStoreSymbol } from '~/code/pages/MerchantsManagement/components'
import { MerchantSelectStore } from './MerchantSelectStore'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { IMerchantManagementStore } from '~/code/models'
import { ChangeRiskLimitsStoreSymbol } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/ChangeRiskLimitsModal'
import { ProfileProps } from '~/code/pages/MerchantsManagement/components/MerchantApplication/components/Profile/props'
import { cloneObject, log } from 'dna-common'
import {
  paynecticsDictionaryParamList,
  activePaymentsFinancialInstitute,
  dnaClientsFinancialInstitute,
  subsidiariesTenantCodePrefixList,
  tradingAddressType,
  companyAddressType,
  dictionariesEnum,
  dictionaryParam,
  terminalActiveStatus
} from './constants'
import translations from './translations'

@injectable()
export class MerchantsManagementStore implements IMerchantManagementStore {
  isFullDossierLoading: boolean = false
  isFullDossierV2Loading: boolean = false
  companyFullDossierLoadError: string = null
  companyFullDossierV2: CompanyFullDossierV2Type = {} as CompanyFullDossierV2Type
  companyFullDossierV2LoadError: string = null
  changeRiskLimitsStore: ChangeRiskLimitsStore
  merchantSelectStore: MerchantSelectStore
  riskLimitsDictionary: RiskLimitsDictionaryResponseType[] = []
  riskLimits: RiskLimitsType = {}
  showAdditionalStoreIssueModal: boolean = false
  profileEntity: ProfileProps = {} as ProfileProps
  payneticsMidsList: DictionaryMappingValueType[] = []
  settlementPeriodEcom: number | null = null
  settlementPeriodPos: number | null = null

  constructor(
    @inject(ChangeRiskLimitsStoreSymbol) changeRiskLimitsStore: ChangeRiskLimitsStore,
    @inject(MerchantsManagementSelectStoreSymbol) merchantSelectStore: MerchantSelectStore
  ) {
    this.changeRiskLimitsStore = changeRiskLimitsStore
    this.merchantSelectStore = merchantSelectStore

    makeObservable(this, {
      isFullDossierV2Loading: observable,
      companyFullDossierLoadError: observable,
      companyFullDossierV2: observable,
      companyFullDossierV2LoadError: observable,
      showAdditionalStoreIssueModal: observable,
      riskLimitsDictionary: observable,
      riskLimits: observable,
      profileEntity: observable,
      payneticsMidsList: observable,
      settlementPeriodEcom: observable,
      settlementPeriodPos: observable,

      selectedMerchant: computed,
      shareholders: computed,
      directors: computed,
      authorizedPersons: computed,
      isCompanyFullDossierV2Exists: computed,
      isMerchantActive: computed,
      isSubsidiary: computed,
      isSubsidiaryForFee: computed,
      isSubsidiaryForProcess: computed,
      tenantCode: computed,
      financialInstitute: computed,
      isEuroClient: computed,
      merchantMids: computed,
      stores: computed,

      loadCompanyFullDossierV2: action,
      clearErrors: action,
      onTabPaneClick: action,
      setShowAdditionalStoreIssueModal: action,
      goToShowAdditionalProcess: action,
      generateModelForProfile: action,
      reload: action,
      clearMerchantInfo: action
    })

    reaction(
      () => this.selectedMerchant,
      selectedMerchant => {
        if (!selectedMerchant || !selectedMerchant.id) return
        this.clearMerchantInfo()
        this.loadMerchantData(selectedMerchant.id)
      }
    )
  }

  get selectedMerchant() {
    return this.merchantSelectStore.selectedMerchant
  }

  get tenantCode() {
    return this.companyFullDossierV2?.mainInfo?.tenantCode
  }

  get financialInstitute() {
    return this.companyFullDossierV2?.mainInfo?.financialInstitute
  }

  get isSubsidiary() {
    return subsidiariesTenantCodePrefixList.includes(this.tenantCode)
  }

  get isSubsidiaryForFee() {
    return this.tenantCode !== 'dna' && this.financialInstitute === activePaymentsFinancialInstitute
  }

  get isSubsidiaryForProcess() {
    return this.tenantCode !== 'dna' && this.financialInstitute !== dnaClientsFinancialInstitute
  }

  get isEuroClient() {
    return this.companyFullDossierV2?.mainInfo?.marketCode === 'EU'
  }

  get shareholders() {
    const filteredShareholders =
      this.companyFullDossierV2?.relation?.filter(
        rel => rel.subjectRelation.relationType === '7' && rel.subjectRelation.relationDirection === '1'
      ) || []
    return filteredShareholders.map(shareholder => shareholder.subjectRelation.topLevelSubjectId2.toString())
  }

  get directors() {
    const filteredDirectors =
      this.companyFullDossierV2?.relation?.filter(
        rel => rel.subjectRelation.relationType === '8' && rel.subjectRelation.relationDirection === '1'
      ) || []
    return filteredDirectors.map(shareholder => shareholder.subjectRelation.topLevelSubjectId2.toString())
  }

  get authorizedPersons() {
    const filteredAuthorizedPersons =
      this.companyFullDossierV2?.relation?.filter(
        rel => rel.subjectRelation.relationType === '4' && rel.subjectRelation.relationDirection === '1'
      ) || []
    return filteredAuthorizedPersons.map(shareholder => shareholder.subjectRelation.topLevelSubjectId2.toString())
  }

  get isCompanyFullDossierV2Exists() {
    return Object.keys(this.companyFullDossierV2).length > 0
  }

  get isMerchantActive() {
    return this.companyFullDossierV2?.mainInfo?.isActive
  }

  get metchantHiglightInformation() {
    const { companyName, isActive, dsrId } = this.companyFullDossierV2?.mainInfo || {}
    const firstStore = this.companyFullDossierV2?.processing?.stores?.[0] ?? null
    const firstContract = firstStore?.contracts?.[0] ?? null
    const firstTerminal = firstContract?.terminals?.[0]?.terminal ?? null
    return {
      companyName,
      tradingName: firstTerminal?.descriptor,
      isActive,
      uberId: dsrId
    }
  }

  get merchantMids() {
    const mids = this.companyFullDossierV2?.processing?.stores
      ?.flatMap(store => store?.contracts ?? [])
      .flatMap(contract => contract?.terminals ?? [])
      .map(terminal => terminal?.terminal?.merchantId)
    return Array.from(new Set(mids))
  }

  get stores() {
    return this.companyFullDossierV2?.processing?.stores || []
  }

  setShowAdditionalStoreIssueModal(val: boolean) {
    this.showAdditionalStoreIssueModal = val
  }

  clearMerchantInfo() {
    this.companyFullDossierV2 = {} as CompanyFullDossierV2Type
    this.riskLimitsDictionary = []
    this.riskLimits = {}
    this.profileEntity = {} as ProfileProps
    this.payneticsMidsList = []
    this.settlementPeriodEcom = null
    this.settlementPeriodPos = null
  }

  reload() {
    this.companyFullDossierV2 = {} as CompanyFullDossierV2Type
    this.merchantSelectStore.clearSelectedMerchant()
    this.merchantSelectStore.clearMerchants()
    this.clearErrors()
  }

  clearErrors() {
    this.companyFullDossierLoadError = null
    this.companyFullDossierV2LoadError = null
  }

  public startFullDossierV2Loading() {
    this.isFullDossierV2Loading = true
  }

  public stopFullDossierV2Loading() {
    this.isFullDossierV2Loading = false
  }

  async loadMerchantData(id: string): Promise<void> {
    this.startFullDossierV2Loading()

    try {
      await this.loadCompanyFullDossierV2(id)

      const { mainInfo } = this.companyFullDossierV2 || {}
      const topLevelSubjectId = mainInfo?.topLevelSubjectId || null
      const dsrId = mainInfo?.dsrId || null

      if (!dsrId || !topLevelSubjectId) return

      await this.loadRiskLimitsDossierV2()
      await this.generateModelForProfile()
      await this.loadPayneticsMidsList(dsrId, topLevelSubjectId)
      if (!this.isSubsidiary && this.isMerchantActive) {
        await this.loadSettlementPeriod(topLevelSubjectId)
      }
    } catch (error) {
      log(translations().errorLoadingMerchantData, error)
      message.error(translations().errorLoadingMerchantData, error)
    } finally {
      this.stopFullDossierV2Loading()
    }
  }

  async loadSettlementPeriod(topLevelSubjectId: number) {
    try {
      const { status, result, error } = await getSettlementPeriod(topLevelSubjectId)

      if (status === 200 && !error && result) {
        const { settlementPeriodPOS, settlementPeriodEcom } = result
        this.settlementPeriodPos = settlementPeriodPOS
        this.settlementPeriodEcom = settlementPeriodEcom
      } else {
        throw new Error(error?.message || translations().errLoadingSettlementPeriod)
      }
    } catch (error) {
      message.error(error?.message || translations().errLoadingSettlementPeriod)
    }
  }

  async loadCompanyFullDossierV2(id: string): Promise<void> {
    this.companyFullDossierV2 = {} as CompanyFullDossierV2Type

    if (!id) return

    try {
      const { status, result, error } = await fetchCompanyFullDossierV2(id)

      if (status === 200) {
        this.companyFullDossierV2 = result
      } else {
        this.companyFullDossierV2 = {} as CompanyFullDossierV2Type
        this.companyFullDossierV2LoadError = error?.message
        message.error(error?.message || commonTranslations().defaultErrorMessage)
      }
    } catch (error) {
      this.companyFullDossierV2 = {} as CompanyFullDossierV2Type
      this.companyFullDossierV2LoadError = error?.message
      message.error(error?.message || commonTranslations().defaultErrorMessage)
    }
  }

  async loadRiskLimitsDossierV2(): Promise<void> {
    try {
      const activeStores = (this.companyFullDossierV2?.processing?.stores || []).filter(
        store => store?.storeInfo?.isActive
      )

      const activeContracts = activeStores
        .flatMap(store => store?.contracts || [])
        .filter(contract => contract?.contract?.isActive)

      const activeTerminals = activeContracts
        .flatMap(contract => contract?.terminals || [])
        .filter(terminal => terminal?.terminal?.terminalStatus === terminalActiveStatus)

      const riskLimitsList = activeTerminals.flatMap(terminal => terminal?.riskLimits || [])

      const { status, result, error } = await getRiskLimitNamesDossierV2()

      if (status === 200) {
        this.riskLimitsDictionary = result?.filter(limit => limit.isActive)

        this.riskLimits = this.riskLimitsDictionary.reduce((acc, riskLimit) => {
          const value = riskLimitsList.find(limit => limit.paramId === riskLimit.id)?.paramValue || null
          acc[riskLimit.paramCode] = value
          return acc
        }, {})
      } else {
        this.riskLimitsDictionary = []
        this.riskLimits = {}
        message.error(error?.message || commonTranslations().defaultErrorMessage)
      }
    } catch (error) {
      this.riskLimitsDictionary = []
      this.riskLimits = {}
      log(error)
      message.error(error?.message || commonTranslations().defaultErrorMessage)
    }
  }

  async loadPayneticsMidsList(dsrId: number, topLevelSubjectId: number): Promise<void> {
    try {
      const { status, result, error } = await getDictionaryMappingValues(dsrId, paynecticsDictionaryParamList)

      if (status !== 200) {
        this.payneticsMidsList = []
        message.error(error?.message || commonTranslations().defaultErrorMessage)
        return
      }
      if (result?.length === 0 && topLevelSubjectId !== dsrId) {
        await this.loadPayneticsMidsListSubjectId(topLevelSubjectId)
      } else {
        this.payneticsMidsList = result
      }
    } catch (error) {
      this.payneticsMidsList = []
      log(error)
      message.error(error?.message || commonTranslations().defaultErrorMessage)
    }
  }

  async loadPayneticsMidsListSubjectId(topLevelSubjectId: number) {
    const { status, result, error } = await getDictionaryMappingValues(topLevelSubjectId, paynecticsDictionaryParamList)
    if (status === 200) {
      this.payneticsMidsList = result
    } else {
      this.payneticsMidsList = []
      message.error(error?.message || commonTranslations().defaultErrorMessage)
    }
  }

  async onTabPaneClick(tabName: string) {
    if (tabName === 'additionalStore') {
      this.setShowAdditionalStoreIssueModal(true)
    }
  }

  goToShowAdditionalProcess() {
    goToRoute(Routes.APPLICATIONS_START_PROCESS_ADDITIONAL, {
      id: this.companyFullDossierV2?.mainInfo?.companyNumber,
      uberID: this.companyFullDossierV2?.mainInfo?.dsrId
    })
    return
  }

  async getDictValue(dictName: string, dictValue: string, paramName: string = 'value') {
    const { status, result } = await getDictionaryValue(dictName, dictValue)
    if (status === 200 && result) {
      return result[0]?.[paramName] || ''
    }
    return ''
  }

  async getAddressByType(addressType: string) {
    const addressList = this.companyFullDossierV2?.address?.filter(address => address.addressType === addressType) || []
    const fullAddressList = await Promise.all(
      addressList.map(async address => {
        const country = await this.getDictValue('d_countries', address.country)
        const clonedAddress = cloneObject(address)
        clonedAddress.country = country
        return clonedAddress
      })
    )
    return fullAddressList || []
  }

  async generateModelForProfile() {
    const fullDossier = this.companyFullDossierV2 ?? null
    const firstStore = fullDossier?.processing?.stores?.[0] ?? null
    const firstContract = firstStore?.contracts?.[0] ?? null
    const firstTerminal = firstContract?.terminals?.[0]?.terminal ?? null
    const firstWebSite = fullDossier?.subjectWebsite?.[0] ?? null
    const firstEmail = fullDossier?.email?.[0] ?? null
    const firstPhone = fullDossier?.phone?.[0] ?? null
    const mccId = firstTerminal?.merchantCategoryCode ?? null

    const mccCode = mccId ? await this.getDictValue(dictionariesEnum.merchantCategory, mccId, dictionaryParam.code) : ''
    const tradingAddress = await this.getAddressByType(tradingAddressType)
    const companyAddress = await this.getAddressByType(companyAddressType)
    const acqInfo = this.getAcquisitionChannel()

    this.profileEntity = {
      companyName: fullDossier.mainInfo?.companyName,
      companyTradeName: firstTerminal.descriptor,
      companyNumber: fullDossier.mainInfo?.companyNumber,
      companyWebsite: firstWebSite?.webSite?.url,
      dateOfCreation: fullDossier.mainInfo?.creationDate,
      merchantCategoryCode: mccCode,
      numberOfEmployees: fullDossier.mainInfo?.numberOfEmployees?.toString(),
      tradeOutsideEea: firstStore.natureAccountTransaction?.declaredTradeCharacteristic?.tradeOutsideEea,
      tradingCountriesOutsideEea: firstStore.tradingCountriesOutside,
      tradingAddress,
      emailAddress: firstEmail?.email,
      telephoneNumber: firstPhone?.fullPhoneNum,
      averageTransactionValue:
        firstStore.natureAccountTransaction?.declaredTradeCharacteristic?.averageTransactionValue,
      expectedAnnualTurnover: firstStore.natureAccountTransaction?.declaredTradeCharacteristic?.expAnnualTurnover,
      expectedMonthlyTransactionValues:
        firstStore.natureAccountTransaction?.declaredTradeCharacteristic?.numberTransactionPerMonth,
      natureOfBusiness: firstStore.storeInfo?.natureOfBusiness,
      natureOfBusinessDetails: firstStore.storeInfo?.natureOfBusinessDetails,
      businessModelQuestions: '',
      companyAddress,
      dsrId: fullDossier.mainInfo?.dsrId,
      isActive: fullDossier.mainInfo?.isActive,
      partner: acqInfo?.acquisitionChannelValue,
      segmentCategory: acqInfo?.segmentCategoryValue,
      segmentSubCategory: acqInfo?.segmentSubCategoryValue,
      acquisitionMgr: acqInfo?.acquisitionMgr,
      dnaAcquiring: fullDossier.mainInfo?.dnaAcquiring,
      tenantCode: fullDossier.mainInfo?.tenantCode,
      tenantValue: fullDossier.mainInfo?.tenantValue,
      marketCode: fullDossier.mainInfo?.marketCode,
      additionalInfo: fullDossier.additionalMerchantInfo ? chunkArrayInGroups(fullDossier.additionalMerchantInfo) : []
    }
  }

  getAcquisitionChannel(): MerchantAcquisitionInfoType {
    const acquisitionChannelList = this.companyFullDossierV2?.merchantAcquisitionInfo || []
    return acquisitionChannelList.reduce((currentAcquisitionInfo, acqInfoItem) => {
      if (!acqInfoItem.addDateTime || moment(acqInfoItem.addDateTime).isBefore(currentAcquisitionInfo?.addDateTime)) {
        return acqInfoItem
      }
      return currentAcquisitionInfo
    }, {} as MerchantAcquisitionInfoType)
  }
}
