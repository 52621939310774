import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { StartProcessStore } from '../StartProcessStore'
import { TariffType } from '../components/Pricing/models/TariffType'
import { fetchTariffSettings, getTariffsInitialValues } from '../components/Pricing/services'
import { IcTariffType } from '../components/Pricing/models/IcTariffType'
import { ITarrifs } from '../models/ITarrifs'
import { FormInstance } from 'antd/es/form/Form'
import { convertStringValuesToNumber } from '../services'
import { TariffRequestType } from '../components/Pricing/models/TariffRequestType'
import merge from 'lodash/merge'
import { PricingStoreType } from '../components/Pricing/models'
import translations from '../translations'
import { isEmpty } from 'lodash'
import { PRODUCT_VERSIONS, merchantCharge, paymentTypeCodeMap, settlementNameMap } from '../services/constants'
import { CompanyProfile, SettlementsInfoDataType } from '../models'

export class PricingStore implements PricingStoreType {
  isTariffsLoading: boolean = false
  tariffsList: IcTariffType[] = []
  fullTariffs: ITarrifs[] = []
  same: boolean = false
  activeTab: string = ''
  nextClickCounter: number = 0
  selectedPricingProduct: string = ''
  completeEnabled: boolean = false
  showSettlementTerms: boolean = false

  tarrifTypes: { ecom?: TariffType; pos?: TariffType } = {}

  constructor(private parentStore: StartProcessStore) {
    makeObservable(this, {
      isTariffsLoading: observable,
      tariffsList: observable,
      fullTariffs: observable,
      tarrifTypes: observable,
      same: observable,
      activeTab: observable,
      nextClickCounter: observable,
      selectedPricingProduct: observable,
      completeEnabled: observable,
      showSettlementTerms: observable,

      productTypes: computed,
      isTerminalPurchase: computed,
      isZashEpos: computed,
      isChargeable: computed,
      isAliPay: computed,
      isWeChatPay: computed,
      isPartnerWithoutLimits: computed,
      grossSettlementsByUberIdAllowed: computed,
      perCompanyUberIdAllowed: computed,
      isOnlyEcom: computed,

      reset: action,
      loadTariffs: action,
      addToTarrifs: action,
      setTarrifTypes: action,
      getFilteredTariffsList: action,
      setSame: action.bound,
      setActiveTab: action,
      setNextClickCounter: action,
      showWarning: action,
      mergeInitialValuesICPlus: action,
      onInitialValuesChangeICPlus: action,
      mergeInitialvaluesBlended: action,
      onInitialValuesChangedBlended: action,
      mergeInitialValuesFees: action,
      onInitialValuesChangedFees: action,
      setSelectedPricingProduct: action.bound,
      checkFormFilled: action.bound,
      setShowSettlementTerms: action.bound,
      mergeInitialValuesSettlements: action.bound,
      settlementFormLoadSettings: action.bound,
      handleSettlementsFormChange: action.bound
    })
  }

  setShowSettlementTerms(val: boolean) {
    this.showSettlementTerms = val
  }

  setSelectedPricingProduct(product: string) {
    this.selectedPricingProduct = product
  }

  mergeInitialValuesICPlus(type: string, initialValues: any) {
    return merge({}, getTariffsInitialValues(this.getFilteredTariffsList(type, 'ic+')), initialValues)
  }

  onInitialValuesChangeICPlus(initialValues: any, type: string, tForm: FormInstance, setter: React.Dispatch<any>) {
    if (initialValues !== undefined) {
      setter(this.mergeInitialValuesICPlus(type, initialValues))
    } else {
      tForm.resetFields()
    }
  }

  mergeInitialvaluesBlended(type: string, initialValues: any) {
    return merge(
      {},
      getTariffsInitialValues(this.getFilteredTariffsList(type, 'blended'), el => ({
        base: 0,
        percent: el.percentFee
      })),
      initialValues
    )
  }

  onInitialValuesChangedBlended(initialValues: any, type: string, tForm: FormInstance, setter: React.Dispatch<any>) {
    if (initialValues !== undefined) {
      setter(this.mergeInitialvaluesBlended(type, initialValues))
    } else {
      tForm.resetFields()
    }
  }

  mergeInitialValuesFees(initialValues: IcTariffType[], product: string) {
    const productValues =
      product !== 'ecom'
        ? {
            billingByPaytek: this.dataStore.application?.companyProfile?.chargeType === 'byPaytek' ? true : false
          }
        : {}
    const defaultValues = {
      diners: { base: 0, percent: 3 },
      chinaUnionPay: { base: 0, percent: 3 },
      amex: { base: 0, percent: 3 },
      zashAppEnabled: true,
      setUpConfigurationDeliveryFeeEnabled: true,
      paymentTerminalSupportAndTransactionFeeEnabled: true,
      airtimeServiceEnabled: true,
      dinersEnabled: true,
      amexEnabled: true,
      unionPayEnabled: true,
      openBankingEnabled: true,
      payByBankEnabled: true,
      zashSolution: false,
      zAndPayBankApp: false,
      terminalCostEnabled: false,
      minMonthlyServiceChargeEnabled: true,
      gatewaySetUpFeeEnabled: false,
      gatewayFeeEnabled: false,
      monthlyGatewayFeeEnabled: false,
      openBanking: {
        base: this.tariffsList?.find(item => item?.interchangeFee?.key === 'openBanking')?.interchangeFee?.baseFee,
        percent: this.tariffsList?.find(item => item?.interchangeFee?.key === 'openBanking')?.interchangeFee?.percentFee
      },
      payByBank: {
        base: this.tariffsList?.find(item => item?.interchangeFee?.key === 'payByBank')?.interchangeFee?.baseFee,
        percent: this.tariffsList?.find(item => item?.interchangeFee?.key === 'payByBank')?.interchangeFee?.percentFee
      },
      authorisationOkFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'authorisationOkFee')
        ?.interchangeFee?.baseFee,
      refundFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'refundFee')?.interchangeFee?.baseFee,
      chargebackFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'chargebackFee')?.interchangeFee
        ?.baseFee,
      pciFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'pciFee')?.interchangeFee?.baseFee,
      setUpConfigurationDeliveryFee: this.tariffsList?.find(
        item => item?.interchangeFee?.key === 'setUpConfigurationDeliveryFee'
      )?.interchangeFee?.baseFee,
      zashApp: this.tariffsList?.find(item => item?.interchangeFee?.key === 'zashApp')?.interchangeFee?.baseFee,
      paymentTerminalSupportAndTransactionFee: this.tariffsList?.find(
        item => item?.interchangeFee?.key === 'paymentTerminalSupportAndTransactionFee'
      )?.interchangeFee?.baseFee,
      airtimeService: this.tariffsList?.find(
        item => item?.interchangeFee?.key === 'paymentTerminalSupportAndTransactionFee'
      )?.interchangeFee?.baseFee,
      minMonthlyServiceCharge: this.tariffsList?.find(item => item?.interchangeFee?.key === 'minMonthlyServiceCharge')
        ?.interchangeFee?.baseFee,
      gatewaySetUpFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'gatewaySetUpFee')?.interchangeFee
        ?.baseFee,
      gatewayFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'gatewayFee')?.interchangeFee?.baseFee,
      monthlyGatewayFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'monthlyGatewayFee')
        ?.interchangeFee?.baseFee,
      aliPay: {
        base: this.tariffsList?.find(item => item?.interchangeFee?.key === 'aliPay')?.interchangeFee?.baseFee,
        percent: this.tariffsList?.find(item => item?.interchangeFee?.key === 'aliPay')?.interchangeFee?.percentFee
      },
      weChatPay: {
        base: this.tariffsList?.find(item => item?.interchangeFee?.key === 'weChatPay')?.interchangeFee?.baseFee,
        percent: this.tariffsList?.find(item => item?.interchangeFee?.key === 'weChatPay')?.interchangeFee?.percentFee
      },
      cardNotPresentFee: this.tariffsList?.find(item => item?.interchangeFee?.key === 'cardNotPresentFee')
        ?.interchangeFee?.baseFee,
      ...productValues
    }

    if (this.tarrifTypes[product] === 'ic+') {
      defaultValues.cardNotPresentFee = null
    }
    return merge({}, defaultValues, initialValues)
  }

  mergeInitialValuesSettlements(initialValues: IcTariffType[]) {
    const defaultValues = {
      ecom: {
        settlementPeriod: 3,
        settlementFee: this.tariffsList?.find(
          item => item.product === 'ecom' && item?.interchangeFee?.key === 'settlementFee'
        )?.interchangeFee?.baseFee,
        fasterFundingFee: this.tariffsList?.find(
          item => item.product === 'ecom' && item?.interchangeFee?.key === 'fasterFundingFee'
        )?.interchangeFee?.baseFee,
        fasterFundingMonthly: this.tariffsList?.find(
          item => item.product === 'ecom' && item?.interchangeFee?.key === 'fasterFundingMonthly'
        )?.interchangeFee?.baseFee
      },
      pos: {
        settlementPeriod: 3,
        settlementFee: this.tariffsList?.find(
          item => item.product === 'pos' && item?.interchangeFee?.key === 'settlementFee'
        )?.interchangeFee?.baseFee,
        fasterFundingFee: this.tariffsList?.find(
          item => item.product === 'pos' && item?.interchangeFee?.key === 'fasterFundingFee'
        )?.interchangeFee?.baseFee,
        fasterFundingMonthly: this.tariffsList?.find(
          item => item.product === 'pos' && item?.interchangeFee?.key === 'fasterFundingMonthly'
        )?.interchangeFee?.baseFee
      }
    }

    return merge({}, defaultValues, initialValues)
  }

  settlementFormLoadSettings(settlementForm: FormInstance) {
    if (this.dataStore.isAdditionalStoreProcess) return
    if (!this.grossSettlementsByUberIdAllowed || this.isOnlyEcom) {
      settlementForm.setFieldsValue({
        grossSettlementsByUberId: false
      })
    }

    this.isOnlyEcom &&
      settlementForm.setFieldsValue({
        grossSettlementsByMid: false
      })

    !this.perCompanyUberIdAllowed &&
      settlementForm.setFieldsValue({
        perCompanyUberId: false
      })
  }

  handleSettlementsFormChange(changedValues: any, formSettlements: FormInstance) {
    const updateFormFields = fields => {
      formSettlements.setFieldsValue(fields)
    }

    if (changedValues?.ecom?.nextDayFundingRequested === false) {
      updateFormFields({ ecom: { settlementPeriod: '3' } })
    }
    if (changedValues?.ecom?.nextDayFundingRequested === true) {
      updateFormFields({ ecom: { settlementPeriod: '1' } })
    }

    if (changedValues?.pos?.nextDayFundingRequested === false) {
      updateFormFields({ pos: { settlementPeriod: '3' } })
    }
    if (changedValues?.pos?.nextDayFundingRequested === true) {
      updateFormFields({ pos: { settlementPeriod: '1' } })
    }

    if (changedValues.perCompanyUberId) {
      updateFormFields({ perOutletMid: false })
    }

    if (changedValues.perOutletMid) {
      updateFormFields({ perCompanyUberId: false })
    }

    const exclusiveFields = ['dailyNetSettlements', 'grossSettlementsByMid', 'grossSettlementsByUberId']

    exclusiveFields.forEach(field => {
      if (changedValues[field]) {
        const resetFields = exclusiveFields
          .filter(f => f !== field)
          .reduce((acc, f) => {
            acc[f] = false
            return acc
          }, {})
        updateFormFields(resetFields)
      }
    })
  }

  onInitialValuesChangedFees(initialValues: any, product: string, setter: React.Dispatch<any>, tForm: FormInstance) {
    if (initialValues !== undefined) {
      setter(this.mergeInitialValuesFees(initialValues, product))
    } else {
      tForm.resetFields()
    }
  }
  setNextClickCounter(val: number) {
    this.nextClickCounter = val
  }

  setActiveTab(val: string) {
    this.activeTab = val
  }

  setSame(val: boolean) {
    this.same = val

    if (val === true) {
      delete this.tarrifTypes.ecom
      this.tarrifTypes.ecom = this.tarrifTypes.pos
      const existTariff = this.dataStore.tariffs.find(i => i.type !== 'ecom')
      existTariff && this.addToTarrifs({ ...existTariff.tariffs, settlementPeriod: 3 }, 'ecom', existTariff.completed)
    } else {
      this.dataStore.setTariffs(this.dataStore.tariffs.filter(t => t.type !== 'ecom'))
    }
  }

  get shouldBeSkipped() {
    const { dataStore } = this.parentStore

    return dataStore.contactInfoData.acquisitionChannel === 'fpms'
  }

  setTarrifTypes(prd: string, type: TariffType) {
    this.tarrifTypes[prd] = type
    this.setNextClickCounter(0)
  }

  get isPartnerWithoutLimits() {
    return this.dataStore.contactInfoData?.acquisitionChannel === 'sunday_app'
  }

  get grossSettlementsByUberIdAllowed() {
    const firstSortCode = this.dataStore.stores[0]?.directDebitBankInfo?.sortCode
    const firstAccNum = this.dataStore.stores[0]?.directDebitBankInfo?.bankAccountNumber
    return this.dataStore.stores.every(
      store =>
        store?.directDebitBankInfo?.sortCode === firstSortCode &&
        store?.directDebitBankInfo?.bankAccountNumber === firstAccNum
    )
  }

  get perCompanyUberIdAllowed() {
    const firstSortCode = this.dataStore.stores[0]?.bankInfo?.sortCode
    const firstAccNum = this.dataStore.stores[0]?.bankInfo?.bankAccountNumber
    return this.dataStore.stores.every(
      store => store?.bankInfo?.sortCode === firstSortCode && store?.bankInfo?.bankAccountNumber === firstAccNum
    )
  }

  get productTypes() {
    return this.dataStore.productTypes
  }

  get isOnlyEcom() {
    return this.dataStore.productTypes.length === 1 && this.dataStore.productTypes[0] === 'ecom'
  }

  get isAliPay() {
    return Boolean(this.dataStore.stores.find(store => store.products.find(p => p.aliPay === true)))
  }

  get isWeChatPay() {
    return Boolean(this.dataStore.stores.find(store => store.products.find(p => p.weChatPay === true)))
  }

  getFilteredTariffsList(productType: string, feeType: string): IcTariffType[] {
    return this.tariffsList.filter(p => p.product === productType && p.tariffType === feeType)
  }

  get isTerminalPurchase() {
    return (
      this.dataStore.productVersion !== PRODUCT_VERSIONS.REDESIGNED &&
      Boolean(
        this.dataStore.stores?.find(store =>
          Boolean(store?.products?.find(product => product?.terminalType === 'terminalPurchase'))
        )
      )
    )
  }

  get isZashEpos() {
    return Boolean(
      this.dataStore.stores?.find(store => Boolean(store?.products?.find(product => product.zashEpos === true)))
    )
  }

  get isChargeable() {
    return Boolean(
      this.dataStore.stores?.find(store =>
        Boolean(store?.products?.find(product => product?.terminalType === 'subscription'))
      )
    )
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  get showSameOption() {
    return this.selectedPricingProduct === 'ecom' && this.dataStore.tariffs?.find(t => t.type === 'pos')?.completed
  }

  addToTarrifs(value: any, t: string, completed: boolean) {
    const exist = this.dataStore.tariffs.find(i => i.type === t)
    if (exist) {
      exist.tariffs = value
      exist.completed = completed
      return
    }

    const item: ITarrifs = {
      type: t,
      tariffs: value,
      completed: completed
    }
    this.dataStore.tariffs.push(item)
  }

  reset() {
    this.isTariffsLoading = false
    this.same = false
    this.nextClickCounter = 0
    this.fullTariffs = []
    this.selectedPricingProduct = ''
  }

  goNext(values: any) {
    this.parentStore.onFinishPricing(values, 'isNextLoading')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  async loadTariffs() {
    if (this.tariffsList.length > 0) return
    try {
      runInAction(() => {
        this.isTariffsLoading = true
      })
      const { result } = await fetchTariffSettings()
      if (!isEmpty(result)) {
        let tariffSettings: IcTariffType[] = []
        result?.forEach(item => {
          tariffSettings.push(...item.tariffSettings)
        })
        this.tariffsList = tariffSettings
      }
    } catch (error) {
      message.error(translations().errorFetchingTariffs)
    } finally {
      runInAction(() => {
        this.isTariffsLoading = false
      })
    }
  }

  async submit(
    tariffType: string,
    formBlended: FormInstance,
    formICP: FormInstance,
    formFees: FormInstance,
    product: string,
    saveForLater: boolean = false
  ) {
    const form = tariffType === 'blended' ? formBlended : formICP

    !saveForLater && (await Promise.all([form.validateFields(), formFees.validateFields()]))

    if (isEmpty(this.tariffsList)) {
      message.error(translations().tariffListNull)
      return
    }

    if (this.isZashEpos) {
      formFees.setFieldsValue({ zAndPayBankApp: true, zashSolution: true })
    } else {
      formFees.setFieldsValue({ zAndPayBankApp: false, zashSolution: false })
    }

    const newTariffs: TariffRequestType = {
      ...form.getFieldsValue(),
      ...formFees.getFieldsValue(),
      tariffType
    }
    const exclude = []

    await Object.entries(newTariffs).forEach(item => {
      if (item[0].includes('Enabled')) {
        !item[1] && exclude.push(item[0].replace('Enabled', ''))
      }

      //remove null
      if (item[1] === undefined || item[1] === null) {
        exclude.push(item[0])
      }
    })

    if (exclude.length > 0) {
      exclude.forEach(key => {
        delete newTariffs[key]
      })
    }

    form.resetFields()
    formFees.resetFields()
    convertStringValuesToNumber(newTariffs, [
      'tariffType',
      'zashSolution',
      'zAndPayBankApp',
      'billingByPaytek',
      'fasterFundingFeeEnabled',
      'fasterFundingMonthlyEnabled',
      'zashAppEnabled',
      'setUpConfigurationDeliveryFeeEnabled',
      'paymentTerminalSupportAndTransactionFeeEnabled',
      'airtimeServiceEnabled',
      'dinersEnabled',
      'amexEnabled',
      'unionPayEnabled',
      'openBankingEnabled',
      'payByBankEnabled',
      'terminalCostEnabled',
      'minMonthlyServiceChargeEnabled',
      'gatewaySetUpFeeEnabled',
      'gatewayFeeEnabled',
      'monthlyGatewayFeeEnabled'
    ])

    await this.addToTarrifs(newTariffs, product, !saveForLater)

    this.goNext(this.dataStore.tariffs)
    this.setSelectedPricingProduct('')
  }

  showWarning(): boolean {
    const checkLength =
      (this.productTypes.length > 1 && this.fullTariffs.length === 1) || this.productTypes.length === 1

    const result = checkLength && this.nextClickCounter === 0 ? true : false
    if (result && this.nextClickCounter === 0) {
      this.setNextClickCounter(this.nextClickCounter + 1)
    }

    return result
  }

  async submitSettlements(formSettlements: FormInstance, forLater?: boolean) {
    const values = formSettlements.getFieldsValue()

    const selectedSettlementType = Object.entries(
      formSettlements.getFieldsValue(['dailyNetSettlements', 'grossSettlementsByMid', 'grossSettlementsByUberId'])
    )?.find(o => o?.[1] === true)?.[0]

    const selectedSettlementPayment = Object.entries(
      formSettlements.getFieldsValue(['perCompanyUberId', 'perOutletMid'])
    )?.find(o => o?.[1] === true)?.[0]

    if (!selectedSettlementPayment) {
      message.error(translations().chooseSettlementPayment)
      return
    }

    if (!selectedSettlementType) {
      message.error(translations().chooseSettlementType)
      return
    }

    const settlInfo: SettlementsInfoDataType = {
      ...values,
      settlementPayment: values.perCompanyUberId ? 'uberid' : values.perOutletMid ? 'mid' : '',
      settlementType: paymentTypeCodeMap[selectedSettlementType] || '',
      settlementName: settlementNameMap[selectedSettlementType],
      chargeMerchant: merchantCharge(selectedSettlementType),
      settlementImmediate: true,
      isCompleted: forLater ? false : true,
      pos: {
        fasterFundingMonthly: (values?.pos?.fasterFundingMonthly && String(values?.pos?.fasterFundingMonthly)) || '',
        fasterFundingFee: (values?.pos?.fasterFundingFee && String(values?.pos?.fasterFundingFee)) || '',
        nextDayFundingRequested: values?.pos?.nextDayFundingRequested,
        settlementFee: (values?.pos?.settlementFee && String(values?.pos?.settlementFee)) || '',
        settlementPeriod: values?.pos?.settlementPeriod && String(values?.pos?.settlementPeriod || '')
      },
      ecom: {
        fasterFundingMonthly: (values?.ecom?.fasterFundingMonthly && String(values?.ecom?.fasterFundingMonthly)) || '',
        fasterFundingFee: (values?.ecom?.fasterFundingFee && String(values?.ecom?.fasterFundingFee)) || '',
        nextDayFundingRequested: values?.ecom?.nextDayFundingRequested,
        settlementFee: (values?.ecom?.settlementFee && String(values?.ecom?.settlementFee)) || '',
        settlementPeriod: values?.ecom?.settlementPeriod && String(values?.ecom?.settlementPeriod || '')
      }
    }

    const compProf: CompanyProfile = {
      settlementInfo: settlInfo
    }

    this.dataStore.updateCompanyProfile(compProf)
    this.setShowSettlementTerms(false)

    this.dataStore.saveApplication('isSaveLoading')
  }

  checkFormFilled(tForm: FormInstance) {
    this.completeEnabled =
      tForm.isFieldsTouched(true) || tForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
  }
}
