import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    applicationSubmitted: string
    contactInfoTitle: string
    generalInformationTitle: string
    pricingTitle: string
    completeApplicationTitle: string
    supportingDocumentsTitle: string
    applicationSubmissionTitle: string
    couldNotLoadCompanies: string
    next: string
    back: string
    errorLoadingApplication: string
    errorLoadingAcquisitionChannels: string
    errorVerifyBankAccount: string
    errorVerifyAddress: string
    errorStartingApplication: string
    newApplication: string
    errorCheckingStatus: string
    applicationAlreadySubmitted: string
    errorSavingData: string
    savedDataSuccessfully: string
    all: string
    errorCheckingSFOpportunityID: string
    errorCheckingDocumentRequirements: string
    pos: string
    moto: string
    ecom: string
    payByLink: string
    payByApp: string
    virtualTerminal: string
    nbsp: string
    deposits: string
    balances: string
    fullPayment: string
    checkedBankAccountInfo: (accNum: string, sortCode: string) => string
    addProductMessage: string
    mustBe: (count: number, companyMode: boolean) => string
    canNotProceed: string
    errorEmailCheck: string
    addStoreMsg: string
    chooseValue: string
    success: string
    error: string
    msaSignError: string
    obrSettingsError: string
    docusignSettingsError: string
    validateBankAccount: string
    accExists: string
    errorVerifyOfficer: string
    ecoSpendError: string
    jumioError: string
    resendError: string
    resendSuccess: string
    errorFetchingTariffs: string
    companyCheckErr: string
    tradeName: string
    tariffListNull: string
    chooseSettlementType: string
    chooseSettlementPayment: string
    invoicedByUberID: string
}>()