import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  settlementPayment: string
  perCompanyUberId: string
  settlementPaymentNote: (isAdditionalProcess: boolean) => ReactNode
  perOutlerMid: string
  settlementType: string
  settlementTypeNote: string
  dailyNetSettlements: string
  monthlyNetSettlements: string
  grossSettlementsByMid: string
  grossSettlementsByUberId: string
  posStandarPeriod: string
  posStandardPeriodNote: string
  settlementPeriod: string
  zeroPlaceholder: string
  settlementFee: string
  posNextDay: string
  posNextDayNote: ReactNode
  nextDayFundingRequested: string
  nextDayFundingFeePerSettlement: string
  feeAppliedForEachSettlement: string
  nextDayFundingFeePerMonth: string
  ecomStandardPeriod: string
  ecomNextDay: string
  save: string
  saveForLater: string
  cancel: string
  feeReducing: string
}>()
