import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    terminalType: string
    store: string
    terminal: string
    all: string
    mid: string
}>()

