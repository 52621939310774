import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { StartProcessStoreContext } from '../../../StartProcessStore'
import { BankInfo } from '../../../models/BankInfo'
import { BankAccountsPopoverProps } from './props'
import styles from './BankAccountsPopover.scss'
import translations from './translations'

export const BankAccountsPopover = observer((props: BankAccountsPopoverProps) => {
  const { onSelect, className } = props
  const store = useContext(StartProcessStoreContext)
  const [isVisible, setVisible] = useState(false)

  const select = (acccount: BankInfo) => {
    setVisible(false)
    onSelect(acccount)
  }

  if (store.existingBankAccounts?.length === 0) {
    return null
  }

  const Content = () => {
    return (
      <div>
        <List title={translations().bankAccounts} accounts={store.existingBankAccounts} onSelect={select} />
      </div>
    )
  }

  return (
    <Popover content={<Content />} open={isVisible} className={className} trigger={['click']} onOpenChange={setVisible}>
      <Button icon={<CopyOutlined />}>{translations().fillInDataFrom}</Button>
    </Popover>
  )
})

type ListProps = { title: string; accounts: BankInfo[]; onSelect(bank: BankInfo): void }
const List = (props: ListProps) => {
  const { accounts, title, onSelect } = props

  if (accounts.length === 0) {
    return null
  }

  return (
    <>
      <h4>{title}</h4>
      <ul className={styles.list}>
        {accounts.map(item => {
          return (
            <li
              key={item?.bankAccountNumber}
              className={styles.listItem}
              onClick={() => onSelect(item)}
            >{`${item?.bankName}(${item?.bankAccountNumber})`}</li>
          )
        })}
      </ul>
    </>
  )
}
