import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

import { FilterSelect, TwoLine } from '~/code/components'
import translations from './translations'
import { StoreAndTerminalFilterProps } from './props'
import styles from './styles.scss'

export const StoreAndTerminalFilter = observer((props: StoreAndTerminalFilterProps) => {
  const { store, className } = props

  const hasTerminalType = store.terminalTypeOptions.length > 1
  const hasStore = store.terminalType === 'pos' && store.storeOptions.length > 0
  const hasMid = store.midOptions.length > 0
  const hasTerminal = store.terminalOptions.length > 0

  if (!store.merchantId || store.merchantId === 'all') return <></>

  return (
    <div
      className={classNames(styles.storeAndTerminalFilter, {
        [className]: className && (hasTerminalType || hasStore || hasMid || hasTerminal)
      })}
    >
      {hasTerminalType && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().terminalType }}
            selectProps={{
              className: styles.terminalTypeSelect,
              loading: store.isLoading,
              value: store.terminalType,
              onChange: store.setTerminalType,
              options: store.terminalTypeOptions
            }}
          />
        </div>
      )}
      {hasStore && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().store }}
            selectProps={{
              className: styles.storeSelect,
              loading: store.isLoading,
              value: store.store,
              showSearch: true,
              onChange: store.setStore,
              optionLabelProp: 'prettiesLabel',
              options: (store.storeOptions.map(s => {
                  const isAll = s.value === 'all'
                  return {
                    value: s.value,
                    label: <TwoLine primary={isAll ? translations().all : s.label} secondary={!isAll && s.subLabel} />,
                    prettiesLabel: isAll ? translations().all : s.label
                  }
                }) || [])
              
            }}
          />
        </div>
      )}
      {hasMid && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().mid, shouldUpdate: true }}
            selectProps={{
              className: styles.terminalSelect,
              loading: store.isLoading,
              value: store.mid,
              onChange: store.setMid,
              options: store.midOptions
            }}
          />
        </div>
      )}
      {hasTerminal && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().terminal, shouldUpdate: true }}
            selectProps={{
              className: styles.terminalSelect,
              loading: store.isLoading,
              value: store.terminal,
              onChange: store.setTerminal,
              options: store.terminalOptions
            }}
          />
        </div>
      )}
    </div>
  )
})
