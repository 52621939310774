import React from 'react'
import { observer } from 'mobx-react'
import { ListPage } from '../ListPage'
import { SettlementsListProps } from './props'
import translations from './translations'

export const SettlementsList = observer((props: SettlementsListProps) => {
  const { settlements, selectSettlements, isSaveLoading, onSave, isContinueLoading, isValid } = props

  return (
    <ListPage
      title={translations().title}
      text={translations().details}
      list={[
        {
          name: translations().settlementsName,
          onClick: () => selectSettlements(true),
          isCompleted: isValid(settlements)
        }
      ]}
      isContinueLoading={isContinueLoading}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addTitle={translations().addTitle}
      sorting={false}
    />
  )
})
