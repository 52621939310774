import { inject, injectable } from 'inversify'
import { cloneObject } from 'dna-common'
import { makeObservable, action, observable, computed } from 'mobx'
import { FormInstance } from 'antd/es/form/Form'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { ISettlementsStatusTableStore } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/ISettlementsStatusTableStore'
import { ISettlementsStatusModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/ISettlementsStatusModalStore'
import { SettlementsStatusModalStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal'
import { HoldTableType } from './models/merchant-dossier-v2'
import {
  AllContractActionEnum,
  AllContractCommonStatusEnum,
  UpdateContractTypeEnum
} from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/constants'

@injectable()
export class SettlementsStatusTableStore implements ISettlementsStatusTableStore {
  storesDossierV2Store: IStoresDossierV2Store
  settlementsStatusModalStore: ISettlementsStatusModalStore
  showHoldReasonsModalShow: boolean = false
  selectedContractValue: string = ''
  selectedContractSettlementValue: boolean = false
  holdTableData: HoldTableType[] = []
  showHoldReasonsModalForAllContracts: boolean = false
  allContractsAction: AllContractActionEnum = null
  allContractsCommonStatus: AllContractCommonStatusEnum = AllContractCommonStatusEnum.midxed
  constructor(
    @inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store,
    @inject(SettlementsStatusModalStoreSymbol) settlementsStatusModalStore: ISettlementsStatusModalStore
  ) {
    this.storesDossierV2Store = storesDossierV2Store
    this.settlementsStatusModalStore = settlementsStatusModalStore
    makeObservable(this, {
      showHoldReasonsModalShow: observable,
      selectedContractValue: observable,
      showHoldReasonsModalForAllContracts: observable,
      selectedContractSettlementValue: observable,
      holdTableData: observable,
      allContractsCommonStatus: observable,
      setShowHoldReasonsModalShow: action.bound,
      setHideHoldReasonsModalShow: action.bound,
      changeHoldStatus: action.bound,
      defineCommonSettlementStatusForAllContracts: action,
      setShowHoldReasonsModalForAllContracts: action,
      setHideHoldReasonsModalForAllContracts: action,
      settlementsReasonsDict: computed,
      tableData: computed
    })
  }

  get settlementsReasonsDict() {
    return this.settlementsStatusModalStore.settlementsReasonDictionary
  }

  get tableData() {
    return this.settlementsStatusModalStore.holdTableData
  }

  setInitialValues() {
    this.selectedContractValue = ''
    this.selectedContractSettlementValue = false
    this.allContractsAction = null
  }

  defineCommonSettlementStatusForAllContracts() {
    const enabledContractsCount = this.tableData.filter(data => data.status === true).length
    const disabledContractsCount = this.tableData.filter(data => data.status === false).length

    if (enabledContractsCount > 0 && disabledContractsCount > 0) {
      this.allContractsCommonStatus = AllContractCommonStatusEnum.midxed
    }
    if (enabledContractsCount === 0 && disabledContractsCount > 0) {
      this.allContractsCommonStatus = AllContractCommonStatusEnum.allOff
    }
    if (enabledContractsCount > 0 && disabledContractsCount === 0) {
      this.allContractsCommonStatus = AllContractCommonStatusEnum.allOn
    }
  }

  setShowHoldReasonsModalShow(contract: string, settlementValue: boolean): void {
    this.showHoldReasonsModalShow = true
    this.selectedContractValue = contract
    this.selectedContractSettlementValue = settlementValue
  }

  setHideHoldReasonsModalShow(): void {
    this.showHoldReasonsModalShow = false
    this.setInitialValues()
  }

  setShowHoldReasonsModalForAllContracts(action: AllContractActionEnum) {
    this.allContractsAction = action
    this.showHoldReasonsModalForAllContracts = true
  }

  setHideHoldReasonsModalForAllContracts(form: FormInstance) {
    this.setInitialValues()
    form.resetFields()
    this.showHoldReasonsModalForAllContracts = false
  }

  changeHoldStatus(
    reason: string,
    form: FormInstance,
    typeOfSettlementsChange: UpdateContractTypeEnum,
    status: AllContractActionEnum
  ) {
    const updatedStatus = status === AllContractActionEnum.allOn
    if (typeOfSettlementsChange === UpdateContractTypeEnum.singleContract) {
      const filteredContract = this.tableData.find(data => data.contract === this.selectedContractValue)
      if (filteredContract) {
        filteredContract.comment = reason
        filteredContract.status = updatedStatus
      }
    } else {
      this.tableData.forEach(data => {
        data.comment = reason
        data.status = updatedStatus
      })
    }
    this.settlementsStatusModalStore.updateSettlementsStatusTableData(cloneObject(this.tableData))
    if (typeOfSettlementsChange === UpdateContractTypeEnum.singleContract) {
      this.setHideHoldReasonsModalShow()
    } else {
      this.setHideHoldReasonsModalForAllContracts(form)
    }
  }
}
