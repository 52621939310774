import translations from '../translations'

export const processInitiationSource = 'dashboard'
export const dossierDefaultVersion = '1'
export const posProductPrefixes = ['03', '08', '15', '19']
export const ecomProductPrefixes = ['04', '05', '06', '07', '21', '22', '18']
export const jsonType = 'Json'
export const stringType = 'String'
export const booleanType = 'Boolean'
export const integerType = 'Integer'
export const doubleType = 'Double'
export const tipJarMidParamId = 56
export const tipJarVanueParamId = 55
export const tipJarDictionaryParamList = `${tipJarVanueParamId},${tipJarMidParamId}`
export const paynecticsDictionaryParamList = '13,25'

export const defaultChargeMethod = 'MID'
export const companyBusinessStructure = 'company'
export const businessAccountType = 'business'
export const soleTraderAccountType = 'personal'
export const grossSettlement = 'Gross'
export const directDebit = 'DD'

export const defaultContractTariffs = {
  contract: null,
  tariffs: [],
  terminals: []
}

export const defaultChangeMethodsOptions = [
  { value: 'MID', label: 'MID' },
  { value: 'UBER_ID', label: 'UBER_ID' }
]

export const defaultSettlementsReasonDictionary = [
  {
    code: null,
    createdAt: null,
    id: null,
    isActive: null,
    updatedAt: null,
    value: null
  }
]

export const defaultContractSettlementsList = [
  {
    id: null,
    reason: null,
    changeDate: null,
    settlementStatus: null,
    contractId: null,
    topLevelSubjectId: null,
    processId: null,
    initiator: null,
    comment: null,
    balance: null,
    contractNumber: null,
    holdInfo: []
  }
]

export const defaultDirectDebitAccount = {
  id: null,
  bankAccountCurrency: null,
  bankAccountNumber: translations().notDefined,
  sortCode: translations().notDefined,
  iban: null,
  swift: null,
  bankAccountName: null,
  bankAccountType: null,
  addDateTime: null,
  isDirectDebit: true
}

export const onlySettlementAccount = 1

export const processStartStatus = 'STARTED'

export const activePaymentsFinancialInstitute = '5'
export const incorrectTerminalsPrefix = 'CC'

export const dnaClientsFinancialInstitute = '1'

export const subsidiariesTenantCodePrefixList = ['ap', 'cc']

export const contractOnHoldStatus = '2'
export const contractWithoutHoldStatus = '1'

export const terminalActiveStatus = '1'

export const tradingAddressType = '7'
export const companyAddressType = '1'

export enum dictionariesEnum {
  merchantCategory = 'd_merchant_category_code',
  settlementReason = 'd_reason'
}

export enum dictionaryParam {
  code = 'code'
}

export const companyBusinessStructureCodeList = ['1', '5']

export const businessOwnerDsrCode = '7'
export const businessOwnerRelationDirection = '1'

export const closeMerchantOrStoreType = {
  closeMerchant: 'merchantClose',
  closeStore: 'storeClose'
}
