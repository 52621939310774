import merge from 'lodash/merge'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { capitalize, cloneObject, isEmpty } from 'dna-common'
import {
  Application,
  CompanyProfile,
  OfficerProfile,
  CompanyType,
  OfficerAddress,
  OfficerRole,
  OnboardingRequest,
  CheckedDocumentVerification,
  PaymentSolution,
  PaymentSolutionView,
  StoreItem,
  BankAccountVerification,
  BirthDateModel,
  ContactInfoType
} from '../models'
import { isPCSCorporate } from './validations'
import { OnboardingRequestBase } from '../models/onboarding/OnboardingRequestBase'
import { OnboardingAddress } from '../models/onboarding/OnboardingAddress'
import { OnboardingOfficer } from '../models/onboarding/OnboardingOfficer'
import { convertStringValuesToNumber, getEmptyAddress } from '.'
import { CheckedDocumentModel } from '../models/DocumentsRequiredModel'
import { convertDateObjectToString } from './converters'
import { UploadFile } from 'antd/lib/upload/interface'
import {
  AdvancePaymentsFormStateModel,
  ProcessingMethodsFormStateModel
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import translations from '../translations'
import { OnboardingCompanyProfile } from '../models/onboarding/OnboardingCompanyProfile'
import { CreditSafeDirectorsInfoItem } from '../models/CreditSafeDirectorsModel'
import { CreditSafeShareholdersInfoItem } from '../models/CreditSafeShareholdersModel'
import { OnboardingSettingsResponseType } from '../models/onboarding/OnboardingSettingsResponseType'
import { BankInfo } from '../models/BankInfo'

const processingMethodsData = [
  {
    key: 'pos',
    description: translations().pos
  },
  {
    key: 'moto',
    description: translations().moto
  },
  {
    key: 'ecom',
    description: translations().ecom
  },
  {
    key: 'payByLink',
    description: translations().payByLink
  },
  {
    key: 'payByApp',
    description: translations().payByApp
  },
  {
    key: 'virtualTerminal',
    description: translations().virtualTerminal
  }
]

const advancePaymentsData = [
  {
    key: 'nbsp',
    description: translations().nbsp
  },
  {
    key: 'deposits',
    description: translations().deposits
  },
  {
    key: 'balances',
    description: translations().balances
  },
  {
    key: 'fullPayment',
    description: translations().fullPayment
  },
  {
    key: 'other',
    hasInput: true
  }
]

export const convertAdvancePayments = (advancePayments: AdvancePaymentsFormStateModel) => {
  return advancePaymentsData.map(payment =>
    payment.hasInput
      ? {
          name: payment.key,
          description: advancePayments[payment.key].description,
          percent: Number(advancePayments[payment.key].percent),
          days: Number(advancePayments[payment.key].days)
        }
      : {
          name: payment.key,
          description: payment.description,
          percent: Number(advancePayments[payment.key].percent),
          days: Number(advancePayments[payment.key].days)
        }
  )
}

export const convertProcessingMethods = (processingMethods: ProcessingMethodsFormStateModel) => {
  return processingMethodsData.map(method => ({
    name: method.key,
    description: method.description,
    percent: Number(processingMethods[method.key].percent)
  }))
}

export const getPaymentSolutionView = (paymentSolution: PaymentSolution, terminalOrders: any): PaymentSolutionView => {
  const motoRequested = terminalOrders && terminalOrders[0]?.motoRequested
  switch (paymentSolution) {
    case PaymentSolution.POS:
      return motoRequested ? PaymentSolutionView.POS_MOTO : PaymentSolutionView.POS
    case PaymentSolution.PAY_BY_LINK:
      return motoRequested ? PaymentSolutionView.PAY_BY_LINK_MOTO : PaymentSolutionView.PAY_BY_LINK
    case PaymentSolution.WEBSITE_PAYMENTS:
      return motoRequested ? PaymentSolutionView.WEBSITE_PAYMENTS_MOTO : PaymentSolutionView.WEBSITE_PAYMENTS
  }
}

export const getPaymentSolution = (paymentSolutionView: PaymentSolutionView): PaymentSolution => {
  switch (paymentSolutionView) {
    case PaymentSolutionView.POS:
    case PaymentSolutionView.POS_MOTO:
      return PaymentSolution.POS
    case PaymentSolutionView.PAY_BY_LINK:
    case PaymentSolutionView.PAY_BY_LINK_MOTO:
      return PaymentSolution.PAY_BY_LINK
    case PaymentSolutionView.WEBSITE_PAYMENTS:
    case PaymentSolutionView.WEBSITE_PAYMENTS_MOTO:
      return PaymentSolution.WEBSITE_PAYMENTS
  }
}

export const getTerminalOrders = (paymentSolutionView: PaymentSolutionView, locationAddress) => {
  return [
    {
      count: 1,
      locationAddress,
      motoRequested: [
        PaymentSolutionView.PAY_BY_LINK_MOTO,
        PaymentSolutionView.POS_MOTO,
        PaymentSolutionView.WEBSITE_PAYMENTS_MOTO
      ].includes(paymentSolutionView),
      paymentSolution: getPaymentSolution(paymentSolutionView),
      storeName: ''
    }
  ]
}

export function parseApplicationWithEmptyValues(
  application: Application,
  companyType: CompanyType = 'sole-trader'
): Application {
  const { companyProfile, companyOfficerList, companyPCSList } = cloneObject(application)
  const parsedApplication = {
    companyProfile: companyProfile ? merge(new CompanyProfile(), companyProfile) : new CompanyProfile(),
    companyOfficerList:
      companyOfficerList && companyOfficerList.map(({ id, ...item }) => merge(new OfficerProfile(), item)),
    companyPCSList: companyPCSList && companyPCSList.map(({ id, ...item }) => merge(new OfficerProfile(), item))
  }
  return parsedApplication
}

export function parseApplicationCompanyInformation(application: any): CompanyProfile {
  const { companyProfile } = application

  return {
    companyName: companyProfile.companyName,
    companyNumber: companyProfile.companyNumber,
    dateOfCreation:
      companyProfile.dateOfCreation && moment(companyProfile.dateOfCreation, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    companyAddress: companyProfile.registeredOfficeAddress && {
      ...companyProfile.registeredOfficeAddress,
      townOrCity: companyProfile.registeredOfficeAddress.locality,
      country: mapSameCountries(companyProfile.registeredOfficeAddress.country)
    },
    companyWebsite: companyProfile.companyWebsite,
    employeesNumber: companyProfile.employeesNumber,
    emailAddress: companyProfile.emailAddress,
    telephoneNumber: companyProfile.telephoneNumber,
    bankAccountNumber: companyProfile.bankAccountNumber,
    sortCode: companyProfile.sortCode,
    bankName: companyProfile.bankName,
    accountName: companyProfile.accountName
  }
}

export function parseCompanyOfficersAndPcs(application: any): Application {
  const { companyOfficerList, companyPCSList } = application

  return {
    companyOfficerList: companyOfficerList
      ? companyOfficerList.map(item => ({
          id: uuid(),
          name: item.name,
          nameElements: parseCompanyOfficerName(item.name),
          gender: item.gender,
          birthplace: item.birthplace,
          nationality: mapSameNationalities(item.nationality),
          dateOfBirth: item.dateOfBirth,
          officerRole: item.officerRole,
          occupation: item.occupation,
          emailAddress: item.emailAddress,
          telephoneNumber: item.telephoneNumber
        }))
      : [],
    companyPCSList: companyPCSList
      ? companyPCSList
          .filter(item => isEmpty(item.ceasedOn))
          .map(item => ({
            id: uuid(),
            name: item.name,
            nameElements: item.nameElements,
            gender: item.gender,
            birthplace: item.birthplace,
            occupation: item.occupation,
            nationality: mapSameNationalities(item.nationality),
            dateOfBirth: item.dateOfBirth,
            kind: item.kind,
            identification: item.identification,
            address: isPCSCorporate(item) ? item.address : {},
            emailAddress: item.emailAddress,
            telephoneNumber: item.telephoneNumber
          }))
      : []
  }
}

export function parseCompanyOfficersAndPcsFromCreditSafe(
  directors: CreditSafeDirectorsInfoItem[],
  shareholders: CreditSafeShareholdersInfoItem[]
): Application {
  return {
    companyOfficerList:
      directors?.length > 0
        ? directors
            .filter(item => item.directorType?.toLocaleLowerCase() === 'person')
            .map(item => ({
              deletionAllowed: false,
              id: uuid(),
              name: `${item.firstName} ${item.surname}`,
              nameElements: {
                surname: capitalize(item.surname?.trim()),
                forename: item.firstName?.trim(),
                middleName: item?.middleName || '',
                title: item.title
              },
              gender: item.gender,
              birthplace: '',
              nationality: mapSameNationalities(item.nationality),
              officerRole: OfficerRole.director,
              fromApi: true
            }))
        : [],
    companyPCSList:
      shareholders?.length > 0
        ? shareholders
            .filter(item => item.percentSharesHeld && Number(item.percentSharesHeld) > 24)
            .map(item => ({
              deletionAllowed: false,
              ownershipPercentage: Number(item.percentSharesHeld),
              id: uuid(),
              name: item.name,
              nameElements: parseCompanyOfficerName(item.name),
              kind: item.shareholderType,
              identification: { registrationNumber: item.regNo },
              fromApi: true
            }))
        : []
  }
}

export function parseCompanyOfficerName(name: string) {
  name = name ? name.trim() : ''

  const nameArray = name.split(/\s+/)
  const forename = nameArray[0]
  const surname = nameArray[nameArray.length - 1]
  const names = [...nameArray].slice(1, nameArray.length - 1)

  return {
    surname: capitalize(surname.trim()),
    forename: forename.trim(),
    middleName: names.map(n => n.trim()).join(' ')
  }
}

export function mapSameCountries(country: string) {
  switch (country.toLocaleLowerCase()) {
    case 'england':
    case 'great britain':
    case 'uk':
    case 'scotland':
    case 'wales':
    case 'northern ireland':
      return 'United Kingdom'
  }
  return country
}

export function mapSameNationalities(nationality: string) {
  switch (nationality?.toLocaleLowerCase()) {
    case 'english':
    case 'northern irish':
    case 'scottish':
    case 'welsh':
    case 'british':
      return 'British'
  }
  return nationality
}

export const parseOnboardingRequestData = (
  application: Application,
  tariffs: any = undefined,
  stores: StoreItem[] = [],
  activeTabKey?: string,
  processVersion?: string,
  contactInfo?: ContactInfoType,
  envelopeId?: string,
  settings?: OnboardingSettingsResponseType,
  accounts?: BankInfo[],
  sentToDocusign?: boolean,
  isManualMSA?: boolean,
  productSelectionVersion?: string
): OnboardingRequest => {
  let { companyProfile } = application
  const { companyOfficerList, companyPCSList } = application
  const agreementOfficer =
    application?.companyOfficerList?.find(o => o.agreementSignatory === true) ||
    application?.companyPCSList?.find(o => o.agreementSignatory === true)

  if (!companyProfile) {
    companyProfile = new CompanyProfile()
  }

  const {
    relationshipType,
    companyName,
    companyNumber,
    dateOfCreation,
    employeesNumber,
    companyWebsite,
    emailAddress,
    telephoneNumber,
    accountName,
    bankAccountType,
    bankAccountNumber,
    sortCode,
    bankName,
    companyAddress,
    companyType,
    directorsPartnersCount,
    charityComission,
    charityNumber,
    merchantCategoryCode,
    merchantCategoryCodeDescription,
    directDebitAccount,
    directDebitBankDetails,
    subscriptionServicePeriod,
    freeSubscriptionPeriod,
    terminalType,
    chargeType,
    onboardingCountryInfo,
    comments,
    otherDescription,
    countryOfInitialRegistration,
    companyTradeName,
    initialBoardingDate,
    settlementInfo
  } = companyProfile

  const base: OnboardingRequestBase = {
    businessStructure: companyType,
    stores,
    currentStep: activeTabKey,
    version: processVersion,
    applicantInfo: {
      firstName: agreementOfficer?.nameElements?.forename || contactInfo?.firstName,
      surname: agreementOfficer?.nameElements?.surname || contactInfo?.surname,
      emailAddress: agreementOfficer?.emailAddress || emailAddress,
      telephoneNumber: agreementOfficer?.telephoneNumber || contactInfo?.phoneNumber,
      acquisitionChannel: contactInfo?.acquisitionChannel,
      opportunityId: contactInfo?.salesforceOpportunityID,
      segmentCategory: contactInfo?.segmentCategory,
      segmentSubCategory: contactInfo?.segmentSubCategory
    },
    docusignEnvelopeId: envelopeId,
    processSettings: settings,
    bankAccounts: accounts,
    sentToDocusign: sentToDocusign,
    isManualMSA: isManualMSA,
    productVersion: productSelectionVersion
  }

  if (tariffs?.length > 0 && !isEmpty(settlementInfo)) {
    const numberSettlement = cloneObject(settlementInfo)
    convertStringValuesToNumber(numberSettlement)

    tariffs?.forEach(t => {
      if (t.type === 'pos') {
        t.tariffs = { ...t.tariffs, ...numberSettlement?.pos }
      }

      if (t.type === 'ecom') {
        t.tariffs = { ...t.tariffs, ...numberSettlement?.ecom }
      }
    })
  }

  const tariffsData = tariffs ? { productTariffs: tariffs } : {}

  const soleTraderCompanyProfile: OnboardingCompanyProfile = {
    relationshipType,
    companyName,
    dateOfCreation: dateOfCreation ? moment(dateOfCreation, 'YYYY / MM / DD').format('YYYY-MM-DD') : '',
    numberOfEmployees: employeesNumber,
    companyWebsite,
    emailAddress,
    telephoneNumber,
    bankAccountType,
    bankAccountName: accountName,
    bankAccountNumber,
    sortCode,
    bankName,
    companyType,
    companyNumber,
    directorsPartnersCount,
    charityComission,
    charityNumber,
    companyAddress: companyAddress && parseAddress(companyAddress, true),
    merchantCategoryCode,
    merchantCategoryCodeDescription,
    directDebitAccount,
    directDebitBankDetails,
    subscriptionServicePeriod,
    freeSubscriptionPeriod,
    terminalType,
    chargeType,
    onboardingCountryInfo,
    comments,
    otherDescription,
    countryOfInitialRegistration,
    companyTradeName,
    initialBoardingDate,
    settlementInfo
  }

  if (companyType === 'company' || companyType === 'llp') {
    const corporateEntityShareholders = companyPCSList
      .filter(s => isPCSCorporate(s))
      .map(s => ({
        companyName: s.name,
        companyNumber: s.identification?.registrationNumber || '',
        fromApi: s.fromApi || false,
        ownershipPercentage: s.ownershipPercentage
      }))

    const individualShareholders = companyPCSList.filter(s => !isPCSCorporate(s)).map(s => parseOfficerProfile(s))

    const companyDirectors = companyOfficerList
      .filter(o => o.officerRole === OfficerRole.director)
      .map(o => parseOfficerProfile(o))

    return {
      ...base,
      companyProfile: soleTraderCompanyProfile,
      companyDirectors,
      corporateEntityShareholders,
      individualShareholders,
      ...tariffsData
    }
  }

  const owners = companyOfficerList
    ?.filter(o => o.officerRole === OfficerRole.businessOwner)
    .map(o => parseOfficerProfile(o))

  const owner = companyOfficerList?.find(o => o.officerRole === OfficerRole.businessOwner)

  return {
    ...base,
    companyProfile: soleTraderCompanyProfile,
    businessOwner: owner && parseOfficerProfile(owner),
    businessOwners: owners,
    ...tariffsData
  }
}

export const parseAddress = (address: OfficerAddress, isCompany?: boolean): OnboardingAddress => {
  address = address ? address : getEmptyAddress()

  const { addressLine1, addressLine2, country, postalCode, region, county, locality, townOrCity } = address

  return {
    addressLine1,
    addressLine2,
    country,
    postalCode,
    county,
    countyOrState: region,
    townOrCity: isCompany ? townOrCity : locality
  }
}

export const parseOfficerProfile = (officer: OfficerProfile): OnboardingOfficer => {
  officer = new OfficerProfile(officer)

  const {
    nameElements: { title, forename, middleName, surname },
    dateOfBirth,
    gender,
    birthplace,
    nationality,
    occupation,
    emailAddress,
    telephoneNumber,
    address,
    deletionAllowed,
    ownershipPercentage,
    fromApi,
    officerVerificationResult,
    agreementSignatory,
    jumioCheckResults,
    jumioRequestSent,
    uploadDocManually,
    idDocumentNumber,
    idDocumentExpirationDate,
    vulnerability
  } = officer

  const { houseName, houseNumber, ...restAddress } = address

  return {
    title,
    firstName: forename,
    middleName,
    surname,
    dateOfBirth,
    gender,
    placeOfBirth: birthplace,
    nationality,
    occupation,
    emailAddress,
    telephoneNumber,
    ownershipPercentage,
    residentialAddress: {
      ...parseAddress(restAddress),
      houseName,
      houseNumber
    },
    deletionAllowed,
    fromApi: fromApi || false,
    officerVerificationResult,
    agreementSignatory,
    jumioCheckResults,
    jumioRequestSent,
    uploadDocManually,
    idDocumentNumber,
    idDocumentExpirationDate,
    vulnerability
  }
}

export const parseCheckedDocumentVerification = (
  officer: CheckedDocumentVerification,
  fileList: UploadFile[]
): CheckedDocumentModel => {
  return {
    isRequired: !officer.verified,
    officer: `${officer.firstName} ${officer.surname}, ${convertDateObjectToString(
      officer.dateOfBirth,
      'DD.MM.YYYY'
    )}, ${officer.residentialAddress.postalCode}, ${officer.residentialAddress.country}`,
    fileList: fileList && fileList.length > 0 ? fileList : [],
    isLoading: false
  }
}

export const parseCheckedAccountVerification = (
  bank: BankAccountVerification,
  fileList: UploadFile[],
  id: number
): CheckedDocumentModel => {
  return {
    isRequired: !bank.verified,
    officer: translations().checkedBankAccountInfo(bank.bankAccountNumber, bank.sortCode),
    fileList: fileList && fileList.length > 0 ? fileList : [],
    isLoading: false
  }
}

export const parseIsoDate = (dt: string): BirthDateModel => {
  const parsedDate = new Date(dt)
  return {
    day: parsedDate.getDate(),
    month: parsedDate.getMonth() + 1,
    year: parsedDate.getFullYear()
  }
}

export const parseSortCode = (str: string): string => {
  const matches = str.match(/.{1,2}/g)
  return matches.join('-')
}
