export const dnaFeeList = ['dnapConsumerDebit', 'dnapConsumerCredit', 'dnapCorporateDebit', 'dnapCorporateCredit']
export const defaultSchemeFeesList = [
  'mcUkConsumerDebitSc',
  'mcUkConsumerCreditSc',
  'mcUkCorporateDebitSc',
  'mcUkCorporateCreditSc',
  'mcEuConsumerDebitSc',
  'mcEuConsumerCreditSc',
  'mcEuCorporateDebitSc',
  'mcEuCorporateCreditSc',
  'mcIntConsumerDebitSc',
  'mcIntConsumerCreditSc',
  'mcIntCorporateDebitSc',
  'mcIntCorporateCreditSc',
  'visaUkConsumerDebitSc',
  'visaUkConsumerCreditSc',
  'visaUkCorporateDebitSc',
  'visaUkCorporateCreditSc',
  'visaEuConsumerDebitSc',
  'visaEuConsumerCreditSc',
  'visaEuCorporateDebitSc',
  'visaEuCorporateCreditSc',
  'visaIntConsumerDebitSc',
  'visaIntConsumerCreditSc',
  'visaIntCorporateDebitSc',
  'visaIntCorporateCreditSc'
]
