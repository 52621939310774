import { inject, injectable } from 'inversify'
import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { FormInstance, message } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { log } from 'dna-common'
import { AppStore } from '../AppStore'
import { traverseObject } from '~/code/services'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { IEditTariffsTableStore } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsTable/IEditTariffsTableStore'
import {
  GroupedTerminalsDataType,
  TariffType,
  BlendedTariffEditTabTableType,
  TerminalTariffType,
  TariffsComplexValuesType,
  ProductType,
  EditTerminalTariffsReqType
} from './models/merchant-dossier-v2'
import {
  TariffTypeEnum,
  TariffGroupTypeEnum,
  TariffTypeChangeEnum
} from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/constants'
import { TariffGroupedSettingsType } from '~/code/pages/MerchantsManagement/components/Stores/components/Contract/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import {
  FieldsListType,
  ValuesListType,
  FormValuesListType,
  DNAFeeType,
  ComponentsType
} from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsTable/models'
import { notCompositeTariffsList } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsTable/constants'
import { tariffComparisonTableDataType } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsConfirmTable/models'
import { icPlusToBlendedDefaultValues } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsConfirmTable/services'
import { EditTariffsModalStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal'
import { IEditTariffsModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/IEditTariffsModalStore'
import { startProcessEditTariffs, getTariffSettings } from './services/fetchers'
import { jsonType, stringType, booleanType } from './constants'
import translations from './translations'

@injectable()
export class EditTariffsTableStore implements IEditTariffsTableStore {
  isLoading: boolean = false
  storesDossierV2Store: IStoresDossierV2Store
  editTariffsModalStore: IEditTariffsModalStore
  completeEnabled: boolean = false
  isEditTariffsIsLoading: boolean = false
  showWarningModal: boolean = false
  tabSettings: TariffGroupedSettingsType[] = []
  tariffSettings: TariffGroupedSettingsType[] = []

  constructor(
    @inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store,
    @inject(EditTariffsModalStoreSymbol) editTariffsModalStore: IEditTariffsModalStore
  ) {
    this.storesDossierV2Store = storesDossierV2Store
    this.editTariffsModalStore = editTariffsModalStore
    makeObservable(this, {
      isLoading: observable,
      completeEnabled: observable,
      isEditTariffsIsLoading: observable,
      showWarningModal: observable,
      tariffSettings: observable,
      getBlendedTariffs: action,
      checkFormFilled: action,
      clearData: action,
      getTariffSettings: action,
      selectedMidStoreInfo: computed,
      contractTariffSettings: computed
    })
  }

  get selectedMidStoreInfo(): string {
    return this.storesDossierV2Store.selectedMidStoreInfo
  }

  get contractTariffSettings(): TariffGroupedSettingsType[] {
    return this.tariffSettings
  }

  get posSettlementPeriod() {
    return this.storesDossierV2Store.posSettlementPeriod
  }

  get ecomSettlementPeriod() {
    return this.storesDossierV2Store.ecomSettlementPeriod
  }

  get isEuroClient() {
    return this.storesDossierV2Store.isEuroClient
  }

  clearSelectedTerminal() {
    this.editTariffsModalStore.setSelectedTerminal(null)
  }

  clearData() {
    this.tariffSettings = []
  }

  async getData(product: ProductType, terminalGroup: string) {
    await this.getTariffSettings(terminalGroup, product)
  }

  setTariffSettings(data: TariffGroupedSettingsType[]): void {
    this.tariffSettings = data
  }

  setBackButtonVisibility(isShown: boolean): void {
    this.editTariffsModalStore.setBackButtonVisibility(isShown)
  }

  async getTariffSettings(terminalGroup: string, product: string) {
    try {
      const { status, result, error } = await getTariffSettings(terminalGroup, product.toLowerCase())
      if (status !== 200 || error) {
        message.error(error.message)
      } else {
        this.setTariffSettings(result)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  getBlendedTariffs = (product: string, formValuesList: FormValuesListType): BlendedTariffEditTabTableType[] => {
    const tariffs = []
    const tariffGroup = this.tariffSettings.find(
      setting => setting.key === TariffGroupTypeEnum.changeFeesBlended && setting.product === product.toLowerCase()
    )
    if (tariffGroup) {
      const terminalTariffs = tariffGroup.groups?.find(group => group.group.code === 'termianlTariffs')
      if (terminalTariffs) {
        terminalTariffs.tariffSettings.forEach((item, idx) => {
          tariffs.push({
            key: idx,
            id: item.id,
            cardScheme: item.cardScheme,
            serviceArea: item.serviceArea,
            serviceAreaCode: item.serviceAreaCode,
            corporateOrConsumer: item.corporateOrConsumer,
            cardType: item.cardType,
            interchangeFee: {
              key: item.interchangeFee.key,
              baseFee: formValuesList[item.interchangeFee.key]?.['base'],
              percentFee: formValuesList[item.interchangeFee.key]?.['percent'],
              minBaseFee: item.interchangeFee.minBaseFee,
              minPercentFee: item.interchangeFee.minPercentFee,
              maxBaseFee: item.interchangeFee.maxBaseFee,
              maxPercentFee: item.interchangeFee.maxPercentFee
            }
          })
        })
      }
    }
    return tariffs
  }

  getDNAFees = (product: string, valuesList: ValuesListType): DNAFeeType[][] => {
    const tariffs: DNAFeeType[][] = []
    const tariffGroup = this.tariffSettings.find(
      setting => setting.key === TariffGroupTypeEnum.changeFeesICPlus && setting.product === product.toLowerCase()
    )
    if (!tariffGroup || !tariffGroup.groups) return []
    const dnaTariffs = tariffGroup.groups.find(group => group.group.code === 'dnaFee')
    if (!dnaTariffs || !dnaTariffs.tariffSettings) return []
    const chunkSize = 4
    for (let i = 0; i < dnaTariffs.tariffSettings.length; i += chunkSize) {
      const tariffElements = dnaTariffs.tariffSettings.slice(i, i + chunkSize)
      const rowItems = tariffElements.map(({ interchangeFee }) => ({
        code: interchangeFee.key,
        value: valuesList?.[interchangeFee.key],
        component: interchangeFee.components,
        maxBaseFee: interchangeFee.maxBaseFee,
        minBaseFee: interchangeFee.minBaseFee,
        maxPercentFee: interchangeFee.maxPercentFee,
        minPercentFee: interchangeFee.minPercentFee
      }))
      tariffs.push(rowItems)
    }
    return tariffs
  }

  getTariffType = (terminal: GroupedTerminalsDataType): TariffTypeEnum => {
    const icPlusTariff = terminal.tariffs.find(terminal => terminal.tariffType === TariffTypeEnum.icPlus)
    return icPlusTariff ? TariffTypeEnum.icPlus : TariffTypeEnum.blended
  }

  getTariffsTableValues = (data: TariffType[]): TariffsComplexValuesType => {
    const result: TariffsComplexValuesType = {}
    for (const item of data) {
      ;[item.interchangeFee].forEach(el => {
        result[el?.key] = { base: el?.baseFee, percent: el?.percentFee }
      })
    }
    return result
  }

  checkFormFilled(tForm: FormInstance) {
    this.completeEnabled =
      tForm.isFieldsTouched(true) || tForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
  }

  convertStringValuesToNumber(obj: object) {
    traverseObject(obj, (value, key, subObj) => {
      subObj[key] = typeof value === undefined || value === null ? value : Number(value)
    })
  }

  setPropertyIfAbsent(target: TerminalTariffType, property: string, defaultValue: Moment | number) {
    if (!target.hasOwnProperty(property)) {
      target[property] = defaultValue || null
    }
  }

  updateFinalObject(finalObject: TerminalTariffType, initValues: TerminalTariffType) {
    const hasEffectiveFrom = finalObject.hasOwnProperty('effectiveFrom')
    const hasPaymentTerminalSupportAndTransactionFee = finalObject.hasOwnProperty(
      'paymentTerminalSupportAndTransactionFee'
    )

    if (hasEffectiveFrom && !hasPaymentTerminalSupportAndTransactionFee) {
      this.setPropertyIfAbsent(
        finalObject,
        'paymentTerminalSupportAndTransactionFee',
        initValues?.paymentTerminalSupportAndTransactionFee
      )
    }

    if (hasPaymentTerminalSupportAndTransactionFee && !hasEffectiveFrom) {
      this.setPropertyIfAbsent(finalObject, 'effectiveFrom', initValues?.effectiveFrom)
    }
  }

  calculateFinalObject = (initValues: TerminalTariffType, formFees: FormInstance): TerminalTariffType => {
    const finalObject: TerminalTariffType = {}
    for (const [key, value] of Object.entries(formFees.getFieldsValue())) {
      let initValue = initValues[key]
      let finalValue: any = value
      if (
        (typeof initValue === 'object' && initValue !== null) ||
        (typeof finalValue === 'object' && finalValue !== null)
      ) {
        this.convertStringValuesToNumber(finalValue)
        if (JSON.stringify(initValue) !== JSON.stringify(finalValue)) {
          finalObject[key] = value
        }
      } else {
        if (initValue === null || initValue === undefined) initValue = ''
        if (finalValue === null || finalValue === undefined) finalValue = ''
        if (initValue.toString() !== finalValue.toString()) {
          finalObject[key] = value || 0
        }
      }
    }
    this.updateFinalObject(finalObject, initValues)
    return finalObject
  }

  hasValuesBeenChanged = (
    initValues: TerminalTariffType,
    formFees: FormInstance,
    initTatiffType?: TariffTypeEnum,
    newTariffType?: TariffTypeEnum
  ): {
    finalValuesList: TerminalTariffType
    isFieldsChanged: boolean
  } => {
    const finalObject = this.calculateFinalObject(initValues, formFees)
    if (Object.keys(finalObject).length === 0) {
      if (initTatiffType && newTariffType && initTatiffType !== newTariffType) {
        return { finalValuesList: finalObject, isFieldsChanged: true }
      } else {
        message.error(translations().errorNoChanges)
        return { finalValuesList: finalObject, isFieldsChanged: false }
      }
    } else {
      return { finalValuesList: finalObject, isFieldsChanged: true }
    }
  }

  generateFieldsList(
    tariffType: TariffTypeEnum,
    product: ProductType
  ): { tabSettings: TariffGroupedSettingsType[]; fieldsList: FieldsListType[] } {
    let filteredSettings = []
    if (this.storesDossierV2Store.isEuroClient) {
      filteredSettings = this.tariffSettings
    } else {
      const defaultTariffType =
        tariffType === TariffTypeEnum.blended
          ? TariffGroupTypeEnum.changeFeesBlended
          : TariffGroupTypeEnum.changeFeesICPlus
      const settings = this.tariffSettings
      filteredSettings = settings.filter(
        item => item.key === defaultTariffType && item.product === product.toLocaleLowerCase()
      )
    }
    const tabSettings = filteredSettings

    const fieldsList = filteredSettings.reduce((acc, setting) => {
      if (setting?.groups) {
        setting.groups.forEach(group => {
          group.tariffSettings.forEach(tariffSetting => {
            acc.push({
              key: tariffSetting?.interchangeFee?.key,
              dsrKey: tariffSetting?.interchangeFee?.dsrKey,
              components: tariffSetting?.interchangeFee?.components
            })
            if (tariffSetting?.schemeFee) {
              acc.push({
                key: tariffSetting.schemeFee?.key,
                dsrKey: tariffSetting.schemeFee?.dsrKey,
                components: tariffSetting.schemeFee?.components
              })
            }
          })
        })
      }
      return acc
    }, [])
    return { tabSettings, fieldsList }
  }

  generateValuesList(
    data: GroupedTerminalsDataType,
    contractTariffs: TariffType[],
    fieldsList: FieldsListType[],
    tabSettings: TariffGroupedSettingsType[],
    tariffType: TariffTypeEnum
  ): ValuesListType {
    const valuesList = {}
    const processFee = (key: string, feeBase: number, feePercent: number, effectiveFrom: string = null) => {
      valuesList[key] = { tariffCode: key, feeBase, feePercent }
      if (key === 'paymentTerminalSupportAndTransactionFee') valuesList['effectiveFrom'] = effectiveFrom
    }

    if (tariffType === TariffTypeEnum.blended && tabSettings?.length > 0) {
      const settings = tabSettings[0].groups?.filter(group => group?.group.code === 'termianlTariffs')
      if (settings[0]?.tariffSettings?.length > 0) {
        settings[0]?.tariffSettings.forEach(tariffItem => {
          const key = tariffItem.interchangeFee?.key
          const schemeKey = tariffItem.schemeFee?.key
          processFee(key, tariffItem.interchangeFee?.baseFee, tariffItem.interchangeFee?.percentFee)
          processFee(schemeKey, tariffItem.schemeFee?.baseFee, tariffItem.schemeFee?.percentFee)
        })
      }
    } else {
      data.tariffs.forEach(tariffItem => {
        const key = tariffItem.interchangeFee?.key
        const dnaKey = tariffItem.standardFee?.key
        const schemeKey = tariffItem.schemeFee?.key
        processFee(key, tariffItem.interchangeFee?.baseFee, tariffItem.interchangeFee?.percentFee)
        processFee(dnaKey, tariffItem.standardFee?.baseFee, tariffItem.standardFee?.percentFee)
        processFee(schemeKey, tariffItem.schemeFee?.baseFee, tariffItem.schemeFee?.percentFee)
      })
    }

    const generalTariffs = [
      ...data.generalTariffs.filter(tariff => tariff.id !== 0),
      ...contractTariffs.filter(contract => contract.id !== 0)
    ]

    generalTariffs.forEach(genTariffItem => {
      const fieldName = fieldsList.find(item => {
        return item.dsrKey === genTariffItem.tariffCode
      })?.key
      if (fieldName)
        processFee(fieldName, genTariffItem?.feeBase, genTariffItem?.feePercent, genTariffItem?.effectiveFrom)
    })
    return valuesList
  }

  generateFormValuesList(fieldsList: FieldsListType[], valuesList: ValuesListType): FormValuesListType {
    const formValues = {} as FormValuesListType
    const getFeeValue = (fee: number | undefined | null) => {
      return fee !== undefined && fee !== null ? fee : null
    }
    const getFeeObject = (
      base: number | undefined | null,
      percent: number | undefined | null,
      component: ComponentsType
    ) => {
      switch (component) {
        case 'base':
          return { base: getFeeValue(base) }
        case 'percent':
          return { percent: getFeeValue(percent) }
        default:
          return {
            base: getFeeValue(base),
            percent: getFeeValue(percent)
          }
      }
    }
    fieldsList.forEach(field => {
      const value = valuesList[field.key]
      if (notCompositeTariffsList.includes(field.key)) {
        formValues[field.key] = value?.feeBase
        if (field.key === 'paymentTerminalSupportAndTransactionFee' && typeof valuesList?.effectiveFrom === 'string')
          formValues.effectiveFrom = moment(valuesList?.effectiveFrom) || null
      } else {
        formValues[field.key] = getFeeObject(value?.feeBase, value?.feePercent, field.components)
      }
    })
    return formValues
  }

  createEditTerminalTariffsData(
    dsrId: number,
    finalValuesList: TerminalTariffType,
    contractList: string,
    selectedContractNumber: string,
    companyName: string,
    companyNumber: string,
    comment: string,
    tariffType: string,
    effectiveFrom: Moment,
    changeTariffType: boolean,
    changeTariff: TariffTypeChangeEnum,
    tableSource: tariffComparisonTableDataType[]
  ): EditTerminalTariffsReqType {
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiator: {
          value: AppStore.authStore.email,
          type: stringType
        },
        tariffs: {
          value:
            changeTariff === TariffTypeChangeEnum.icPlusToBlended
              ? JSON.stringify({ ...finalValuesList, ...icPlusToBlendedDefaultValues })
              : JSON.stringify({ ...finalValuesList }),
          type: jsonType
        },
        contracts: {
          value: contractList,
          type: stringType
        },
        contractNumber: {
          value: selectedContractNumber,
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        reason: {
          value: comment,
          type: stringType
        },
        tariffType: {
          value: tariffType,
          type: stringType
        },
        effectiveDate: {
          value: effectiveFrom ? moment(effectiveFrom).format('YYYY-MM-DD') : null,
          type: stringType
        },
        changeTariffType: {
          value: changeTariffType,
          type: booleanType
        },
        changeTariff: {
          value: changeTariff,
          type: stringType
        },
        tableSource: {
          value: JSON.stringify(tableSource),
          type: jsonType
        }
      }
    }
  }

  async startEditTerminalTariffs(
    initValues: TerminalTariffType,
    formFees: FormInstance,
    contractList: string = '',
    formConfirmation: FormInstance,
    tariffType: TariffTypeEnum,
    initTariffType: TariffTypeEnum,
    changeTariffType: boolean,
    changeTariff: TariffTypeChangeEnum,
    tableSource: tariffComparisonTableDataType[]
  ) {
    const { finalValuesList } = this.hasValuesBeenChanged(initValues, formFees, tariffType, initTariffType)
    const effectiveFromDate = finalValuesList.effectiveFrom
    delete finalValuesList.effectiveFrom
    const comment = formConfirmation.getFieldValue('comment')
    this.convertStringValuesToNumber(finalValuesList)
    try {
      runInAction(() => {
        this.isEditTariffsIsLoading = true
      })

      const selectedContractNumber = this.storesDossierV2Store.editTariffsContract
      const { companyName, companyNumber, dsrId } = this.storesDossierV2Store.companyFullDossierV2?.mainInfo
      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }
      const tariffsData = this.createEditTerminalTariffsData(
        dsrId,
        finalValuesList,
        contractList,
        selectedContractNumber,
        companyName,
        companyNumber,
        comment,
        tariffType,
        effectiveFromDate,
        changeTariffType,
        changeTariff,
        tableSource
      )
      const { status, error, result } = await startProcessEditTariffs(tariffsData)
      if (status !== 200 || error) {
        message.error(error.message || translations().errorEditingTariffs)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        formFees.resetFields()
        this.clearSelectedTerminal()
        this.storesDossierV2Store.closeEditTariffsModal()
      }
    } catch (error) {
      log(error)
      message.error(translations().errorEditingTariffs)
    } finally {
      runInAction(() => {
        this.isEditTariffsIsLoading = false
      })
    }
  }
}
