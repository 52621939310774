import React from 'react'
import classNames from 'classnames'
import { Drawer, Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { SiderProps } from './props'
import styles from './styles.scss'

const siderExpandedWidth = 300
const siderCollapsedWidth = 60

export const Sider: React.FC<SiderProps> = props => {
  const { isMobile, className, collapsed, onCollapse, children, ...rest } = props

  if (isMobile) {
    return (
      <Drawer
        open={!collapsed}
        placement='left'
        className={styles.drawer}
        closable={false}
        width={siderExpandedWidth}
        onClose={() => onCollapse(true, 'clickTrigger')}
      >
        {children}
      </Drawer>
    )
  }

  return (
    <>
      <div className={styles.siderSpace} style={{ width: collapsed ? siderCollapsedWidth : siderExpandedWidth }} />
      <Layout.Sider
        className={classNames(className, styles.sider)}
        collapsible
        collapsed={collapsed}
        collapsedWidth={siderCollapsedWidth}
        width={siderExpandedWidth}
        trigger={
          <div className={styles.trigger} onClick={() => onCollapse(!collapsed, 'clickTrigger')}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        }
        children={children}
        {...rest}
      />
    </>
  )
}
