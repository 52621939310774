import React from 'react'
import { observer } from 'mobx-react'
import { Table, Form, Radio } from 'antd'
import { TariffTypeEnum, TariffTypeChangeEnum } from '../../../constants'
import { dnaFeeList } from '../../EditTariffsConfirmTable/constants/constants'
import { defineTariffUpdateType } from '../../EditTariffsConfirmTable/services'
import { TariffComparisonTableProps } from './props'
import { ICPlusTableColumns, BlendedTableColumns } from './constants'
import translations from '../translations'
import styles from './TariffComparisonTable.scss'

export const TariffComparisonTable = observer((props: TariffComparisonTableProps) => {
  const { data, form, tariffType, initTariffType } = props

  const dnaComissions = form.getFieldsValue(dnaFeeList)
  const changeType = defineTariffUpdateType(tariffType, initTariffType)

  return (
    <Form form={form}>
      <Form.Item>
        <div className={styles.updateInfo}>
          {changeType === TariffTypeChangeEnum.blendedToICPlus && translations().changeFromBlendedToIcPlus}
          {changeType === TariffTypeChangeEnum.icPlusToBlended && translations().changeFromIcPlusToBlended}
        </div>
        <Radio.Group disabled value={tariffType} className={styles.radioGroup}>
          <Radio id='blended' value={TariffTypeEnum.blended}>
            {translations().blended}
          </Radio>
          <Radio id='icPlus' value={TariffTypeEnum.icPlus}>
            {translations().icPlus}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Table
        scroll={{ x: 200 }}
        columns={tariffType === TariffTypeEnum.blended ? BlendedTableColumns(dnaComissions) : ICPlusTableColumns}
        dataSource={data?.map((p, idx) => ({
          key: idx,
          ...p
        }))}
        pagination={false}
        size='small'
        bordered
      />
    </Form>
  )
})
