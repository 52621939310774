import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'antd'
import classNames from 'classnames'
import { CustomButton } from '~/code/components'
import {
  feesDataNextDayEcom,
  feesDataNextDayPos,
  feesDataSettlementPayments,
  feesDataSettlementType,
  nextDayDataEcom,
  nextDayDataPos,
  settlementFeesColumns,
  settlementFeesDataEcom,
  settlementFeesDataPos,
  settlementPaymentColumns
} from './services/generator'
import translations from './translations'
import { SettlementsFormPropsType } from './props'
import styles from './styles.scss'

export const SettlementsForm: React.FC<SettlementsFormPropsType> = props => {
  const { initialValues, store } = props

  const [mergedInitialValues, setMergedInitialValues] = useState(store.mergeInitialValuesSettlements(initialValues))

  const [formSettlements] = Form.useForm()

  useEffect(() => {
    store.onInitialValuesChangedFees(initialValues, '', setMergedInitialValues, formSettlements)

    setTimeout(() => {
      formSettlements.setFieldsValue(store.mergeInitialValuesSettlements(initialValues))
      store.settlementFormLoadSettings(formSettlements)
    }, 1000)
  }, [initialValues])

  useEffect(() => {
    store.loadTariffs()
    !initialValues &&
      formSettlements.setFieldsValue({
        perCompanyUberId: false,
        perOutletMid: true,
        dailyNetSettlements: true,
        monthlyNetSettlements: false,
        grossSettlementsByMid: false,
        grossSettlementsByUberId: false,
        ecom: { nextDayFundingRequested: false },
        pos: { nextDayFundingRequested: false }
      })

    store.settlementFormLoadSettings(formSettlements)
  }, [])

  return (
    <div className={styles.feesFormMSA}>
      <Form
        form={formSettlements}
        initialValues={mergedInitialValues}
        labelAlign='left'
        onValuesChange={(changedValues, _) => {
          store.handleSettlementsFormChange(changedValues, formSettlements)
        }}
        scrollToFirstError
      >
        <div className={styles.styledTitle}>{translations().title}</div>
        <div className={styles.tableHeader}>{translations().settlementPayment}</div>
        <div className={styles.tableSubHeader}>
          <div>{translations().settlementPaymentNote(store.dataStore.isAdditionalStoreProcess)}</div>
        </div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={settlementPaymentColumns}
          dataSource={feesDataSettlementPayments(
            store.perCompanyUberIdAllowed,
            store.dataStore.isAdditionalStoreProcess
          )}
        />
        <div className={styles.tableHeader}>{translations().settlementType}</div>
        <div className={styles.tableSubHeader}>{translations().settlementTypeNote}</div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={settlementPaymentColumns}
          dataSource={feesDataSettlementType(
            store.grossSettlementsByUberIdAllowed,
            store.isOnlyEcom,
            store.dataStore.isAdditionalStoreProcess
          )}
        />

        {store.dataStore.productTypes.find(p => p === 'pos') && (
          <>
            <div className={classNames(styles.tableHeader, styles.redColor)}>{translations().posNextDay}</div>
            <div className={styles.tableSubHeader}>{translations().posNextDayNote}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={settlementPaymentColumns}
              dataSource={feesDataNextDayPos()}
            />
            <Form.Item shouldUpdate>
              {t =>
                t.getFieldValue(['pos', 'nextDayFundingRequested']) && (
                  <Table
                    showHeader={false}
                    bordered
                    pagination={false}
                    columns={settlementFeesColumns}
                    dataSource={nextDayDataPos(store.tariffsList)}
                  />
                )
              }
            </Form.Item>

            <div className={classNames(styles.tableHeader, styles.redColor)}>{translations().posStandarPeriod}</div>
            <div className={styles.tableSubHeader}>{translations().posStandardPeriodNote}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={settlementFeesColumns}
              dataSource={settlementFeesDataPos(store.tariffsList)}
            />
          </>
        )}

        {store.dataStore.productTypes.find(p => p === 'ecom') && (
          <>
            <div className={classNames(styles.tableHeader, styles.blueColor)}>{translations().ecomNextDay}</div>
            <div className={styles.tableSubHeader}>{translations().posNextDayNote}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={settlementPaymentColumns}
              dataSource={feesDataNextDayEcom()}
            />
            <Form.Item shouldUpdate>
              {t =>
                t.getFieldValue(['ecom', 'nextDayFundingRequested']) && (
                  <Table
                    showHeader={false}
                    bordered
                    pagination={false}
                    columns={settlementFeesColumns}
                    dataSource={nextDayDataEcom(store.tariffsList)}
                  />
                )
              }
            </Form.Item>
            <div className={classNames(styles.tableHeader, styles.blueColor)}>{translations().ecomStandardPeriod}</div>
            <div className={styles.tableSubHeader}>{translations().posStandardPeriodNote}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={settlementFeesColumns}
              dataSource={settlementFeesDataEcom(store.tariffsList)}
            />
          </>
        )}

        <div className={styles.btnsContainer}>
          <Row gutter={10}>
            <Col>
              <CustomButton
                type='primary'
                loading={store.dataStore.isSaveLoading}
                onClick={async () => {
                  await formSettlements.validateFields()
                  await store.submitSettlements(formSettlements)
                }}
              >
                {translations().save}
              </CustomButton>
            </Col>

            <Col>
              <>
                <CustomButton
                  loading={store.dataStore.isSaveLoading}
                  onClick={async () => {
                    await formSettlements.validateFields()
                    await store.submitSettlements(formSettlements, true)
                  }}
                >
                  {translations().saveForLater}
                </CustomButton>
              </>
            </Col>
            <Col>
              <CustomButton onClick={() => store.setShowSettlementTerms(false)}>{translations().cancel}</CustomButton>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}
