import { Checkbox, Divider, Form, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './styles.scss'
import { FeesFormMSAProps } from './models/FeesFormMSAProps'
import {
  authColumns,
  authFeesData,
  authFeesDataV2,
  childrenColumnsWithoutDisable,
  commonFeesDataFiltered,
  feesColumnsGateway,
  feesColumnsOther,
  feesColumnsPayments,
  feesColumnsRegular,
  feesDataGateway,
  feesDataOther,
  feesDataPayment,
  feesDataRegular,
  feesDataRegularV2,
  ffColumns,
  ffData,
  nonTransationFeesColumns,
  nonTransationFeesData,
  settlementFeesColumns,
  settlementFeesDataFiltered,
  terminalPurchaseColumns,
  terminalPurchaseData,
  zashColumns,
  zashData
} from './services/generator'
import translations from './translations'
import { PRODUCT_VERSIONS } from '~/code/pages/StartProcess/services/constants'

export const FeesFormMSA: React.FC<FeesFormMSAProps> = props => {
  const { form, initialValues, product, store, tarriffType } = props

  const [mergedInitialValues, setMergedInitialValues] = useState(store.mergeInitialValuesFees(initialValues, product))

  useEffect(() => {
    store.onInitialValuesChangedFees(initialValues, product, setMergedInitialValues, form)

    form.setFieldsValue(store.mergeInitialValuesFees(initialValues, product))
  }, [initialValues, tarriffType])

  return (
    <div className={styles.feesFormMSA}>
      <Form
        form={form}
        initialValues={mergedInitialValues}
        labelAlign='left'
        onValuesChange={() => store.checkFormFilled(form)}
        scrollToFirstError
      >
        <div className={styles.tableHeader}>{translations().transactionFees}</div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={childrenColumnsWithoutDisable}
          dataSource={commonFeesDataFiltered(
            product,
            store.tariffsList,
            store.isAliPay,
            store.isWeChatPay,
            store.isPartnerWithoutLimits
          )}
        />

        <div className={styles.tableHeader}>{translations().authFee}</div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={authColumns}
          dataSource={
            store.dataStore.productVersion === PRODUCT_VERSIONS.REDESIGNED
              ? authFeesDataV2(store.tariffsList, store.tarrifTypes[product], store.isPartnerWithoutLimits)
              : authFeesData(store.tariffsList, store.isPartnerWithoutLimits)
          }
        />

        <div className={styles.tableHeader}>{translations().nonTransactionFees}</div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={nonTransationFeesColumns}
          dataSource={nonTransationFeesData(store.tariffsList, store.isPartnerWithoutLimits)}
        />

        {product === 'pos' && store.isZashEpos && (
          <>
            <div className={styles.tableHeader}>{translations().zashTitle}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={zashColumns}
              dataSource={zashData(store.tariffsList, store.isPartnerWithoutLimits)}
            />
          </>
        )}

        {product === 'pos' && (
          <>
            <div className={styles.tableHeader}>{translations().paymentFacilitatingServices}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={feesColumnsPayments}
              dataSource={feesDataPayment(store.tariffsList, store.isPartnerWithoutLimits)}
            />
          </>
        )}
        {product === 'pos' && store.isTerminalPurchase && (
          <>
            <div className={styles.tableHeader}>{translations().terminalPurchase}</div>
            <div className={styles.tableSubHeader}>{translations().terminalPurchaseNote}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={terminalPurchaseColumns}
              dataSource={terminalPurchaseData}
            />
          </>
        )}
        {product === 'pos' && store.isChargeable && (
          <>
            <div className={styles.tableHeader}>{translations().regularCharges}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={feesColumnsRegular}
              dataSource={
                store.dataStore.productVersion === PRODUCT_VERSIONS.REDESIGNED
                  ? feesDataRegularV2(store.tariffsList, store.isPartnerWithoutLimits)
                  : feesDataRegular(store.tariffsList, store.isPartnerWithoutLimits)
              }
            />
          </>
        )}
        {product === 'ecom' && (
          <>
            <div className={styles.tableHeader}>{translations().gatewayServices}</div>
            <Table
              showHeader={false}
              bordered
              pagination={false}
              columns={feesColumnsGateway}
              dataSource={feesDataGateway(store.tariffsList, store.isPartnerWithoutLimits)}
            />
          </>
        )}

        <div className={styles.tableHeader}>{translations().other}</div>
        <Table
          showHeader={false}
          bordered
          pagination={false}
          columns={feesColumnsOther}
          dataSource={feesDataOther(store.tariffsList, store.isPartnerWithoutLimits)}
        />
      </Form>
    </div>
  )
}
