import React from 'react'
import { Form, Row, Col, Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { DNAFeesValueType } from '../../models'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import { getDNATariffName } from '../../../EditTariffsConfirmTable/services'
import { tariffComparisonTableDataType } from '../../../EditTariffsConfirmTable/models'
import translations from '../../translations'
import styles from '../TariffComparisonTable.scss'

const newTariffsHeader = () => {
  return {
    className: styles.pinkHeader
  }
}

const currentTariffsHeader = () => {
  return {
    className: styles.blueHeader
  }
}

export const BlendedTableColumns = (dnaComissions: DNAFeesValueType): ColumnsType<tariffComparisonTableDataType> => {
  return [
    {
      title: () => {
        return (
          <Row gutter={16}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item id='dnapConsumerDebit' label={translations().dnapConsumerDebit}>
                <Input value={dnaComissions?.dnapConsumerDebit?.percent} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item id='dnapConsumerCredit' label={translations().dnapConsumerCredit}>
                <Input value={dnaComissions?.dnapConsumerCredit?.percent} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item id='dnapCorporateDebit' label={translations().dnapCorporateDebit}>
                <Input value={dnaComissions?.dnapCorporateDebit?.percent} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item id='dnapCorporateCredit' label={translations().dnapCorporateCredit}>
                <Input value={dnaComissions?.dnapCorporateCredit?.percent} disabled />
              </Form.Item>
            </Col>
          </Row>
        )
      },
      onHeaderCell: currentTariffsHeader,
      children: [
        {
          title: translations().transactionType,
          children: [
            {
              key: 'cardScheme',
              dataIndex: 'cardScheme',
              title: translations().cardScheme
            },
            {
              key: 'serviceArea',
              dataIndex: 'serviceArea',
              title: translations().serviceArea,
              width: 200
            },
            {
              key: 'cardType',
              dataIndex: 'cardType',
              title: translations().cardType,
              width: 130
            },
            {
              key: 'corporateOrConsumer',
              dataIndex: 'corporateOrConsumer',
              title: translations().corporateOrConsumer,
              width: 130
            }
          ]
        },
        {
          title: translations().interchangeFee,
          onHeaderCell: currentTariffsHeader,
          children: [
            {
              key: 'interchangeFee',
              dataIndex: 'interchangeFee',
              title: translations().percentFee,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => row?.interchangeFee?.percentFee
            }
          ]
        },
        {
          title: translations().schemeFee,
          onHeaderCell: currentTariffsHeader,
          children: [
            {
              key: 'schemeBaseFee',
              dataIndex: 'schemeFee',
              title: translations().baseFee,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => row?.schemeFee?.baseFee
            },
            {
              key: 'schemePercentFee',
              dataIndex: 'schemeFee',
              title: translations().percentFee,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => row?.schemeFee?.percentFee
            }
          ]
        },
        {
          title: () => <div>{translations().dnaStandardFee}</div>,
          onHeaderCell: currentTariffsHeader,
          children: [
            {
              title: translations().headlineRate,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => row?.dnaFee
            }
          ]
        },
        {
          title: translations().totalFee,
          onHeaderCell: currentTariffsHeader,
          children: [
            {
              key: 'totalBaseFee',
              dataIndex: 'totalBaseFee',
              title: translations().baseFee,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => <strong>{row?.schemeFee?.baseFee}</strong>
            },
            {
              key: 'totalPercentFee',
              dataIndex: 'totalPercentFee',
              title: translations().percentFee,
              onHeaderCell: currentTariffsHeader,
              render: (_, row) => {
                return (
                  <Form.Item shouldUpdate noStyle>
                    {form => {
                      const interchangeFee = Number(row?.interchangeFee?.percentFee) || 0
                      const schemeFee = Number(row?.schemeFee?.percentFee || 0)
                      const dnaFee = Number(
                        form.getFieldValue([getDNATariffName(row?.corporateOrConsumer, row?.cardType), 'percent']) || 0
                      )
                      return <strong>{(interchangeFee + schemeFee + dnaFee).toFixed(3)}</strong>
                    }}
                  </Form.Item>
                )
              }
            }
          ]
        }
      ]
    },
    {
      title: '',
      onHeaderCell: newTariffsHeader,
      children: [
        {
          title: translations().blended,
          key: 'blendedColumn',
          onHeaderCell: newTariffsHeader,
          children: [
            {
              key: 'blendedBaseFee',
              onHeaderCell: newTariffsHeader,
              dataIndex: 'blended',
              title: translations().baseFee,
              render: (_, row) => row?.blended?.baseFee
            },
            {
              key: 'blendedPercentFee',
              onHeaderCell: newTariffsHeader,
              dataIndex: 'blended',
              title: translations().percentFee,
              render: (_, row) => row?.blended?.percentFee
            }
          ]
        }
      ]
    }
  ]
}
export const ICPlusTableColumns: ColumnsType<tariffComparisonTableDataType> = [
  {
    title: '',
    onHeaderCell: currentTariffsHeader,
    children: [
      {
        title: translations().transactionType,
        children: [
          {
            key: 'cardScheme',
            dataIndex: 'cardScheme',
            title: translations().cardScheme
          },
          {
            key: 'serviceArea',
            dataIndex: 'serviceArea',
            title: translations().serviceArea,
            width: 200
          },
          {
            key: 'cardType',
            dataIndex: 'cardType',
            title: translations().cardType,
            width: 130
          },
          {
            key: 'corporateOrConsumer',
            dataIndex: 'corporateOrConsumer',
            title: translations().corporateOrConsumer,
            width: 130
          }
        ]
      },
      {
        title: translations().blended,
        onHeaderCell: currentTariffsHeader,
        key: 'blendedColumn',
        children: [
          {
            key: 'blendedBaseFee',
            onHeaderCell: currentTariffsHeader,
            dataIndex: 'blended',
            title: translations().baseFee,
            render: (_, row) => row?.blended?.baseFee
          },
          {
            key: 'blendedPercentFee',
            onHeaderCell: currentTariffsHeader,
            dataIndex: 'blended',
            title: translations().percentFee,
            render: (_, row) => row?.blended?.percentFee
          }
        ]
      }
    ]
  },
  {
    title: (
      <Row gutter={16}>
        <Col xs={24} md={12} lg={6}>
          <NumberFormItem
            min={0}
            name={['dnapConsumerDebit', 'percent']}
            label={translations().dnapConsumerDebitShort}
            labelCol={{ span: 24 }}
            decimalLength={4}
            disabled
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <NumberFormItem
            min={0}
            name={['dnapConsumerCredit', 'percent']}
            label={translations().dnapConsumerCreditShort}
            labelCol={{ span: 24 }}
            decimalLength={4}
            disabled
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <NumberFormItem
            min={0}
            name={['dnapCorporateDebit', 'percent']}
            label={translations().dnapCorporateDebitShort}
            labelCol={{ span: 24 }}
            decimalLength={4}
            disabled
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <NumberFormItem
            min={0}
            name={['dnapCorporateCredit', 'percent']}
            label={translations().dnapCorporateCreditShort}
            labelCol={{ span: 24 }}
            decimalLength={4}
            disabled
          />
        </Col>
      </Row>
    ),
    onHeaderCell: newTariffsHeader,
    children: [
      {
        title: translations().interchangeFee,
        onHeaderCell: newTariffsHeader,
        children: [
          {
            key: 'interchangeFee',
            onHeaderCell: newTariffsHeader,
            dataIndex: 'interchangeFee',
            title: translations().percentFee,
            render: (_, row) => row?.interchangeFee?.percentFee
          }
        ]
      },
      {
        title: translations().schemeFee,
        onHeaderCell: newTariffsHeader,
        children: [
          {
            key: 'schemeBaseFee',
            dataIndex: 'schemeFee',
            title: translations().baseFee,
            onHeaderCell: newTariffsHeader,
            render: (_, row) => row?.schemeFee?.baseFee
          },
          {
            key: 'schemePercentFee',
            dataIndex: 'schemeFee',
            title: translations().percentFee,
            onHeaderCell: newTariffsHeader,
            render: (_, row) => row?.schemeFee?.percentFee
          }
        ]
      },
      {
        title: () => <div>{translations().dnaStandardFee}</div>,
        onHeaderCell: newTariffsHeader,
        children: [
          {
            title: translations().headlineRate,
            onHeaderCell: newTariffsHeader,
            render: (_, row) => renderCell([getDNATariffName(row?.corporateOrConsumer, row?.cardType), 'percent'])
          }
        ]
      },
      {
        title: translations().totalFee,
        onHeaderCell: newTariffsHeader,
        children: [
          {
            key: 'totalBaseFee',
            dataIndex: 'totalBaseFee',
            title: translations().baseFee,
            onHeaderCell: newTariffsHeader,
            render: (_, row) => <strong>{row?.schemeFee?.baseFee}</strong>
          },
          {
            key: 'totalPercentFee',
            dataIndex: 'totalPercentFee',
            title: translations().percentFee,
            onHeaderCell: newTariffsHeader,
            render: (_, row) => {
              return (
                <Form.Item shouldUpdate noStyle>
                  {form => {
                    const interchangeFee = Number(row?.interchangeFee?.percentFee) || 0
                    const schemeFee = Number(row?.schemeFee?.percentFee || 0)
                    const dnaFee = Number(
                      form.getFieldValue([getDNATariffName(row?.corporateOrConsumer, row?.cardType), 'percent']) || 0
                    )
                    return <strong>{(interchangeFee + schemeFee + dnaFee).toFixed(3)}</strong>
                  }}
                </Form.Item>
              )
            }
          }
        ]
      }
    ]
  }
]

const ReadOnly: React.FC<{ value?: string }> = props => <span>{props.value}</span>

const renderCell = (name: string[]) => {
  return (
    <Form.Item name={name} noStyle>
      <ReadOnly />
    </Form.Item>
  )
}
