import React, { useEffect, useState } from 'react'
import { Form, Typography, Table, Input, Button } from 'antd'
import translations from '../../components/EditTariffsTable/translations'
import { confirmationTableColumns, generateInput } from '../EditTariffsTable/services/generator'
import { TariffComparisonTable } from '../EditTariffsTable/TariffComparisonTable'
import { descriptionPostfix } from '../EditTariffsTable/constants'
import { generateTariffsComparisonData, generateNotTransactionalTariffsData, defineTariffUpdateType } from './services'
import { nonTransactionalTariffsType, tariffComparisonTableDataType } from './models'
import { EditTariffsComfirmTableProps } from './props'
import styles from './EditTariffsComfirmTable.scss'

const { TextArea } = Input
export const EditTariffsComfirmTable = (props: EditTariffsComfirmTableProps) => {
  const {
    formConfirmation,
    store,
    finalValuesList,
    fieldsValue,
    formFees,
    newTariffType,
    initTariffType,
    terminalList,
    terminalTariffsList,
    tabSettings
  } = props

  const [tableData, setTableData] = useState<tariffComparisonTableDataType[]>([])
  const [nonTransactionalTariffsData, setNonTransactionalTariffsData] = useState<nonTransactionalTariffsType>({})

  useEffect(() => {
    const isTariffTypeUpdate = initTariffType !== newTariffType
    if (isTariffTypeUpdate)
      setTableData(generateTariffsComparisonData(store.tariffSettings, terminalTariffsList, formFees))

    setNonTransactionalTariffsData(
      generateNotTransactionalTariffsData(tabSettings, finalValuesList, isTariffTypeUpdate)
    )
  }, [])

  return (
    <>
      <Typography.Title level={4}>{translations().confirmationMessage}</Typography.Title>
      <br />
      <Typography.Title level={5}>{store.selectedMidStoreInfo}</Typography.Title>
      <br />
      {initTariffType !== newTariffType && (
        <TariffComparisonTable
          tariffType={newTariffType}
          initTariffType={initTariffType}
          data={tableData}
          form={formFees}
        />
      )}
      {
        <Form form={formConfirmation} labelAlign='left'>
          {Object.entries(nonTransactionalTariffsData)?.map(([key, value]) => (
            <div key={key}>
              <div className={styles.tableHeader}>{value?.groupName}</div>
              <Table
                bordered
                pagination={false}
                dataSource={value?.params?.map(valueItem => {
                  const [[key, value]] = Object.entries(valueItem)
                  return {
                    key: key,
                    current: generateInput(key, fieldsValue[key]),
                    new: generateInput(key, value),
                    name: translations()[key],
                    description: translations()[`${key}${descriptionPostfix}`]
                  }
                })}
                columns={confirmationTableColumns}
              />
            </div>
          ))}
          <div className={styles.commentText}>
            <Form.Item key={'comment'} name={'comment'}>
              <TextArea placeholder={translations().comment} />
            </Form.Item>
          </div>
          <div className={styles.submitBtn}>
            <Button
              type='primary'
              loading={store.isEditTariffsIsLoading}
              onClick={() => {
                store.startEditTerminalTariffs(
                  fieldsValue,
                  formFees,
                  terminalList.join(','),
                  formConfirmation,
                  newTariffType,
                  initTariffType,
                  newTariffType !== initTariffType,
                  defineTariffUpdateType(newTariffType, initTariffType),
                  tableData
                )
              }}
            >
              {translations().save}
            </Button>
          </div>
        </Form>
      }
    </>
  )
}
