import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Empty, Skeleton, Typography, Checkbox } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { Info } from './../'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { MerchantsManagementStoreSymbol } from '../..'
import translations from './translations'
import { StartChangeRiskLimits } from './components/StartChangeRiskLimits/StartChangeRiskLimits'
import { ChangeButton } from './components/ChangeButton'
import { IRiskLimitsStore } from './IRiskLimitsStore'
import styles from './RiskLimits.scss'

const { Title } = Typography

export const RiskLimitsStoreSymbol = Symbol('RiskLimitsStore')
export const RiskLimits = observer(() => {
  const store = useInjection<IRiskLimitsStore>(RiskLimitsStoreSymbol)
  const { isFullDossierV2Loading, selectedMerchant, companyFullDossierV2LoadError, riskLimits, isMerchantActive } =
    useInjection<MerchantsManagementStore>(MerchantsManagementStoreSymbol)

  useEffect(() => {
    if (!isFullDossierV2Loading) store.setAllTerminalsSelected()
  }, [isFullDossierV2Loading])

  const renderChangeButton = () => {
    if (!hasPermissions([PermissionMap.merchants.update]) || store.allTerminalIds.length === 0 || !isMerchantActive) {
      return null
    }

    return (
      store.isCompanyFullDossierV2Exists && (
        <div className={styles.changeButtonContainer}>
          <ChangeButton />
        </div>
      )
    )
  }

  const renderInfoValue = val =>
    ['dailyAmount', 'dailyMaxRefundAmount', 'monthlyAmount', 'perTransaction', 'perRefund', 'perDevice'].includes(
      val[0]
    )
      ? val[1]?.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })
      : ''

  const renderInfoCardBlock = () => {
    return isFullDossierV2Loading ? (
      <Skeleton />
    ) : (
      <>
        <Title level={3}>{translations().currentRiskLimits}</Title>
        {store.allTerminalIds.length === 0 && <div className={styles.errMsg}>{translations().noActiveTerminals}</div>}
        <Checkbox.Group
          options={store.allTerminalIds.map(t => ({ label: t, value: t }))}
          value={store.terminalIds?.filter(id => store.allTerminalIds?.includes(id))}
          onChange={(value: string[]) => {
            const validValues = value?.filter(id => store.allTerminalIds?.includes(id))
            store.setTerminalIds(validValues)
          }}
        />
        {!!companyFullDossierV2LoadError ? (
          <Empty />
        ) : (
          <Row>
            {Object.entries(riskLimits).map((limit, idx) => {
              return (
                <Col key={idx} xs={{ span: 24 }} sm={{ span: 6, order: idx }}>
                  <Info label={translations()[limit[0]]} value={renderInfoValue(limit)} />
                </Col>
              )
            })}
          </Row>
        )}
        {Object.keys(riskLimits).length > 0 ? renderChangeButton() : null}
      </>
    )
  }

  const renderChangeModalBlock = () => {
    return <StartChangeRiskLimits key={selectedMerchant?.id} />
  }

  return (
    <div>
      {renderInfoCardBlock()}
      {renderChangeModalBlock()}
    </div>
  )
})
