import React, { useMemo } from 'react'
import { Button, Card, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { FilterSelect, MerchantSelect, RangePicker } from '~/code/components'
import { isPartner } from '~/code/services/auth'
import { StoreAndTerminalFilter } from '~/code/components/StoreAndTerminalFilter'
import { ISettlementsFilterStore } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsFilters/ISettlementsFilterStore'
import { disableDate, isEndDateWithinOneYearRange, calculateYearBoundaryDate } from '../../services'
import { getPeriods } from './services'
import { DownloadStatementButtons, DownloadStatementPdfModal, DownloadStatementLoadingModal } from './components'
import translations from './translations'
import styles from './SettlementsFilters.scss'
export const SettlementsFiltersStoreSymbol = Symbol('SettlementsFiltersStore')
export const SettlementsTestFiltersStoreSymbol = Symbol('SettlementsTestFiltersStore')

export const SettlementsFilters = observer(
  (props: {
    isLoading: boolean
    onLoad(): Promise<void>
    filtersStoreSymbol: symbol
    merchantSelectStoreSymbol: symbol
    isDownloadButtonVisible: boolean
  }) => {
    const store = useInjection<ISettlementsFilterStore>(props.filtersStoreSymbol)
    const {
      storeAndTerminalFilterStore,
      merchantSelectStore,
      isPdfSatementModalOpen,
      setIsPdfSatementModalOpen,
      downloadPdfStatement
    } = store

    const noChannelNorMerchantSelected = () => {
      const isMerchantNotSelected = !merchantSelectStore.merchant?.id || merchantSelectStore.merchant?.id === 'all'
      if (isPartner()) {
        return isMerchantNotSelected
      }
      return merchantSelectStore.acquisitionChannel?.id === 'all' && isMerchantNotSelected
    }

    const noDatesSelected = () => {
      return !store.startDate || !store.endDate
    }

    const downloadButtonTooltip = () => {
      if (noDatesSelected()) {
        return translations().selectDatesFirst
      } else if (noChannelNorMerchantSelected()) {
        if (isPartner()) {
          return translations().selectMerchantFirst
        } else {
          return translations().selectMerchantOrAcquisitionChannelFirst
        }
      }
      return null
    }

    const drawAccountNumberSelection = useMemo(
      () => !isPartner() && store.accountNumberList?.length > 0,
      [store.accountNumberList]
    )
    const disableRangePicker = useMemo(
      () => noChannelNorMerchantSelected(),
      [merchantSelectStore.acquisitionChannel?.id, merchantSelectStore.merchant?.tradeName]
    )
    const disableDownload = useMemo(
      () => noChannelNorMerchantSelected() || noDatesSelected(),
      [merchantSelectStore.acquisitionChannel, merchantSelectStore.merchant?.tradeName, store.startDate, store.endDate]
    )
    const downloadButtonTooltipMessage = useMemo(
      () => downloadButtonTooltip(),
      [
        merchantSelectStore.acquisitionChannel?.id,
        merchantSelectStore.merchant?.tradeName,
        store.startDate,
        store.endDate
      ]
    )

    return (
      <Card className={styles.Settlements}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className={styles.SettlementsFilters}>
              <MerchantSelect storeSymbol={props.merchantSelectStoreSymbol} />
              <RangePicker
                startDate={store.startDate}
                endDate={store.endDate}
                period={store.period}
                allowClear={false}
                onSetPeriod={store.setPeriod}
                onSetDates={store.setDates}
                periods={getPeriods(disableRangePicker)}
                disabledDate={disableDate}
                disabled={disableRangePicker}
                tooltipTitle={
                  disableRangePicker
                    ? isPartner()
                      ? translations().selectMerchantFirst
                      : translations().selectMerchantOrAcquisitionChannelFirst
                    : null
                }
                includeHoursAndMins={false}
                onCalendarChange={(dates, rangeType) => {
                  if (rangeType === 'start' && !isEndDateWithinOneYearRange(dates)) {
                      return [dates[0], calculateYearBoundaryDate(dates[0], 'end')]
                  }
                  return dates
              }}

              />
            </div>
          </Col>
          {drawAccountNumberSelection && (
            <Col xs={24}>
              <FilterSelect
                formItemProps={{ label: translations().accountNumber }}
                selectProps={{
                  className: styles.accountNumberSelect,
                  loading: store.isAccountNumberListLoading,
                  value: store.accountNumber,
                  onChange: store.setAccountNumber,
                  options: store.accountNumberList
                }}
              />
            </Col>
          )}
          <Col xs={24} xl={21}>
            <StoreAndTerminalFilter store={storeAndTerminalFilterStore} />
          </Col>
          <Col xs={24} xl={3}>
            <div className={styles.downloadStatementButtonContainer}>
              {props.isDownloadButtonVisible && (
                <DownloadStatementButtons
                  merchantId={merchantSelectStore.merchant?.id}
                  downloadStatement={store.downloadStatement}
                  isDownloading={store.isDownloadingStatement}
                  disabled={disableDownload}
                />
              )}
              <Button disabled={disableDownload} onClick={props.onLoad} loading={props.isLoading}>
                {translations().viewStatement}
              </Button>
            </div>
          </Col>
        </Row>

        {props.isDownloadButtonVisible && <div className={styles.warnText}>{translations().warnText}</div>}

        <DownloadStatementPdfModal
          isOpen={isPdfSatementModalOpen}
          onCancel={() => setIsPdfSatementModalOpen(false)}
          onSubmit={async data => {
            setIsPdfSatementModalOpen(false)

            await downloadPdfStatement(data)
          }}
        />

        <DownloadStatementLoadingModal isOpen={store.isPdfSatementLoading} />
      </Card>
    )
  }
)
