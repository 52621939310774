import translations from './translations'

translations.add('en', {
  profile: 'Profile',
  info: 'Info',
  accounts: 'Accounts',
  terminals: 'Request MOTO/Virtual Terminal',
  riskLimits: 'Current Risk Limits',
  closureOfMerchant: 'Closure of Merchant',
  closureOfTerminal: 'Closure of Terminal',
  changeOfSettlementPeriod: 'Change of Settlement Period',
  terminalIssue: 'Additional Terminal Issue',
  documentsRequired: 'Documents required',
  additionalStore: 'Additional Store Issue',
  changeSettlementType: 'Change Settlement Type',
  holdOrReleaseSettlements: 'Hold/Release Settlements'
})
