import translations from './translations'
import React from 'react'
import { Typography } from 'antd'

const { Link } = Typography

translations.add('en', {
  start: 'Start',
  generalInfo: 'General Information',
  bankDetailsVerif: 'Bank details',
  storesProducts: 'Outlets and Products',
  financialInfo: 'Financial Information',
  merchantBankAccount: 'Merchant Bank Account - For Charges (Money Out)',
  shareholders: 'Shareholders',
  directors: 'Directors',
  newDirector: 'New Director',
  signatories: 'Authorised Signatories',
  newSignatory: 'New Signatory',
  businessOwner: 'Business Owner',
  optional: 'Optional',
  completed: 'Completed',
  notCompleted: 'Not Completed',
  pricing: 'Pricing',
  contract: 'Contract',
  sign: 'Sign',
  docuSign: 'DocuSign',
  docuSignInfo: (
    <>
      <p>Click Preview to form the draft of the contract MSA. </p>
      <p>A pdf document will be downloaded, </p>
      <p>check it and assure the data is correct,</p>
      <p> then click Sign to send it for merchant signature in DocuSign</p>
    </>
  ),
  signError: 'Something went wrong on backend side',
  preview: 'Preview',
  alreadySentToSign: 'Document already sent to sign',
  update: 'Update',
  updateError: 'Something went wrong while update',
  title: 'Documents',
  submit: 'Submit',
  back: 'Back',
  individialDetails: 'Individual details',
  companyName: 'Company name: ',
  pendingSignature: 'Pending signature',
  docusignStatus: (status: string) => {
    if (status === 'wait') {
      return 'Sent, pending signature'
    } else if (status === 'success') {
      return 'Successfully signed'
    } else {
      return 'Ready for sending'
    }
  },
  beta: 'Beta V2.1. Work in progress.',
  contactEmail: (
    <>
      If you`ve spotted something that works wrong, please contact
      <Link href='mailto:processes@dnapayments.com'> processes@dnapayments.com</Link>
    </>
  ),
  closeProcess: 'Closing the application',
  closeProcessDescription:
    'Are you sure you want to close this Application? Please make sure you`ve saved the current progress. Once closed it can be found in the "Incomplete" applications section. ',
  manualMSA: 'Will be uploaded manually',
  settlements: 'Settlements terms'
})
