import { FormInstance } from 'antd'
import { cloneObject } from 'dna-common'
import {
  BlendedTariffEditTabTableType,
  TerminalTariffType,
  CorporateOrConsumerType,
  CardType as CardTypeEnum
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { TariffGroupedSettingsType } from '../../../../Contract/models'
import { TariffTypeEnum, TariffTypeChangeEnum, TariffGroupTypeEnum } from '../../../constants'
import { defaultSchemeFeesList, dnaFeeList } from '../constants/constants'
import { nonTransactionalTariffsType, tariffComparisonTableDataType } from '../models'

export const generateTariffsComparisonData = (
  tariffSettings: TariffGroupedSettingsType[],
  terminalTariffsList: BlendedTariffEditTabTableType[],
  formFees: FormInstance
): tariffComparisonTableDataType[] => {
  const tariffSettingsClone = cloneObject(tariffSettings)
  const terminalSettings = tariffSettingsClone
    ?.find(({ key }) => key === TariffGroupTypeEnum.changeFeesICPlus)
    ?.groups?.find(({ group }) => group.code === 'termianlTariffs')?.tariffSettings

  const formValues = formFees.getFieldsValue()

  const comparisonData: tariffComparisonTableDataType[] =
    terminalSettings?.map(data => {
      const blended = terminalTariffsList.find(
        ({ interchangeFee }) => interchangeFee.key === data.interchangeFee.key
      )?.interchangeFee

      if (formValues[blended?.key]) {
        const base = formValues[blended?.key]?.base
        const percent = formValues[blended?.key]?.percent
        blended.baseFee = Number(base || 0)
        blended.percentFee = Number(percent || 0)
      }

      const dnaName = getDNATariffName(data?.corporateOrConsumer, data?.cardType)
      const dnaValues = formFees.getFieldsValue(dnaFeeList)

      const blendedData = blended ? { blended } : {}
      const dnaFeeData = dnaName ? { dnaFee: dnaValues?.[dnaName]?.percent } : {}

      return {
        ...data,
        ...blendedData,
        ...dnaFeeData
      } as tariffComparisonTableDataType
    }) || []

  return comparisonData
}

export const generateNotTransactionalTariffsData = (
  tabSettings: TariffGroupedSettingsType[],
  finalValuesList: TerminalTariffType,
  isTariffTypeUpdate: boolean
): nonTransactionalTariffsType => {
  const notTransactionalTariffs = {}

  let currentTabSettings = tabSettings?.[0]?.groups || []
  if (isTariffTypeUpdate)
    currentTabSettings = currentTabSettings.filter(
      setting => setting.group.code !== 'termianlTariffs' && setting.group.code !== 'dnaFee'
    )
  currentTabSettings.forEach(tabSetting => {
    tabSetting.tariffSettings.forEach(tariffSetting => {
      const finalValue = finalValuesList[tariffSetting.interchangeFee.key]
      if (finalValue) {
        if (notTransactionalTariffs[tariffSetting.group.code]) {
          notTransactionalTariffs[tariffSetting.group.code].params.push({
            [tariffSetting.interchangeFee.key]: finalValue
          })
        } else {
          notTransactionalTariffs[tariffSetting.group.code] = {
            groupName: tariffSetting.group.value,
            params: [{ [tariffSetting.interchangeFee.key]: finalValue }]
          }
        }
      }
    })
  })

  return notTransactionalTariffs
}

export const defineTariffUpdateType = (
  newTariffType: TariffTypeEnum,
  initTariffType: TariffTypeEnum
): TariffTypeChangeEnum => {
  if (newTariffType && initTariffType && newTariffType !== initTariffType) {
    if (initTariffType == TariffTypeEnum.icPlus && newTariffType == TariffTypeEnum.blended) {
      return TariffTypeChangeEnum.icPlusToBlended
    }
    if (initTariffType == TariffTypeEnum.blended && newTariffType == TariffTypeEnum.icPlus) {
      return TariffTypeChangeEnum.blendedToICPlus
    }
  }
  return null
}

export const getDNATariffName = (
  corporateOrConsumer: CorporateOrConsumerType.corporate | CorporateOrConsumerType.consumer,
  cardType: string
) => {
  if (corporateOrConsumer === CorporateOrConsumerType.consumer) {
    if (cardType === CardTypeEnum.debit) {
      return 'dnapConsumerDebit'
    }
    if (cardType === CardTypeEnum.credit) {
      return 'dnapConsumerCredit'
    }
  }

  if (corporateOrConsumer === CorporateOrConsumerType.corporate) {
    if (cardType === CardTypeEnum.debit) {
      return 'dnapCorporateDebit'
    }
    if (cardType === CardTypeEnum.credit) {
      return 'dnapCorporateCredit'
    }
  }

  return ''
}

export const icPlusToBlendedDefaultValues = {
  ...defaultSchemeFeesList.reduce((acc, fee) => {
    acc[fee] = { base: 0, percent: 0 }
    return acc
  }, {}),
  ...dnaFeeList.reduce((acc, fee) => {
    acc[fee] = { percent: 0 }
    return acc
  }, {})
}
