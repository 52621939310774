import { Form, Col, Row, Select, Collapse } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { FormSubgroupTitle } from '..'
import { StoreInfoFormProps } from './props'
import { isCountryUK, validatePostalCodeOfUK } from 'startapp/services'
import { Input, PostalCode } from '~/code/components'
import translations from './translations'
import styles from './StoreInfoForm.scss'
import { CITY_TYPING_PATTERN } from '~/code/constants/Patterns'
import { isEmpty } from 'dna-common'
import classNames from 'classnames'

export const StoreInfoForm = observer((props: StoreInfoFormProps) => {
  const {
    form,
    onSelectIndustry,
    mccList,
    isMccListLoading,
    mccOptions,
    onSelectMcc,
    bankAccounts,
    directDebitAccounts
  } = props
  const { validateFields, getFieldValue, setFieldsValue, setFields } = form
  const setError = (error: string, fieldName: string) => {
    setFields([
      {
        name: [fieldName, 'addressLine1'],
        errors: [error],
        validating: false,
        touched: true,
        value: form.getFieldValue([fieldName, 'addressLine1'])
      },
      {
        name: [fieldName, 'townOrCity'],
        errors: [error],
        validating: false,
        touched: true,
        value: form.getFieldValue([fieldName, 'townOrCity'])
      }
    ])
  }

  const resetError = (fieldName: string) => {
    setFields([
      {
        name: [fieldName, 'addressLine1'],
        errors: [],
        validating: false,
        touched: true,
        value: form.getFieldValue([fieldName, 'addressLine1'])
      },
      {
        name: [fieldName, 'townOrCity'],
        errors: [],
        validating: false,
        touched: true,
        value: form.getFieldValue([fieldName, 'townOrCity'])
      }
    ])
  }

  const accountsForDD = isEmpty(directDebitAccounts) ? bankAccounts : directDebitAccounts
  return (
    <>
      <Collapse defaultActiveKey={'outletInfo'} className={styles.styledCollapse}>
        <Collapse.Panel header={translations().outletInformation} key={'outletInfo'}>
          <div className={styles.container}>
            <Form.Item shouldUpdate noStyle>
              <FormSubgroupTitle>{translations().storeInfo}</FormSubgroupTitle>
              <Form.Item
                name='storeName'
                label={<div>{translations().tradingName}</div>}
                validateTrigger='onBlur'
                rules={[{ required: true, message: translations().required }]}
              >
                <Input
                  onChange={e => {
                    form.setFieldsValue({
                      systemDescriptor: e.target.value?.substring(0, 24)
                    })
                  }}
                  placeholder={translations().storeNamePlaceholder}
                />
              </Form.Item>

              <Form.Item
                name='systemDescriptor'
                label={<div className={styles.description}>{translations().systemDescriptorDescription}</div>}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  { required: true, message: translations().required },
                  { type: 'string', max: 24, message: translations().maxLength24 }
                ]}
              >
                <Input placeholder={translations().systemDescriptorPlaceholder} />
              </Form.Item>

              <Row gutter={[32, 0]}>
                <Col xs={24}>
                  <FormSubgroupTitle>
                    {form.getFieldValue('storeName')} {translations().storeAddress.title}
                  </FormSubgroupTitle>

                  <Form.Item
                    name={['storeAddress', 'country']}
                    label={translations().storeAddress.countryLabel}
                    tooltip={translations().storeAddress.countryTooltip}
                    rules={[
                      {
                        required: true,
                        message: translations().storeAddress.countryRequired
                      }
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.registeredOfficeAddress?.country !== curValues.registeredOfficeAddress?.country
                    }
                  >
                    {() => (
                      <Form.Item
                        name={['storeAddress', 'postalCode']}
                        label={translations().storeAddress.postalCodeLabel}
                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                          return e.currentTarget.value?.toLocaleUpperCase()
                        }}
                        rules={[
                          {
                            required: true,
                            message: translations().storeAddress.postalCodeRequired
                          },
                          {
                            validator: async (rule, value) => {
                              const isUK = isCountryUK(getFieldValue(['storeAddress', 'country']))
                              if (value && isUK && !validatePostalCodeOfUK(value)) {
                                throw new Error(translations().storeAddress.postalCodeInvalid)
                              }
                            }
                          }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <PostalCode
                          isUK={isCountryUK(getFieldValue(['storeAddress', 'country']))}
                          onAddressSelect={a => {
                            const building = a.building_name || a.building_number
                            setFieldsValue({
                              storeAddress: {
                                addressLine1: a.line_1,
                                addressLine2: `${a.line_2} ${a.line_3 ? ', ' + a.line_3 + ',' : ''} ${a.line_4}`,
                                townOrCity: a.town_or_city,
                                region: a.county
                              },
                              systemStoreAddress: {
                                addressLine1: ((building ? building + ' ' : '') + a.thoroughfare).substring(0, 40),
                                townOrCity: a.town_or_city,
                                postalCode: getFieldValue(['storeAddress', 'postalCode'])
                              }
                            })

                            validateFields([
                              ['systemStoreAddress', 'townOrCity'],
                              ['systemStoreAddress', 'addressLine1']
                            ])
                          }}
                          onChange={() =>
                            !isCountryUK(getFieldValue(['storeAddress', 'country'])) &&
                            setFieldsValue({
                              systemStoreAddress: {
                                postalCode: getFieldValue(['storeAddress', 'postalCode'])
                              }
                            })
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>

                  <Form.Item
                    name={['storeAddress', 'addressLine1']}
                    label={translations().storeAddress.addressLine1Label}
                    rules={[
                      {
                        required: true,
                        message: translations().storeAddress.addressLine1Required
                      }
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input
                      onChange={() =>
                        !isCountryUK(getFieldValue(['storeAddress', 'country'])) &&
                        setFieldsValue({
                          systemStoreAddress: {
                            addressLine1: getFieldValue(['storeAddress', 'addressLine1'])
                          }
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name={['storeAddress', 'addressLine2']}
                    label={translations().storeAddress.addressLine2Label}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={['storeAddress', 'townOrCity']}
                    label={translations().storeAddress.cityLabel}
                    rules={[
                      {
                        required: true,
                        message: translations().storeAddress.cityRequired
                      },
                      {
                        pattern: CITY_TYPING_PATTERN,
                        message: translations().storeAddress.cityInvalid
                      }
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input
                      onChange={e => form.setFieldsValue({ systemStoreAddress: { townOrCity: e.target.value } })}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <FormSubgroupTitle>
                {form.getFieldValue('storeName')} {translations().storeAddress.titleSystem}
              </FormSubgroupTitle>
              <div className={styles.description}>{translations().systemStoreAddressDescription}</div>

              <Form.Item
                name={['systemStoreAddress', 'country']}
                label={translations().storeAddress.countryLabel}
                tooltip={translations().storeAddress.countryTooltip}
                rules={[
                  {
                    required: true,
                    message: translations().storeAddress.countryRequired
                  }
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.locationAddress?.country !== curValues.locationAddress?.country
                }}
              >
                {() => (
                  <Form.Item
                    name={['systemStoreAddress', 'postalCode']}
                    label={translations().postalCode}
                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                      return e.currentTarget.value?.toLocaleUpperCase()
                    }}
                    rules={[
                      { required: true, message: translations().required },
                      {
                        validator: async (rule, value) => {
                          const isUK = isCountryUK(getFieldValue(['systemStoreAddress', 'country']))
                          if (value && isUK && !validatePostalCodeOfUK(value)) {
                            throw new Error(translations().storeAddress.postalCodeInvalid)
                          }
                        }
                      }
                    ]}
                    validateTrigger='onBlur'
                  >
                    <PostalCode
                      isUK={isCountryUK(getFieldValue(['systemStoreAddress', 'country']))}
                      onAddressSelect={a => {
                        if ((a.building_name.length || a.building_number.length) && a.thoroughfare.length) {
                          const building = a.building_name || a.building_number
                          setFieldsValue({
                            systemStoreAddress: {
                              addressLine1: (building ? building + ' ' : '' + a.thoroughfare).substring(0, 40),
                              townOrCity: a.town_or_city
                            }
                          })
                          validateFields([
                            ['systemStoreAddress', 'townOrCity'],
                            ['systemStoreAddress', 'addressLine1']
                          ])
                        } else {
                          setFieldsValue({
                            systemStoreAddress: {
                              addressLine1: '',
                              townOrCity: ''
                            }
                          })
                          setError(translations().incompleteData, 'systemStoreAddress')
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                name={['systemStoreAddress', 'addressLine1']}
                label={translations().address}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  { required: true, message: translations().required },
                  {
                    validator: async (rule, value) => {
                      const { addressLine1, townOrCity } = getFieldValue('systemStoreAddress')
                      if (addressLine1?.concat(' ', townOrCity).length > 40) {
                        setError(translations().addressError, 'systemStoreAddress')
                        return Promise.reject()
                      } else if (addressLine1 && townOrCity) {
                        resetError('systemStoreAddress')
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={['systemStoreAddress', 'townOrCity']} label={translations().townOrCity}>
                <Input disabled />
              </Form.Item>

              <FormSubgroupTitle>{translations().industryAndMCC}</FormSubgroupTitle>

              <Form.Item
                name='natureOfBusiness'
                label={<div>{translations().natureOfBusinessLabel}</div>}
                rules={[
                  {
                    required: true,
                    message: translations().natureOfBusinessRequired
                  }
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={translations().natureOfBusinessPlaceholder} />
              </Form.Item>

              <Form.Item
                name={'industry'}
                label={translations().industry}
                rules={[{ required: true, message: translations().required }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  showSearch
                  placeholder={translations().industryPlaceholder}
                  options={mccList}
                  onSelect={e => {
                    form.resetFields(['mcc'])
                    onSelectIndustry(e.toString())
                  }}
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {t => {
                  return (
                    <Form.Item
                      name={'mcc'}
                      label={translations().mcc}
                      rules={[{ required: true, message: translations().required }]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Select
                        loading={isMccListLoading}
                        allowClear
                        showSearch
                        optionFilterProp='label'
                        placeholder={translations().mccPlaceholder}
                        options={mccOptions?.map(itm => ({ value: itm.code, label: `${itm.code}: ${itm.name}` }))}
                        disabled={mccOptions.length === 0 || isMccListLoading}
                        onSelect={e => onSelectMcc(e.toString(), form)}
                      />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse defaultActiveKey={'bankDetails'} className={classNames(styles.styledCollapse, styles.mt)}>
        <Collapse.Panel header={translations().assignBankDetails} key={'bankDetails'}>
          <div className={styles.container}>
            <FormSubgroupTitle>{translations().titleDirectDebit}</FormSubgroupTitle>
            <Form.Item
              name={'directDebitAccount'}
              label={translations().directDebitAccount}
              rules={[{ required: true, message: translations().required }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Select
                showSearch
                placeholder={translations().assingAccount}
                options={accountsForDD?.map(item => ({
                  value: item.bankAccountNumber,
                  label: !isEmpty(item.accountName)
                    ? `${item.accountName} (${item.sortCode} - ${item.bankAccountNumber})`
                    : item.bankAccountNumber
                }))}
              />
            </Form.Item>

            <FormSubgroupTitle>{translations().titleSettlements}</FormSubgroupTitle>
            <Form.Item
              name={'settlementAccount'}
              label={translations().settlementAccount}
              rules={[{ required: true, message: translations().required }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Select
                showSearch
                placeholder={translations().assingAccount}
                options={bankAccounts?.map(item => ({
                  value: item.bankAccountNumber,
                  label: !isEmpty(item.accountName)
                    ? `${item.accountName} (${item.sortCode} - ${item.bankAccountNumber})`
                    : item.bankAccountNumber
                }))}
              />
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  )
})
