import {
  TransactionResponseModel,
  TransactionsParams,
  BpmUserModel,
  BpmStatusModel,
  AcquisitionChannelModel,
  BpmUserGroupModel,
  PosTransactionsParams,
  TransactionsAcquisitionChannelModel,
  GeneralTypeModel,
  PosModelsResponseType,
  PosBundlesResponseType,
  AcquisitionChannelType
} from '~/code/models'
import { FetchMerchantsRes, FetchMerchantsReq, CategoryType } from '~/code/models/handbooks'
import { getWithAuth, postWithAuth } from './authorised-requests'
import { AnalyticsOverviewSummaryRequest } from '~/code/models/analytics/AnalyticsOverviewSummaryRequest'
import {
  AnalyticsDetailedSummaryResponse,
  EntitySummary,
  EntitySummaryGroup,
  StatusSummaryGroup
} from '~/code/models/analytics/AnalyticsSummaryResponse'
import { AnalyticsYearsComparisonRequest } from '~/code/models/analytics/AnalyticsYearsComparisonRequest'
import { AnalyticsYearsComparisonResponse } from '~/code/models/analytics/AnalyticsYearsComparisonResponse'
import { AnalyticsDetailedSummaryRequest } from '~/code/models/analytics/AnalyticsDetailedSummaryRequest'
import { PosTransactionResponseModel } from '~/code/models/PosTransactionResponseModel'
import { AnalyticsPosOverviewSummaryRequest } from '../models/analytics/AnalyticsPosOverviewSummaryRequest'
import {
  AnalyticsPosDetailedSummaryResponse,
  StatusSummaryGroupPos,
  StatusSummaryPos
} from '../models/analytics/AnalyticsPosSummaryResponse'
import { AnalyticsPosDetailedSummaryRequest } from '../models/analytics/AnalyticsPosDetailedSummaryRequest'
import { getAcquisitionChannel, isPartner } from './auth'
import { CheckingResultsModel, NotesModel } from '../pages/BPM/Onboarding/components/Processes/components/Process/types'
import { BPMApplicationModel } from '../pages/BPM/Onboarding/models'
import { MerchantTerminalResponseModel } from '../components/StoreAndTerminalFilter/models'
import { TerminalType } from '../components/StoreAndTerminalFilter/constants'
import { ErrorCodesEcomResType } from '../models/analytics/AnalyticsEcomErrorCodes'
import { ApprovalRateSummaryGroup } from '../models/analytics/AnalyticsApprovalRateSummary'
import { ExportPosMonitoringParamsType } from '~/code/models/ExportPosMonitoringParamsType'
import { ExportEcomMonitoringParamsType } from '~/code/models/ExportEcomMonitoringParamsType'

export const fetchTransactions = (params: TransactionsParams) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<TransactionResponseModel>('/api/v1/dashboard/transactions/statement', {
    ...params,
    acquisitionChannel
  })
}

export const postExports = (params: ExportPosMonitoringParamsType) =>
  postWithAuth('/api/v1/merchants/pos/transactions/export', params)

export const postExportsV2 = (params: ExportPosMonitoringParamsType) =>
  postWithAuth('/api/v2/merchants/pos/transactions/export', params)

export const postExportsTest = (params: ExportPosMonitoringParamsType) =>
  postWithAuth('/api/temp/merchants/pos/transactions/export', params)

export const postEcomExports = (params: ExportEcomMonitoringParamsType) =>
  postWithAuth('/api/v1/merchants/ecom/transactions/export', params)

export const postAmexExports = (params: ExportPosMonitoringParamsType) =>
  postWithAuth('/api/v1/merchants/pos/amex-transactions/export', params)

export const fetchPosTransactions = (params: PosTransactionsParams) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<PosTransactionResponseModel>(`/api/v3/merchants/pos/transactions`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosTestTransactions = (params: PosTransactionsParams) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<PosTransactionResponseModel>(`/api/v3/temp/merchants/pos/transactions`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosAmexTransactions = (params: PosTransactionsParams) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<PosTransactionResponseModel>(`/api/v1/merchants/pos/amex-transactions`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchMerchants = (params?: FetchMerchantsReq) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<FetchMerchantsRes>(`/api/v1/dashboard/merchants`, { ...params, acquisitionChannel })
}

export const fetchEcomErrorCodes = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<ErrorCodesEcomResType>(`/api/v1/dashboard/ecom/transactions/top-error-code-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosErrorCodes = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<StatusSummaryPos[]>(`/api/v1/dashboard/pos/transactions/top-error-code-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchAnalyticsStatusSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<StatusSummaryGroup>(`/api/v1/dashboard/transactions/status-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchAnalyticsPosStatusSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<StatusSummaryGroupPos>(`/api/v1/dashboard/pos/transactions/total-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchAnalyticsEcomApprovalRateSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<ApprovalRateSummaryGroup>(`/api/v1/dashboard/ecom/transactions/approval-rate-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchAnalyticsPosApprovalRateSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<ApprovalRateSummaryGroup>(`/api/v1/dashboard/pos/transactions/approval-rate-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTransactionsSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummary[]>(`/api/v1/dashboard/transactions/summary`, { ...params, acquisitionChannel })
}

export const fetchPosTransactionsSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopMerchantsSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-merchant-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosTopMerchantsSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/top-merchant-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopIssuingBanksSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-issuer-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosTopIssuingBanksSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/top-issuer-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopMCCSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-mcc-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosTopMCCSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/top-mcc-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopPaymentMethodsSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-payment-method-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopCardSchemesSummary = (params: AnalyticsOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-card-scheme-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosTopCardSchemesSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/top-card-scheme-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchTopAcquisitionChannelsSummary = (params: AnalyticsOverviewSummaryRequest) => {
  return getWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/transactions/top-acquisition-channel-summary`, params)
}

export const fetchPosTopAcquisitionChannelsSummary = (params: AnalyticsPosOverviewSummaryRequest) => {
  return postWithAuth<EntitySummaryGroup>(`/api/v1/dashboard/pos/transactions/top-acquisition-channel-summary`, params)
}

export const fetchAnalyticsYearsComparison = (ecomPos: 'ecom' | 'pos', params: AnalyticsYearsComparisonRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsYearsComparisonResponse>(`/api/v1/dashboard/${ecomPos}/transactions/volume`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchMerchantsSummary = (params: AnalyticsDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsDetailedSummaryResponse>(`/api/v1/dashboard/transactions/merchant-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchMccSummary = (params: AnalyticsDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsDetailedSummaryResponse>(`/api/v1/dashboard/transactions/mcc-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchIssuingBanksSummary = (params: AnalyticsDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsDetailedSummaryResponse>(`/api/v1/dashboard/transactions/issuer-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchCardTypesSummary = (params: AnalyticsDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsDetailedSummaryResponse>(`/api/v1/dashboard/transactions/card-scheme-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPaymentMethodsSummary = (params: AnalyticsDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return getWithAuth<AnalyticsDetailedSummaryResponse>(`/api/v1/dashboard/transactions/payment-method-summary`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchAcquisitionChannelsSummary = (params: AnalyticsDetailedSummaryRequest) => {
  return getWithAuth<AnalyticsDetailedSummaryResponse>(
    `/api/v1/dashboard/transactions/acquisition-channel-summary`,
    params
  )
}

export const fetchPosMerchantsSummary = (params: AnalyticsPosDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<AnalyticsPosDetailedSummaryResponse>(`/api/v1/dashboard/pos/transactions/summary/merchant`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosMccSummary = (params: AnalyticsPosDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<AnalyticsPosDetailedSummaryResponse>(`/api/v1/dashboard/pos/transactions/summary/mcc`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosIssuingBanksSummary = (params: AnalyticsPosDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<AnalyticsPosDetailedSummaryResponse>(`/api/v1/dashboard/pos/transactions/summary/issuer`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosCardTypesSummary = (params: AnalyticsPosDetailedSummaryRequest) => {
  const acquisitionChannel = isPartner() ? getAcquisitionChannel() : params.acquisitionChannel
  return postWithAuth<AnalyticsPosDetailedSummaryResponse>(`/api/v1/dashboard/pos/transactions/summary/card-scheme`, {
    ...params,
    acquisitionChannel
  })
}

export const fetchPosAcquisitionChannelsSummary = (params: AnalyticsPosDetailedSummaryRequest) => {
  return postWithAuth<AnalyticsPosDetailedSummaryResponse>(
    `/api/v1/dashboard/pos/transactions/summary/acquisition-channel`,
    params
  )
}

export const fetchBpmUsers = () => getWithAuth<BpmUserModel[]>(`/api/bpm/users`)

export const fetchBpmUserGroups = () => getWithAuth<BpmUserGroupModel[]>(`/api/bpm/groups`)

export const fetchBpmStatuses = () => getWithAuth<BpmStatusModel[]>(`/api/bpm/statuses`)

export const fetchAcquisitionChannels = (params?: { dashboardSource: string }) =>
  getWithAuth<AcquisitionChannelModel[]>(`/api/bpm/acquisition-channels`, params)

export const fetchTransactionsAcquisitionChannels = () =>
  getWithAuth<TransactionsAcquisitionChannelModel[]>(`/api/v1/dashboard/acquisition-channels`)

export const fetchApprovalTypes = () => getWithAuth<GeneralTypeModel[]>(`/api/bpm/approval-types`)

export const fetchProductTypes = () => getWithAuth<GeneralTypeModel[]>(`/api/bpm/applications/filter/product-types`)

// Onboarding Process fetchers
export const fetchProcessInfo = (processID: string) =>
  getWithAuth<BPMApplicationModel>(`/api/v2/processes/${processID}/info`)

export const fetchProcessApplicationData = (processID: string) =>
  getWithAuth<any>(`/api/v2/processes/${processID}/application`)

export const fetchProcessNotes = (processID: string) => getWithAuth<NotesModel>(`/api/v2/processes/${processID}/notes`)

export const fetchCheckingResults = (processID: string) =>
  getWithAuth<CheckingResultsModel>(`/api/v2/processes/${processID}/background-checks`)

export const fetchMerchantTerminals = (merchantID: string, params: { type: TerminalType }) =>
  getWithAuth<MerchantTerminalResponseModel[]>(`/api/v1/merchants/${merchantID}/terminals`, params)

export const fetchPosModels = () =>
  getWithAuth<{ code: string; name: string }[]>(`/bpm/dictionary/pos-type`)

export const fetchMccList = () =>
  getWithAuth<{ code: number; name: string; mcc: any }[]>(`/bpm/dictionary/industry?mcc=true`)

export const fetchPosModelsV2 = () =>
  getWithAuth<PosModelsResponseType[]>(`/bpm/v2/dossier/dictionary/d_pos_model?is_active=true`)

export const fetchPosBundlesById = (id: number) =>
  getWithAuth<PosBundlesResponseType[]>(`/bpm/v2/dossier/dictionary/d_pos_bundle?pos_model=${id}`)

export const fetchCategories = () => getWithAuth<CategoryType[]>(`/bpm/v2/dossier/segment-category`)

export const fetchSubCategories = () => getWithAuth<CategoryType[]>(`/bpm/v2/dossier/segment-sub-category`)

export const fetchAcquisitionChannelsV2 = () => getWithAuth<AcquisitionChannelType[]>(`/bpm/v2/dossier/partner`)

export const fetchTerminalSubscriptionPrice = () =>
  getWithAuth<PosModelsResponseType[]>(
    `/bpm/v2/dossier/dictionary/d_terminal_subscription_price?is_active=true`
  )

  export const fetchPosBundlesISO = (id: number, partner: string) =>
    getWithAuth<PosBundlesResponseType[]>(`/bpm/v2/dossier/dictionary/d_pos_isobundle?pos_model=${id}&partner=${partner}`)

  