import { getWithAuth, postWithAuth } from '~/code/services'
import { MerchantDossierModel } from '../models/merchant-dossier-v1/MerchantDossierModel'
import {
  BusinessAccountVerificationRequestType,
  BusinessAccountVerificationResultType,
  PersonalAccountVerificationRequestType,
  PersonalAccountVerificationResultType
} from '~/code/pages/MerchantsManagement/components/Stores/models'

import {
  ChangeGrossSettlementDossierV2ReqType,
  CloseMerchantDossierV2ReqType,
  RequestMotoVtDossierV2ReqType,
  ProcessStartResType,
  ChangeBankDetailsFullDossierV2ReqType,
  CloseTerminalsDossierV2ReqType,
  GetContractsInfoResultType,
  RiskLimitsDictionaryResponseType,
  RiskLimitsChangeDossierV2Req,
  BalancesType,
  CompanyFullDossierV2Type,
  DictionaryValueType,
  GetSettlementPeriodResType,
  ChangeOfSettlementPeriodReqType,
  IndividualInfoType,
  DictionaryMappingValueType,
  HoldContractDossierV2ReqType,
  TerminalIssueReqType,
  SettlementLogResType,
  EditTerminalTariffsReqType,
  PosBundlesResType,
  PosModelRes,
  RoutingDataType,
  SetTipJarConfigurationReqType,
  MerchantClosureReasonType,
  ChangeRouteProcessReqType,
  SalesForceDataResType,
  AdjustmentProcessReqType,
  GetStoreAttributesResultType,
  EcospendValidateSortCodeResponseType,
  CloseStoreReqType
} from '../models/merchant-dossier-v2'

import { TariffGroupedSettingsType } from '~/code/pages/MerchantsManagement/components/Stores/components/Contract/models'

export const fetchMerchantDossiersV2 = (params: { page: number; size: number; value?: string }) =>
  getWithAuth<{ data: MerchantDossierModel[]; totalCount: number }>('/bpm/v2/dossier/legal/list', params)

export const fetchCompanyFullDossierV2 = (id: string) =>
  getWithAuth<CompanyFullDossierV2Type>(`/bpm/v2/dossier/legal/${id}`)

export const verificateBusinessBankAccount = (params: BusinessAccountVerificationRequestType) =>
  postWithAuth<BusinessAccountVerificationResultType>('/bpm/v1/business-bank-account-verification', params)

export const verificatePersonalBankAccount = (params: PersonalAccountVerificationRequestType) =>
  postWithAuth<PersonalAccountVerificationResultType>('/bpm/v1/personal-bank-account-verification', params)

export const startProcessChangeSettlementDossierV2 = (params: ChangeGrossSettlementDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/settlements/start', params)

export const startProcessMotoVtDossierV2 = (data: RequestMotoVtDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>(`/bpm/engine/process-definition/key/dossiermoto/start`, data)

export const startProcessChangeBankDetailsDossierV2 = (params: ChangeBankDetailsFullDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierbank/start', params)

export const startProcessCloseMerchantDossierV2 = (params: CloseMerchantDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierclose/start', params)

export const startProcessCloseTerminalDossierV2 = (params: CloseTerminalsDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierterminalsclose/start', params)

export const startProcessChangeSettlementPeriod = (params: ChangeOfSettlementPeriodReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossiersetperiod/start', params)

export const startTerminalIssueProcess = (params: TerminalIssueReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/update_merchant/start', params)

export const fetchPosModels = () => getWithAuth<PosModelRes[]>('/bpm/v2/dossier/dictionary/d_pos_model?is_active=true')

export const getRiskLimitNamesDossierV2 = () =>
  getWithAuth<RiskLimitsDictionaryResponseType[]>(`/bpm/v2/dossier/dictionary/add_param?classifier=risk_limit`)

export const startRiskLimitsDossierV2ChangeProcess = (params: RiskLimitsChangeDossierV2Req) =>
  postWithAuth('/bpm/engine/process-definition/key/dossierrisk/start', params)

export const getBalances = (topLevelSubjectId: number) =>
  getWithAuth<BalancesType[]>(`/bpm/adw/dossier/id/${topLevelSubjectId}/balance`)

export const getDictionaryValue = (dictName: string, searchId: string) =>
  getWithAuth<DictionaryValueType[]>(`/bpm/v2/dossier/dictionary/${dictName}?id=${searchId}`)

export const getDictionary = (dictName: string) =>
  getWithAuth<DictionaryValueType[]>(`/bpm/v2/dossier/dictionary/${dictName}`)

export const getSettlementPeriod = (topLevelSubjectId: number) =>
  getWithAuth<GetSettlementPeriodResType>(`/bpm/v2/dossier/legal/${topLevelSubjectId}/tariffs/settlement-period`)

export const getIndividualsInfo = (topLevelSubjectId2: number | string) =>
  getWithAuth<IndividualInfoType>(`/bpm/v2/dossier/person/origin/${topLevelSubjectId2}`)

export const startProcessHoldContractDossierV2 = (params: HoldContractDossierV2ReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierholdcontract/start', params)

export const loadSettlementLog = (dsrId: number, contractNumber: string) =>
  getWithAuth<SettlementLogResType[]>('/bpm/v2/dossier/contracts/contract/settlement-log', { contractNumber, dsrId })

export const fetchPosBundlesById = (id: number) =>
  getWithAuth<PosBundlesResType[]>(`/bpm/v2/dossier/dictionary/d_pos_bundle?pos_model=${id}`)

export const loadRoutingData = (dsrId: number) =>
  getWithAuth<RoutingDataType[]>(`/bpm/adw/dossier/id/${dsrId}/routing-info`)

export const startProcessEditTariffs = (params: EditTerminalTariffsReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierchangeterminaltariffs/start', params)

export const setTipJarConfiguration = (params: SetTipJarConfigurationReqType) =>
  postWithAuth<ProcessStartResType>(`/bpm/engine/process-definition/key/dossiertipjar/start`, params)

export const getDictionaryMappingValues = (dsrId: number, paramList: string) =>
  getWithAuth<DictionaryMappingValueType[]>(
    `/bpm/v2/dossier/dictionary/mapping_value?dsr_id=${dsrId}&param_id=${paramList}`
  )

export const getMerchantClosureReasons = () =>
  getWithAuth<MerchantClosureReasonType[]>('/bpm/dictionary/processes/key/merchant-closing-reasons/entries')

export const startChangeRouteProcess = (params: ChangeRouteProcessReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/merchantrouteswitcher/start', params)

export const loadSalesForceData = (sfId: string) =>
  getWithAuth<SalesForceDataResType[]>(`/bpm/v1/salesforce/case?id=${sfId}`)

export const startAdjustmentProcess = (params: AdjustmentProcessReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/adjustment/start', params)

export const getTariffSettings = (tariffGroup: string, product: string) =>
  getWithAuth<TariffGroupedSettingsType[]>('/bpm/product-tariffer/pricing-settings/grouped', { tariffGroup, product })

export const getEditTariffsInfo = (id: string) =>
  getWithAuth<GetContractsInfoResultType>(`/bpm/v2/dossier/legal/contract/${id}/terminal/tariffs/grouped`)

export const getStoreAttributes = (id: number) =>
  getWithAuth<GetStoreAttributesResultType>(`/bpm/v2/store/id/${id}/attributes`)

export const ecospendValidateSortCode = (sortCode: string) =>
  getWithAuth<EcospendValidateSortCodeResponseType>(`/bpm/adapter-ecospend/v1/sortcodes/${sortCode}`)

export const startProcessCloseStore = (params: CloseStoreReqType) =>
  postWithAuth<ProcessStartResType>('/bpm/engine/process-definition/key/dossierclose/start', params)
