import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Typography, Card, Button, Tooltip, List, Row, Col, Collapse, Skeleton } from 'antd'
import { EyeOutlined, CloseOutlined } from '@ant-design/icons'
import { EditOutlinedBlue } from '~/assets/icons'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { AddressType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { Info } from '../Info'
import { ChangeBankDetailsModal } from './components/ChangeBankDetailsModal'
import { Contract } from './components/Contract'
import { RowElement } from './components/RowElement'
import { EditTariffsModal } from './components/EditTariffsModal'
import { TipJarModal } from './components/TipJarModal'
import { ViewAttributesModal } from './components/ViewAttributesModal'
import { CloseStoreModal } from './components/CloseStoreModal'
import { IStoresDossierV2Store } from './models'
import { StoresProps } from './props'
import translations from './translations'
import styles from './Stores.scss'

export const StoresDossierV2StoreSymbol = Symbol('StoresDossierV2Store')
export const Stores: React.FC<StoresProps> = observer(({ storesInfo }) => {
  const store = useInjection<IStoresDossierV2Store>(StoresDossierV2StoreSymbol)

  const getStoreAddress = (address: AddressType | null) => {
    let addressLine: string = ''
    if (address) {
      addressLine = `${address.townOrCity ? address.townOrCity : ''} ${
        address.addressLine1 ? address.addressLine1 : ''
      } ${address.postalCode ? address.postalCode : ''}`
    }
    return addressLine
  }

  const midsList = useMemo(() => store.getMidsByStore(), [store.selectedStoreId])

  const renderTipJarButton = () => {
    // TODO: remove TipJar button. Hide until decision about the process is made DAS-841
    return null

    /*if (!hasPermissions([PermissionMap.merchants.processes.tipjar.read])) {
      return null
    }

    return (
      <div>
        <Button
          disabled={store.isSubsidiary}
          icon={<SettingOutlined />}
          loading={store.isTipJarLoading}
          onClick={() => store.loadTipJarInfo()}
        >
          {translations().tipJar}
        </Button>
      </div>
    )*/
  }

  const renderCloseStoreButton = (originId: string, id: number) => {
    if (!hasPermissions([PermissionMap.merchants.processes.merchant_close.read])) {
      return null
    }
    return (
      <Button icon={<CloseOutlined />} onClick={() => store.openCloseStoreModal(originId, id)}>
        {translations().closeStore}
      </Button>
    )
  }

  const renderName = (accName: string, sortCode: string, isDirectDebit: boolean) => {
    return (
      <>
        <div className={styles.alias}>
          {isDirectDebit ? translations().directDebitAlias : translations().settlementAlias}
        </div>
        <div className={styles.infoItem}>
          <Info label={translations().accountName} value={accName} />
        </div>
        <div className={styles.infoItem}>
          <Info label={translations().sortCode} value={sortCode} />
        </div>
      </>
    )
  }

  return store.isSettlementsLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Row>
        <Col xs={24} sm={8}>
          <Typography.Title level={3}>
            {translations().companyName}: {storesInfo.mainInfo?.companyName}
          </Typography.Title>
        </Col>

        <Col xs={24} sm={16}>
          <div className={styles.groupContainer}>{renderTipJarButton()}</div>
        </Col>
      </Row>
      {storesInfo.processing?.stores?.map((stores, idx) => {
        const bankDetailsData = store.getBankDetailsData(stores)
        return (
          <Card
            key={idx}
            title={
              <div className={styles.flexCard}>
                <div>
                  <RowElement label={translations().storeName} value={stores.storeInfo.storeName} />
                  <RowElement label={translations().address} value={getStoreAddress(stores.storeAddress)} />
                  {!stores.storeInfo?.isActive && (
                    <div className={styles.redText}>
                      <RowElement label={translations().storeStatus} value={translations().closedStore} />
                    </div>
                  )}
                </div>
                <div className={styles.centeredBtn}>
                  {stores?.storeInfo?.isActive &&
                    renderCloseStoreButton(stores?.storeInfo?.originId, stores?.storeInfo?.id)}
                  <Tooltip title={translations().viewAttributes}>
                    <Button icon={<EyeOutlined />} onClick={() => store.loadStoreAttributes(stores?.storeInfo?.id)}>
                      {translations().viewAttributesBtnLabel}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            }
            className={styles.storeCard}
          >
            {bankDetailsData?.length > 0 && (
              <Collapse
                items={[
                  {
                    key: idx,
                    label: translations().changeBankDetails,
                    children: (
                      <Row>
                        <Col xs={16}>
                          <List
                            itemLayout='horizontal'
                            split={false}
                            dataSource={bankDetailsData.map(detail => ({
                              title: renderName(detail.bankAccountNumber, detail.sortCode, detail.isDirectDebit),
                              bankDetails: detail,
                              originStoreId: detail.originStoreId
                            }))}
                            renderItem={item => (
                              <List.Item
                                actions={[
                                  <Tooltip title={translations().edit} key='edit'>
                                    <Button
                                      type='text'
                                      icon={<EditOutlinedBlue />}
                                      onClick={async () => {
                                        await store.openChangeBankDetailsModal(item.bankDetails, stores.storeInfo?.id)
                                      }}
                                    />
                                  </Tooltip>
                                ]}
                              >
                                {item.title}
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    )
                  }
                ]}
              />
            )}
            {stores.contracts.map((contractItem, idx) => (
              <Contract
                key={idx}
                contract={contractItem.contract}
                mid={contractItem.terminals[0]?.terminal?.merchantId}
                midText={`${store.getMidsFromContractInfo(contractItem)} (${store.defineProductType(
                  contractItem.terminals.map(terminal => terminal.terminal.terminalId)
                )})`}
              />
            ))}
          </Card>
        )
      })}
      {store.isChangeBankDetailsModalOpen && (
        <ChangeBankDetailsModal
          width={'50%'}
          open={true}
          title={translations().changeBankDetails}
          midsList={midsList}
        />
      )}
      <TipJarModal open={store.isTipJarModalOpen} />
      {store.isEditTariffsModalOpen && <EditTariffsModal data={store.editContractTariffs} />}
      <ViewAttributesModal storeId={store.selectedAttributesStoreId} open={store.isViewAttributesModalOpen} />
      {store.isCloseStoreModalOpen && (
        <CloseStoreModal storeId={store.selectedOriginStoreId} open={true} midsList={midsList} />
      )}
    </>
  )
})
