import { makeObservable, computed, action, observable, runInAction, reaction } from 'mobx'
import { inject, injectable } from 'inversify'
import { message, FormInstance } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { log } from 'dna-common'
import { sanitizeString, sanitizeFileName } from './services/utils'
import { AppStore } from '~/code/stores/AppStore'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IChangeBankDetailsModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/IChangeBankDetailsModalStore'
import {
  VerifyBankAccountResultType,
  VerifyBankAccountReqType
} from '~/code/pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/models'
import { verifyBankAccount } from '~/code/pages/StartApplication/services'
import { UploadDocumentResType } from '~/code/pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/models'
import {
  FileKey,
  BusinessAccountVerificationResultType,
  PersonalAccountVerificationResultType
} from '~/code/pages/MerchantsManagement/components/Stores/models'
import { BankDetailsAccTypeEnum } from '~/code/pages/MerchantsManagement/components/Stores/constants'
import { CheckedDocumentType } from '~/code/pages/StartApplication/models/DocumentsRequiredModel'
import { ISalesForceSearchStore } from '~/code/pages/MerchantsManagement/components/SalesForceSearch/ISalesForceSearchStore'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import {
  BankDetailType,
  ChangeBankDetailsFullDossierV2ReqType,
  ChangeBankDetailsResponseType,
  BusinessOwnerResponseType,
  IndividualInfoType,
  SalesForceDataResType
} from './models/merchant-dossier-v2'
import { BankAccountType } from '~/code/pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/models'
import {
  getIndividualsInfo,
  verificateBusinessBankAccount,
  verificatePersonalBankAccount,
  startProcessChangeBankDetailsDossierV2,
  ecospendValidateSortCode
} from './services/fetchers'
import { SalesForceSearchStore } from './SalesForceSearchStore'
import {
  companyBusinessStructureCodeList,
  businessAccountType,
  soleTraderAccountType,
  stringType,
  jsonType,
  booleanType,
  businessOwnerDsrCode,
  businessOwnerRelationDirection,
  processInitiationSource
} from './constants'
import translations from './translations'

@injectable()
export class ChangeBankDetailsModalStore implements IChangeBankDetailsModalStore {
  storesDossierV2Store: IStoresDossierV2Store
  isShowOpenBanking: boolean = false
  isChangeBankDetailsLoading: boolean = false
  business: string = businessAccountType
  soleTRader: string = soleTraderAccountType
  businessAccountVerificationResult: BusinessAccountVerificationResultType = null
  personalAccountVerificationResult: PersonalAccountVerificationResultType = null
  businessOwnerResponse: BusinessOwnerResponseType = { received: false, result: null }
  businessOwnerResult: IndividualInfoType = null
  verificateBankAccountRes: { verified: boolean; message: string; requestSent: boolean } = {
    verified: false,
    message: '',
    requestSent: false
  }
  ecospendNeedRestrictionChecks: { sortCodeIsFound: boolean; requestSent: boolean } = {
    sortCodeIsFound: false,
    requestSent: false
  }
  showOpenBankingVerification: boolean = false
  bankDetailsProcessId: string = null
  changeBankDetailsRes: ChangeBankDetailsResponseType = null
  uploadDocumentManually: boolean = false
  showEcospendFinalSuccessMessage: boolean = false
  verifyBankAccountResult: VerifyBankAccountResultType = { type: 'invalid', message: '' }
  isVerifyBankLoading: boolean = false
  salesForceDataFound: boolean = false
  salesForceData: SalesForceDataResType = null
  isChecked: boolean = false
  isOwnerEmailEqualToCompany: boolean = true
  ownerEmail: string = ''
  salesForceSearchStore: ISalesForceSearchStore
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    this.salesForceSearchStore = new SalesForceSearchStore()
    makeObservable(this, {
      selectedBankDetail: computed,
      document: computed,
      companyName: computed,
      verifyBankAccountDetails: action,
      verifyBankAccount: action,
      setVerifyBankAccountResult: action,
      uploadFile: action,
      deleteFile: action,
      clearMessage: action,
      addDocumentType: action,
      changeBankDetails: action,
      clearData: action,
      setSalesForceData: action,
      formatSortCode: action,
      formatBankAccountNumber: action,
      resetData: action,
      isShowOpenBanking: observable,
      isChangeBankDetailsLoading: observable,
      businessAccountVerificationResult: observable,
      personalAccountVerificationResult: observable,
      businessOwnerResponse: observable,
      showOpenBankingVerification: observable,
      bankDetailsProcessId: observable,
      changeBankDetailsRes: observable,
      verificateBankAccountRes: observable,
      uploadDocumentManually: observable,
      showEcospendFinalSuccessMessage: observable,
      verifyBankAccountResult: observable,
      isVerifyBankLoading: observable,
      salesForceDataFound: observable,
      salesForceData: observable,
      isChecked: observable,
      isOwnerEmailEqualToCompany: observable,
      ownerEmail: observable
    })

    reaction(
      () => this.salesForceSearchStore.salesForceDataResult,
      salesForceDataResult => {
        this.salesForceSearchStore.salesForceSuccessfulValidation
          ? this.setSalesForceData(true, salesForceDataResult)
          : this.setSalesForceData(false, null)
      }
    )
  }

  get selectedBankDetail(): BankDetailType {
    return this.storesDossierV2Store.selectedBankDetail
  }

  get document(): UploadDocumentResType[] {
    return this.storesDossierV2Store.documentsData
  }

  get isDocumentLoading(): boolean {
    return this.storesDossierV2Store.isDocumentLoading
  }

  get documentTypes(): FileKey[] | [] {
    return this.storesDossierV2Store.documentTypes
  }

  get documentsMap(): Record<FileKey, UploadFile[]> {
    return this.storesDossierV2Store.documentsMap
  }

  get email(): string {
    return this.storesDossierV2Store.companyFullDossierV2?.email?.[0]?.email
  }

  get companyName(): string {
    return this.storesDossierV2Store.companyFullDossierV2?.mainInfo?.companyName || ''
  }

  setIsChecked(value: boolean) {
    this.isChecked = value
  }

  setIsOwnerEmailEqualToCompany(value: boolean) {
    this.isOwnerEmailEqualToCompany = value
  }

  setOwnerEmail(value: string) {
    this.ownerEmail = value
  }

  setSalesForceData(isFound: boolean, salesForceData: SalesForceDataResType) {
    this.resetData()
    this.salesForceDataFound = isFound
    this.salesForceData = salesForceData
  }

  clearData() {
    this.storesDossierV2Store.clearData()
  }

  setUploadDocumentManually(status: boolean) {
    this.uploadDocumentManually = status
  }

  removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.storesDossierV2Store.removeDocument(fileKey, fileList)
  }

  clearMessage() {
    this.storesDossierV2Store.clearMessage()
  }

  processSFData(form: FormInstance) {
    const bankAccountNumber = this.salesForceData?.bankAccountNumber || ''
    const sortCode = this.salesForceData?.sortCode || ''
    const bankAccountType = this.salesForceData?.accountType?.toLowerCase()
    let accountType = ''
    if (bankAccountType === BankAccountType.Business) {
      accountType = BankAccountType.Business
    } else if (bankAccountType === BankAccountType.Personal) {
      accountType = BankAccountType.Personal
    } else {
      accountType = ''
    }
    form.setFieldsValue({
      bankAccountName: this.salesForceData?.bankAccountName || '',
      bankAccountNumber: bankAccountNumber ? this.formatBankAccountNumber(bankAccountNumber) : '',
      sortCode: sortCode ? this.formatSortCode(sortCode) : '',
      bankAccountType: accountType
    })
  }

  formatSortCode = (sortCode: string) => {
    const cleanedSortCode = sortCode.replace(/[\s-]/g, '')
    if (cleanedSortCode && cleanedSortCode.length === 6) {
      const part1 = cleanedSortCode.substring(0, 2)
      const part2 = cleanedSortCode.substring(2, 4)
      const part3 = cleanedSortCode.substring(4, 6)
      return `${part1} - ${part2} - ${part3}`
    }
    return sortCode
  }

  formatBankAccountNumber = (bankAccountNumber: string) => {
    const cleanedBankAccountNumber = bankAccountNumber?.replace(/\s/g, '')
    if (cleanedBankAccountNumber && cleanedBankAccountNumber.length === 8) {
      return cleanedBankAccountNumber.replace(/(\d{4})(\d{4})/, '$1 $2')
    }
    return bankAccountNumber
  }
  async verifyBankAccountDetails(data: VerifyBankAccountReqType) {
    let verifyBankAccountResult: VerifyBankAccountResultType

    try {
      runInAction(() => {
        this.isVerifyBankLoading = true
      })

      const { status, result, error } = await verifyBankAccount(data)
      if (status !== 200 || error) {
        verifyBankAccountResult = {
          type: 'error',
          message: [error.message || translations().errVerifyingBankAccount]
        }
      } else {
        if (result.errors && result.errors.length > 0) {
          verifyBankAccountResult = {
            type: 'error',
            message: result.errors
          }
        } else if (result.validations && result.validations.length > 0) {
          verifyBankAccountResult = {
            type: 'invalid',
            message: result.validations
          }
        } else if (result.bankDetails && result.bankDetails.bank) {
          verifyBankAccountResult = {
            type: 'valid',
            message: result.bankDetails.bank
          }
        }
      }
    } catch (error) {
      verifyBankAccountResult = {
        type: 'error',
        message: translations().errVerifyingBankAccount
      }
    } finally {
      runInAction(() => {
        this.isVerifyBankLoading = false
        this.setVerifyBankAccountResult(verifyBankAccountResult)
      })
    }
  }

  verifyBankAccount = async (form: FormInstance) => {
    try {
      await form.validateFields(['bankAccountNumber', 'sortCode'])
    } catch (error) {
      return
    }

    await this.verifyBankAccountDetails({
      accountNumber: form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
      sortCode: form.getFieldValue('sortCode')?.replace(/\s|-/g, '')
    })
  }

  resetData = () => {
    this.verifyBankAccountResult.type !== 'invalid' && this.setVerifyBankAccountResult({ type: 'invalid', message: '' })
    if (this.showOpenBankingVerification) this.showOpenBankingVerification = false
    if (this.isShowOpenBanking) this.isShowOpenBanking = false
    if (this.uploadDocumentManually) this.uploadDocumentManually = false
    if (this.verificateBankAccountRes.requestSent)
      this.verificateBankAccountRes = { verified: false, message: '', requestSent: false }
    if (this.ecospendNeedRestrictionChecks.requestSent)
      this.ecospendNeedRestrictionChecks = { sortCodeIsFound: false, requestSent: false }
    this.clearData()
  }

  setVerifyBankAccountResult(value: VerifyBankAccountResultType) {
    this.verifyBankAccountResult = value
  }

  uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
    return this.storesDossierV2Store.uploadFile(documentType, file)
  }

  addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
    return this.storesDossierV2Store.addDocumentType(fileKey, fileList)
  }

  deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
    return this.storesDossierV2Store.deleteFile(documentType, fileName)
  }

  async uploadDocument(fileList: UploadFile[], documentType: FileKey): Promise<void> {
    const file = fileList[0]
    if (!file || !file.name || !file.originFileObj || !file.originFileObj.name) return
    try {
      const clonnedFile = { ...file }
      clonnedFile.name = sanitizeString(clonnedFile.name)
      const modifiedFile = sanitizeFileName(clonnedFile.originFileObj)
      const isUploaded = await this.uploadFile(documentType, modifiedFile)
      if (isUploaded) {
        this.addDocumentType(documentType, [clonnedFile])
      }
    } catch (error) {
      message.error(error)
    }
  }

  setShowOpenBanking(status: boolean) {
    this.isShowOpenBanking = status
  }

  resetParams() {
    this.storesDossierV2Store.clearData()
    this.isShowOpenBanking = false
    this.businessAccountVerificationResult = null
    this.personalAccountVerificationResult = null
    this.businessOwnerResponse = { received: false, result: null }
    this.businessOwnerResult = null
    this.verificateBankAccountRes = { verified: false, message: '', requestSent: false }
    this.setVerifyBankAccountResult({ type: 'invalid', message: '' })
    this.ecospendNeedRestrictionChecks = { sortCodeIsFound: false, requestSent: false }
    this.showOpenBankingVerification = false
    this.bankDetailsProcessId = null
    this.changeBankDetailsRes = null
    this.uploadDocumentManually = null
    this.showEcospendFinalSuccessMessage = false
    this.setIsChecked(false)
    this.setIsOwnerEmailEqualToCompany(true)
    this.setOwnerEmail('')
  }

  closeChangeBankDetailsModal() {
    this.resetParams()
    this.storesDossierV2Store.closeChangeBankDetailsModal()
  }

  defineMerchantType = (): string => {
    const structure = this.storesDossierV2Store?.companyFullDossierV2?.mainInfo?.businessStructure
    if (companyBusinessStructureCodeList.includes(structure)) {
      return this.business
    }
    return this.soleTRader
  }

  isBusinessAccount = (): boolean => {
    const merchantType = this.defineMerchantType()
    const isBusinessAccount =
      merchantType === this.business || this.salesForceData?.accountType?.toLowerCase() === BankAccountType.Business
    return isBusinessAccount
  }

  getAccountUpdateType(updateAllAccounts: boolean) {
    if (updateAllAccounts) return BankDetailsAccTypeEnum.allAccounts
    return this.selectedBankDetail?.isDirectDebit ? BankDetailsAccTypeEnum.ddAccount : BankDetailsAccTypeEnum.stAccount
  }

  searchTopLevelSubjectId(): number | null {
    const { companyFullDossierV2 } = this.storesDossierV2Store
    const { relation } = companyFullDossierV2
    const targetRelation = relation?.find(
      relation =>
        relation?.subjectRelation?.relationType === businessOwnerDsrCode &&
        relation?.subjectRelation?.relationDirection === businessOwnerRelationDirection &&
        relation?.subjectRelation?.isActual
    )

    return targetRelation?.subjectRelation?.topLevelSubjectId2 || null
  }

  async getBusinessOwnerDossierV2(topLevelSubjectId: number) {
    try {
      const { status, error, result } = await getIndividualsInfo(topLevelSubjectId)
      if (status !== 200 || error) {
        runInAction(() => {
          this.businessOwnerResponse = {
            received: false,
            result: null
          }
        })
      } else {
        runInAction(() => {
          this.businessOwnerResult = result
          const { mainInfo, address } = result
          const { birthDate, name, surname, genderValue } = mainInfo
          const {
            addressLine1 = '',
            addressLine2 = '',
            townOrCity = '',
            countyOrState = '',
            postalCode = ''
          } = address[0] ?? {}
          const dateOfBirth = birthDate?.split('-') || []
          let year = null,
            day = null,
            month = null
          if (dateOfBirth.length === 3) {
            year = Number(dateOfBirth[0])
            day = Number(dateOfBirth[2])
            month = Number(dateOfBirth[1])
          }

          this.businessOwnerResponse = {
            received: true,
            result: {
              firstName: name,
              surname,
              gender: genderValue,
              dateOfBirth: {
                year,
                month,
                day
              },
              address: {
                addressLine1,
                addressLine2,
                townOrCity,
                countyOrState,
                postalCode,
                houseNumber: '',
                houseName: ''
              }
            }
          }
        })
      }
    } catch (error) {
      runInAction(() => {
        this.businessOwnerResponse = {
          received: false,
          result: null
        }
      })
    }
  }

  async verificateBusinessBankAccount(formData: FormInstance) {
    try {
      const { companyNumber, companyName } = this.storesDossierV2Store?.companyFullDossierV2?.mainInfo
      const { bankAccountNumber, sortCode } = formData.getFieldsValue()
      const { status, error, result } = await verificateBusinessBankAccount({
        companyName: companyName,
        companyNumber: companyNumber,
        bankAccountType: this.business,
        bankAccountNumber: bankAccountNumber?.replace(/\s/g, ''),
        sortCode: sortCode?.replace(/\s/g, '')
      })
      if (status !== 200 || error) {
        runInAction(() => {
          this.verificateBankAccountRes = {
            verified: false,
            message: error.message,
            requestSent: true
          }
          this.businessAccountVerificationResult = null
        })
      } else {
        runInAction(() => {
          if (result) {
            this.businessAccountVerificationResult = result
            this.verificateBankAccountRes = {
              verified: result.scanResult?.toUpperCase() === 'MATCH' ? true : false,
              message: '',
              requestSent: true
            }
          }
        })
      }
    } catch (error) {
      runInAction(() => {
        this.businessAccountVerificationResult = error
        this.verificateBankAccountRes = {
          verified: false,
          message: error,
          requestSent: true
        }
      })
    }
  }

  async verificatePersonalBankAccount(formData: FormInstance) {
    try {
      const { bankAccountNumber, sortCode, bankAccountName } = formData.getFieldsValue()
      const data = {
        bankAccountNumber: bankAccountNumber?.replace(/\s/g, ''),
        sortCode: sortCode?.replace(/\s/g, ''),
        bankAccountName
      }
      const { status, error, result } = await verificatePersonalBankAccount({
        ...this.businessOwnerResponse.result,
        ...data
      })
      if (status !== 200 || error) {
        runInAction(() => {
          this.verificateBankAccountRes = {
            verified: false,
            message: error.message,
            requestSent: true
          }
          this.personalAccountVerificationResult = null
        })
      } else {
        runInAction(() => {
          this.personalAccountVerificationResult = result
          this.verificateBankAccountRes = {
            verified: result?.result === 'PASS' ? true : false,
            message: error?.message || 'error',
            requestSent: true
          }
        })
      }
    } catch (error) {
      runInAction(() => {
        this.personalAccountVerificationResult = error
        this.verificateBankAccountRes = {
          verified: false,
          message: error,
          requestSent: true
        }
      })
    }
  }

  async verificatedBankAccount(formData: FormInstance) {
    if (this.isBusinessAccount()) {
      await this.verificateBusinessBankAccount(formData)
    } else {
      await this.verificatePersonalBankAccount(formData)
    }
  }

  getContractNumber(): string {
    const { companyFullDossierV2 } = this.storesDossierV2Store
    const { processing } = companyFullDossierV2 || {}
    const { stores } = processing || {}

    const store = stores?.find(store => store?.storeInfo?.id === this.storesDossierV2Store.selectedStoreId)

    return store?.contracts?.[0]?.contract?.contractNumber || ''
  }

  generateChangeBankDetailsData(
    formData: FormInstance,
    dsrId: number,
    needRestrictionChecks: boolean,
    email: string
  ): ChangeBankDetailsFullDossierV2ReqType {
    const formFieldsValues = formData.getFieldsValue()
    const contractNumber = this.getContractNumber()

    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        documents: {
          value:
            this.document?.length > 0
              ? JSON.stringify(
                  this.document.map(data => {
                    return {
                      type: data.type,
                      name: data.fileName,
                      path: data.path
                    }
                  })
                )
              : 'null',
          type: jsonType
        },
        accountsInfoForUpdate: {
          value: JSON.stringify({
            originStoreId: this.selectedBankDetail?.originStoreId,
            accountDetails: formFieldsValues
          }),
          type: jsonType
        },
        accountTypeForUpdate: {
          value: this.getAccountUpdateType(formFieldsValues?.allAccountTypes),
          type: stringType
        },
        verificationResult: {
          value: JSON.stringify(this.verificateBankAccountRes),
          type: jsonType
        },
        businessAccountVerificationResult: {
          value: JSON.stringify(this.businessAccountVerificationResult),
          type: jsonType
        },
        personalAccountVerificationResult: {
          value: JSON.stringify(this.personalAccountVerificationResult),
          type: jsonType
        },
        needRestrictionChecks: {
          value: needRestrictionChecks,
          type: booleanType
        },
        ecospendEmailAddress: {
          value: email || null,
          type: stringType
        },
        caseIdSalesForce: {
          value: this.salesForceData?.id || '',
          type: stringType
        },
        contract: {
          value: contractNumber,
          type: stringType
        },
        contracts: {
          value: JSON.stringify([contractNumber]),
          type: jsonType
        },
        source: {
          value: processInitiationSource,
          type: stringType
        }
      }
    }
  }

  async ecospendAccountVerification(sortCode: string) {
    try {
      const { status, error, result } = await ecospendValidateSortCode(sortCode)
      if (status !== 200 || error) {
        this.ecospendNeedRestrictionChecks = {
          sortCodeIsFound: false,
          requestSent: true
        }
      } else {
        if (result) {
          this.ecospendNeedRestrictionChecks = {
            sortCodeIsFound: result.isExists,
            requestSent: true
          }
        }
      }
    } catch (error) {
      this.ecospendNeedRestrictionChecks = {
        sortCodeIsFound: false,
        requestSent: true
      }
    }
  }

  async validateSoleTraderAccount() {
    const topLevelSubjectId = this.searchTopLevelSubjectId()
    if (topLevelSubjectId) {
      await this.getBusinessOwnerDossierV2(topLevelSubjectId)
      if (!this.businessOwnerResponse.received) {
        throw new Error(translations().attachDocuments)
      }
    }
  }

  async ecospendValidation(sortCode: string) {
    await this.ecospendAccountVerification(sortCode)
    if (!this.ecospendNeedRestrictionChecks.sortCodeIsFound) {
      this.showOpenBankingVerification = false
      if (this.document.length === 0) throw new Error(translations().attachDocuments)
    } else {
      this.showOpenBankingVerification = true
    }
  }

  async changeBankDetailsStartValidations(formData: FormInstance) {
    const { sortCode } = formData.getFieldsValue()
    const cleanedSortCode = sortCode.replace(/\s/g, '')

    if (this.document.length === 0) {
      this.isBusinessAccount() ? await this.verificatedBankAccount(formData) : await this.validateSoleTraderAccount()
    }

    if (!this.verificateBankAccountRes.verified) {
      await this.ecospendValidation(cleanedSortCode)
    }
  }

  setChangeBankDetailsRes(code: number, message: string) {
    this.changeBankDetailsRes = { code, message }
  }

  async changeBankDetails(
    formData: FormInstance,
    startFromEcoSpend: boolean,
    needRestrictionChecks: boolean = false,
    email?: string
  ) {
    try {
      runInAction(() => {
        this.isChangeBankDetailsLoading = true
      })
      const { dsrId } = this.storesDossierV2Store?.companyFullDossierV2?.mainInfo

      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }

      if (!startFromEcoSpend && !this.verificateBankAccountRes.requestSent) {
        await this.changeBankDetailsStartValidations(formData)
        if (this.showOpenBankingVerification && this.document.length === 0) {
          return
        }
      }

      const changeBankDetailsReqData = this.generateChangeBankDetailsData(formData, dsrId, needRestrictionChecks, email)
      const { status, error, result } = await startProcessChangeBankDetailsDossierV2(changeBankDetailsReqData)

      if (status !== 200 || error) {
        message.error(error.message || translations().requestFail)
        this.setChangeBankDetailsRes(1, error.message || translations().errChangingBankDetals)
        this.clearMessage()
      } else {
        this.bankDetailsProcessId = result?.id
        message.success(result.message || translations().applicationSuccessful)
        this.setChangeBankDetailsRes(0, result.message || translations().applicationSuccessful)
        if (startFromEcoSpend) this.showEcospendFinalSuccessMessage = true
      }
    } catch (error) {
      this.setChangeBankDetailsRes(1, error.message || error || translations().errChangingBankDetals)
      log(error)
    } finally {
      runInAction(() => {
        this.isChangeBankDetailsLoading = false
      })
    }
  }
}
