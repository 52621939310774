import { DNAIoCInit, register } from 'dna-react-ioc'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { HandbooksStoreInjectable } from '~/code/stores/HandbooksStoreInjectable'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'
import { SettlementsPaymentsStore } from '~/code/stores/SettlementsPaymentsStore/SettlementsPaymentsStore'
import { MerchantsManagementStore } from '../stores/MerchantsManagementStore/MerchantsManagementStore'
import { MotoVtStore } from '~/code/stores/MerchantsManagementStore/MotoVtStore'
import { IMotoVtStore } from '../pages/MerchantsManagement/components/MotoVT/IMotoVtStore'
import { MotoVtStoreSymbol, RiskLimitsStoreSymbol } from '../pages/MerchantsManagement/components'
import { ChangeRiskLimitsStore } from '../stores/MerchantsManagementStore/ChangeRiskLimitsStore'
import { StartChangeRiskLimitsStore } from '../stores/MerchantsManagementStore/StartChangeRiskLimitsStore'
import { SettlementsPaymentsTableContainerStoreSymbol } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/SettlementsPaymentsTableContainer'
import { ISettlementsPaymentsTableContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/ISettlementsPaymentsTableContainerStore'
import { SettlementsPaymentsTableContainerStore } from '~/code/stores/SettlementsPaymentsStore/SettlementsPaymentsTableContainerStore'
import { DrawerTitleStore } from '../stores/SubmittedRequestsStore/DrawerTitleStore'
import { SubmittedRequestsStore } from '../stores/SubmittedRequestsStore/SubmittedRequestsStore'
import { MotoVtRequestsStore } from '~/code/stores/MotoVtRequestsStore/MotoVtRequestsStore'
import {
  RiskLimitsMerchantSelectStoreSymbol,
  RiskLimitsRangePickerStoreSymbol
} from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/components'
import { StoresDossierV2Store } from '../stores/MerchantsManagementStore/StoresDossierV2Store'
import { IStoresDossierV2Store } from '../pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '../pages/MerchantsManagement/components/Stores'
import { ContractStore } from '../stores/MerchantsManagementStore/ContractStore'
import { IContractStore } from '../pages/MerchantsManagement/components/Stores/components/Contract/IContractStore'
import { ContractStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/Contract'
import { ChangeBankDetailsDossierV2Store } from '../stores/ChangeBankDetailsDossierV2Store/ChangeBankDetailsDossierV2Store'
import { CloseMerchantStore } from '../stores/MerchantsManagementStore/CloseMerchantStore'
import { ClosureOfMerchantStore } from '../stores/ClosureOfMerchantStore/ClosureOfMerchantStore'
import { ClosureOfTerminalStore } from '../stores/ClosureOfTerminalStore/ClosureOfTerminalStore'
import { ICloseMerchantStore } from '../pages/MerchantsManagement/components/ClosureOfMerchant/ICloseMerchantStore'
import { IClosureOfMerchantStore } from '../pages/BPMProcesses/Requests/components/ClosureOfMerchant/IClosureOfMerchantStore'
import {
  ClosureOfMerchantMerchantSelectStoreSymbol,
  ClosureOfMerchantRangePickerStoreSymbol,
  ClosureOfMerchantStoreSymbol
} from '../pages/BPMProcesses/Requests/components/ClosureOfMerchant'
import {
  ClosureOfTerminalMerchantSelectStoreSymbol,
  ClosureOfTerminalRangePickerStoreSymbol,
  ClosureOfTerminalStoreSymbol
} from '../pages/BPMProcesses/Requests/components/ClosureOfTerminal'
import { TariffsTableStore } from '../stores/MerchantsManagementStore/TariffsTableStore'
import { TariffsTableStoreType } from '../pages/MerchantsManagement/components/Stores/components/TariffsTable/TariffsTableStoreType'
import { TariffsTableStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/TariffsTable'
import { ChangeOfSettlementKindStore } from '../stores/ChangeOfSettlementKindStore/ChangeOfSettlementKindStore'
import {
  ChangeOfSettlementKindStoreSymbol,
  ChangeOfSettlementKindRangePickerStoreSymbol,
  ChangeOfSettlementKindMerchantSelectStoreSymbol
} from '../pages/BPMProcesses/Requests/components/ChangeOfSettlementKind'
import { BalancesStore } from '../stores/MerchantsManagementStore/BalancesStore'
import { IBalancesStore } from '../pages/MerchantsManagement/components/Balances/IBalancesStore'
import { BalancesStoreSymbol } from '../pages/MerchantsManagement/components/Balances'
import { SalesForceSearchStore } from '../stores/MerchantsManagementStore/SalesForceSearchStore'
import { ISalesForceSearchStore } from '../pages/MerchantsManagement/components/SalesForceSearch/ISalesForceSearchStore'
import { SalesForceSearchStoreSymbol } from '../pages/MerchantsManagement/components/SalesForceSearch'
import {
  ChangeOfSettlementsStatusStoreSymbol,
  ChangeOfSettlementsStatusRangePickerStoreSymbol,
  ChangeOfSettlementsStatusMerchantSelectStoreSymbol
} from '../pages/BPMProcesses/Requests/components/ChangeOfSettlementsStatus'
import {
  AdjustmentRequestsStoreSymbol,
  AdjustmentRequestsRangePickerStoreSymbol,
  AdjustmentRequestsMerchantSelectStoreSymbol
} from '~/code/pages'
import { AdjustmentRequestsStore } from '../stores/AdjustmentRequestsStore/AdjustmentRequestsStore'

import {
  StatisticsRangePickerStoreSymbol,
  StatisticsStoreSymbol,
  MerchantsManagementStoreSymbol,
  MotoVtRequestsMerchantSelectStoreSymbol,
  MotoVtRequestsRangePickerStoreSymbol,
  MotoVtRequestsStoreSymbol,
  StatisticsFiltersStoreSymbol,
  TerminalIssueRequestsMerchantSelectStoreSymbol,
  TerminalIssueRequestsRangePickerStoreSymbol,
  TerminalIssueRequestsStoreSymbol,
  EditTariffsRequestsStoreSymbol,
  EditTariffsRequestsRangePickerStoreSymbol,
  EditTariffsRequestsMerchantSelectStoreSymbol,
  ExportsStoreSymbol
} from '../pages'
import {
  CloseMerchantStoreSymbol,
  MerchantsManagementSelectStoreSymbol,
  TerminalIssueStoreSymbol
} from '../pages/MerchantsManagement/components'
import {
  IBPMProcessesStore,
  IBPMProcessesTerminalIssueStoreDossierV2,
  IBPMProcessesEditTariffsStoreDossierV2
} from '../pages/BPMProcesses/Requests/models'
import {
  ChangeBankDetailsDossierV2MerchantSelectStoreSymbol,
  ChangeBankDetailsDossierV2RangePickerStoreSymbol,
  ChangeBankDetailsDossierV2StoreSymbol
} from '../pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2'
import { IChangeBankDetailsDossierV2Store } from '../pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/IChangeBankDetailsDossierV2Store'
import { ChangeBankDetailsDrawerTitleStore } from '../stores/ChangeBankDetailsDossierV2Store/ChangeBankDetailsDrawerTitleStore'
import { ChangeBankDetailsDrawerTitleStoreSymbol } from '../pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/components/ChangeBankDetailsDrawer'
import { IChangeBankDetailsDrawerTitleStore } from '../pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/components/ChangeBankDetailsDrawer/IChangeBankDetailsDrawerTitleStore'
import { SettlementsTableStore } from '../stores/SettlementsStatementStore/SettlementsTableStore'
import { SettlementsFiltersStore } from '../stores/SettlementsStatementStore/SettlementsFiltersStore'
import { SettlementsTestTableStore } from '../stores/SettlementsStatementStore/SettlementsTestTableStore'
import { SettlementsTestFiltersStore } from '../stores/SettlementsStatementStore/SettlementsTestFiltersStore'
import {
  SETTLEMENT_STATEMENTS_STORE_TYPES,
  SETTLEMENT_STATEMENTS_TEST_STORE_TYPES
} from '~/code/pages/settlements/SettlementsStatement/symbols'
import { ChangeOfSettlementPeriodStore } from '~/code/stores/MerchantsManagementStore/ChangeOfSettlementPeriodStore'
import {
  ChangeOfSettlementPeriodRequestStore,
  ChangeOfSettlementPeriodRequestStoreSymbol,
  ChangeOfSettlementsPeriodRequestsRangePickerStoreSymbol
} from '~/code/stores/ChangeOfSettlementPeriodRequestStore/ChangeOfSettlementPeriodRequestStore'
import { SettlementPeriodDrawerTitleStore } from '~/code/stores/ChangeOfSettlementPeriodRequestStore/SettlementPeriodDrawerTitleStore'
import { IChangeOfSettlementPeriodRequestStore } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/models/IChangeOfSettlementPeriodRequestStore'
import { ChangeOfSettlementPeriodStoreType } from '../pages/MerchantsManagement/components/ChangeOfSettlementPeriod/ChangeOfSettlementPeriodStoreType'
import { ChangeOfSettlementPeriodStoreSymbol } from '../pages/MerchantsManagement/components'
import { MerchantSelectStore as ManagementMerchantSelectStore } from '../stores/MerchantsManagementStore/MerchantSelectStore'
import { SettlementPeriodRequestsMerchantSelectStoreSymbol } from '../pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/components'
import { AcquiringFilterStore } from '../pages/Acquiring/components/AcquiringFilter/AcquiringFilterStore'
import { AnalyticsStore } from '../stores/AnalyticsStore/AnalyticsStore'
import { AnalyticsPosStore } from '../stores/AnalyticsStore/AnalyticsPosStore'
import { IAcquiringFilterStore, TransactionsStatementStore } from '../pages/Acquiring'
import { MonitoringPosTransactionStore } from '../stores/MonitoringPosTransactionStore/MonitoringPosTransactionStore'
import { ITransactionStatementStore } from '../pages/Acquiring/pages/Statement/models'
import { MonitoringStoreSymbol } from '../pages/Acquiring/pages/Statement'
import { MerchantSelectStore } from '../stores/MerchantSelectStore/MerchantSelectStore'
import { IMerchantSelectStore } from '../components/MerchantSelect/models'
import { AcquiringFilterStoreSymbol, AnalyticsStoreSymbol, MerchantSelectStoreSymbol } from '../stores/RouterStore'
import { GlobalConfigStore } from '../stores/GlobalConfigStore'
import { IStatisticsStore } from '../pages/BPM/Statistics/models'
import { TerminalIssueStore } from '~/code/stores/MerchantsManagementStore/TerminalIssueStore'
import { TerminalIssueRequestsStore } from '~/code/stores/TerminalIssueRequestsStore/TerminalIssueRequestsStore'
import { EditTariffsRequestsStore } from '~/code/stores/EditTariffsRequestsStore/EditTariffsRequestsStore'
import { StatisticsFiltersStore, StatisticsStore } from '../stores/StatisticsStore'
import { TeammatesStoreSymbol, TTeammatesStore } from '../pages/Teammates'
import { TeammatesStore } from '../stores/TeammatesStore'
import { ITerminalIssueStore } from '../pages/MerchantsManagement/components/TerminalIssue/ITerminalIssueStore'
import { PortalAccessStore } from '~/code/stores/PortalAccessStore'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'

import { PartnerAccessStore } from '~/code/stores/PartnerAccessStore/PartnerAccessStore'
import { IPartnerAccessStore, PartnerAccessStoreSymbol } from '~/code/pages/PartnerAccess'

import { PartnerAccessFormStore } from '~/code/stores/PartnerAccessStore/PartnerAccessFormStore'
import {
  IPartnerAccessFormStore,
  PartnerAccessFormStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'

import { PartnerAccessTableStore } from '~/code/stores/PartnerAccessStore/PartnerAccessTableStore'
import {
  IPartnerAccessTableStore,
  PartnerAccessTableStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessTable'

import { PartnerAccessFilterStore } from '~/code/stores/PartnerAccessStore/PartnerAccessFilterStore'
import {
  IPartnerAccessFilterStore,
  PartnerAccessFilterStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessFilter'

import { ITotalAnalyticsStore } from '~/code/pages/Acquiring/pages/TotalAnalytics'
import { TotalAnalyticsStore } from '~/code/stores/AnalyticsStore/TotalAnalyticsStore'
import { ChangeBankDetailsModalStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal'
import { IChangeBankDetailsModalStore } from '../pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/IChangeBankDetailsModalStore'
import { ChangeBankDetailsModalStore } from '../stores/MerchantsManagementStore/ChangeBankDetailsModalStore'
import {
  ChargeBacksRangePickerStoreSymbol,
  ChargebacksStoreSymbol,
  MerchantSelectStoreChargebacksSymbol
} from '~/code/pages/Chargebacks'
import { ChargebacksStore } from '~/code/stores/ChargebacksStore/ChargebacksStore'
import { ChargebacksStoreType } from '~/code/pages/Chargebacks/types/ChargebacksStoreType'
import { ClosureOfTerminalDossierV2StoreSymbol } from '../pages/MerchantsManagement/components'
import { IClosureOfTerminalDossierV2Store } from '../pages/MerchantsManagement/components/ClosureOfTerminal/ClosureOfTerminalDossierV2StoreType'
import { ClosureOfTerminalDossierV2Store } from '../stores/MerchantsManagementStore/ClosureOfTerminalDossierV2Store'
import { IRiskLimitsStore } from '../pages/MerchantsManagement/components/RiskLimits/IRiskLimitsStore'
import { RiskLimitsStore } from '../stores/MerchantsManagementStore/RiskLimitsStore'
import { StartChangeRiskLimitsStoreSymbol } from '../pages/MerchantsManagement/components/RiskLimits/components/StartChangeRiskLimits/StartChangeRiskLimits'
import { ChangeRiskLimitsStoreSymbol } from '../pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/ChangeRiskLimitsModal'
import { ExportsStore } from '../stores/ExportsStore/ExportsStore'
import { ExportsStoreType } from '../pages/Exports/ExportsStoreType'
import { IOfficersStore } from '../pages/MerchantsManagement/components/MerchantApplication/components/Officers/IOfficersStore'
import { OfficersStore } from '../stores/MerchantsManagementStore/OfficersStore'
import {
  InvoicesFilterStoreSymbol,
  MerchantSelectStoreInvoicesSymbol,
  RangePickerInvoicesStoreSymbol
} from '~/code/pages/Billing/pages/Invoices/components'
import { InvoicesFilterStore } from '~/code/stores/invoicesStore/InvoicesFilterStore'
import { IInvoicesFilterStore } from '~/code/pages/Billing/pages/Invoices/components/InvoicesFilter'
import { InvoicesStore } from '~/code/stores/invoicesStore/InvoicesStore'
import { InvoicesStoreSymbol } from '~/code/pages/Billing'
import { IInvoicesStore } from '~/code/pages/Billing/pages/Invoices/IInvoicesStore'
import {
  NetSuiteInvoicesFilterStoreSymbol,
  RangePickerNetSuiteInvoicesStoreSymbol
} from '~/code/pages/Billing/pages/NetSuiteInvoices/components'
import { NetSuiteInvoicesFilterStore } from '~/code/stores/NetSuiteInvoicesStore'
import { INetSuiteInvoicesFilterStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/components/NetSuiteInvoicesFilter/INetSuiteInvoicesFilterStore'
import { NetSuiteInvoicesStore } from '~/code/stores/NetSuiteInvoicesStore'
import { NetSuiteInvoicesStoreSymbol } from '~/code/pages/Billing'
import { INetSuiteInvoicesStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/INetSuiteInvoicesStore'
import { MotoVtRequestsDrawerTitleStore } from '../stores/MotoVtRequestsStore/MotoVtRequestsDrawerTitleStore'
import { MotoVtRequestsDrawerTitleStoreSymbol } from '../pages/BPMProcesses/Requests/components/MotoVtRequests/components/MotoVtRequestsDrawerTitle'
import { StatementExportModalStore } from '~/code/stores/TransactionStatementStore/StatementExportsModalStore'
import { StatementExportsModalStoreSymbol } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModal'
import { StatementExportsModalStoreType } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModalStoreType'
import { MonitoringPosStoreSymbol, MonitoringDnaPosStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPos'
import {
  MonitoringPosExportsModalStoreSymbol,
  TMonitoringPosExportsModalStore
} from '~/code/pages/Acquiring/pages/MonitoringPos/components'
import { ISettlementsStatusModalStore } from '../pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/ISettlementsStatusModalStore'
import { SettlementsStatusModalStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal'
import { SettlementsStatusModalStore } from '../stores/MerchantsManagementStore/SettlementsStatusModalStore'
import { ISettlementsStatusTableStore } from '../pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/ISettlementsStatusTableStore'
import { SettlementsStatusTableStore } from '../stores/MerchantsManagementStore/SettlementsStatusTableStore'
import { ISettlementsStatusSwitchStore } from '../pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/components/SettlementsStatusSwitch/ISettlementsStatusSwitchStore'
import { SettlementsStatusSwitchStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/components/SettlementsStatusSwitch'
import { SettlementsStatusSwitchStore } from '../stores/MerchantsManagementStore/SettlementsStatusSwitchStore'
import { ChangeOfSettlementsStatusStore } from '../stores/ChangeOfSettlementsStatusStore/ChangeOfSettlementsStatusStore'
import { ProfileStoreInterface } from '~/code/pages/Profile'
import { ProfileStoreSymbol } from '~/code/pages/Profile/Profile'
import { ProfileStore } from '../stores/Profile/ProfileStore'
import { TwoFAStoreInterface, TwoFAStoreSymbol } from '~/code/pages/Profile/components/TwoFA'
import { TwoFAStore } from '~/code/stores/Profile/TwoFAStore'
import { TwoFAModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'
import { TwoFAModalStoreInterface } from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import { TwoFAModalStore } from '~/code/stores/Profile/TwoFAModalStore'
import { TwoFAEmailConfirmationModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAEmailConfirmationModal'
import { TwoFAEmailConfirmationModalStoreInterface } from '~/code/stores/Profile/TwoFAEmailConfirmationModalStore/TwoFAEmailConfirmationModalStoreInterface'
import { TwoFAEmailConfirmationModalStore } from '~/code/stores/Profile/TwoFAEmailConfirmationModalStore'
import { TwoFADevicesStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFADevices'
import { TwoFADevicesStoreInterface } from '~/code/stores/Profile/TwoFADevicesStore/TwoFADevicesStoreInterface'
import { TwoFADevicesStore } from '~/code/stores/Profile/TwoFADevicesStore'
import { MonitoringPosExportsModalStore } from '~/code/stores/MonitoringPosTransactionStore/MonitoringPosExportsModalStore'
import { IRoutingDataStore } from '../pages/MerchantsManagement/components/MerchantApplication/components/RoutingTable/IRoutingDataStore'
import {
  OfficersStoreSymbol,
  RoutingDataStoreSymbol
} from '../pages/MerchantsManagement/components/MerchantApplication/components'
import { RoutingDataStore } from '../stores/MerchantsManagementStore/RoutingDataStore'
import { RealTimeTransactionMapStore } from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionMapStore'
import { RealTimeTransactionMapStoreSymbol } from '~/code/pages/RealTimeTransaction/components'
import { RealTimeTransactionMapStoreInterface } from '~/code/pages/RealTimeTransaction/components/RealTimeTransactionMap/RealTimeTransactionMapStoreInterface'
import {
  RealTimeTransactionWebsocketStore,
  RealTimeTransactionWebsocketStoreSymbol,
  RealTimeTransactionWebsocketStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionWebsocketStore/'
import {
  RealTimeTransactionDataStore,
  RealTimeTransactionDataStoreSymbol,
  RealTimeTransactionDataStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { IEditTariffsModalStore } from '../pages/MerchantsManagement/components/Stores/components/EditTariffsModal/IEditTariffsModalStore'
import { EditTariffsModalStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/EditTariffsModal'
import { EditTariffsModalStore } from '../stores/MerchantsManagementStore/EditTariffsModalStore'
import { IEditTariffsTableStore } from '../pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsTable/IEditTariffsTableStore'
import { EditTariffsTableStore } from '../stores/MerchantsManagementStore/EditTariffsTableStore'
import { ITipJarModalStore } from '../pages/MerchantsManagement/components/Stores/components/TipJarModal/ITipJarModalStore'
import { TipJarModalStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/TipJarModal'
import { TipJarModalStore } from '../stores/MerchantsManagementStore/TipJarModalStore'
import { ICloseStoreModalStore } from '../pages/MerchantsManagement/components/Stores/components/CloseStoreModal/ICloseStoreModalStore'
import { CloseStoreModalStore } from '../stores/MerchantsManagementStore/CloseStoreModalStore'
import { CloseStoreModalStoreSymbol } from '../pages/MerchantsManagement/components/Stores/components/CloseStoreModal/CloseStoreModal'
import { MonitoringPosAmexStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosAmex'
import { MonitoringPosAmexTransactionStore } from '~/code/stores/MonitoringPosAmexTransactionStore/MonitoringPosAmexTransactionStore'
import { MonitoringPosAmexExportsModalStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosAmex/components'
import { MonitoringPosAmexExportsModalStore } from '~/code/stores/MonitoringPosAmexTransactionStore/MonitoringPosAmexExportsModalStore'
import { MonitoringPosTestStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosTest'
import { MonitoringPosTestTransactionStore } from '~/code/stores/MonitoringPosTestTransactionStore/MonitoringPosTestTransactionStore'
import { MonitoringPosTestExportsModalStore } from '~/code/stores/MonitoringPosTestTransactionStore/MonitoringPosTestExportsModalStore'
import { MonitoringPosTestExportsModalStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosTest/components'
import {
  ISettlementsFilterStore,
  ISettlementsTableStore
} from '~/code/pages/settlements/SettlementsStatement/components'
import {
  ISettlementsPaymentsStore,
  MerchantSelectStoreSettlementsPaymentsSymbol,
  SettlementsPaymentsRangePickerStoreSymbol,
  SettlementsPaymentsStoreSymbol
} from '~/code/pages/settlements'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components'
import { IViewAttributesModalStore } from '../pages/MerchantsManagement/components/Stores/components/ViewAttributesModal/IViewAttributesModal'
import { ViewAttributesModalStore } from '../stores/MerchantsManagementStore/ViewAttributesModalStore'
import { IFinancialInformationStore } from '../pages/MerchantsManagement/components/FinancialInformation/IFinancialInformationStore'
import { FinancialInformationStoreSymbol } from '../pages/MerchantsManagement/components'
import { FinancialInformationStore } from '../stores/MerchantsManagementStore/FinancialInformationStore'
import { ChangeSettlementTypeStore } from '../stores/MerchantsManagementStore/ChangeSettlementTypeStore'
import { ChangeSettlementTypeStoreSymbol } from '../pages/MerchantsManagement/components'
import { IChangeSettlementTypeStore } from '../pages/MerchantsManagement/components/ChangeSettlementStatus/IChangeSettlementTypeStore'
import { PartnerCommissionsStoreType } from '../pages/PartnerCommissions/types/PartnerCommissionsStoreType'

import { PartnerCommissionStoreSymbol } from '../pages/PartnerCommissions'
import { ManageNotificationTemplateStore, NotificationTemplatesStore } from '~/code/stores/NotificationManagementStore'
import {
  IManageNotificationTemplateStore,
  IPublishNotificationsStore,
  ManageNotificationsStoreSymbol,
  NotificationTemplatesStoreSymbol,
  PublishNotificationStoreSymbol
} from '~/code/pages/Notifications'
import { PublishNotificationStore } from '~/code/stores/NotificationManagementStore/PublishNotificationsStore'
import { IManageNotificationsStore } from '~/code/pages/Notifications/ManageNotifications/IManageNotificationsStore'
import { ManageNotificationsStore } from '~/code/stores/NotificationManagementStore/ManageNotificationsStore'
import { ManageNotificationTemplateStoreSymbol } from '~/code/pages/Notifications/ManageNotificationTemplates/components'
import { IAnalyticsStore } from '~/code/pages/Acquiring/pages/Analytics'
import { SettlementsStatusTableStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable'
import { EditTariffsTableStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/EditTariffsModal/components/EditTariffsTable'
import { ViewAttributesModalStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/ViewAttributesModal'
import { PartnerCommissionsStore } from '~/code/stores/PartnerCommissionsStore'

export function iocInit() {
  /**
   * Initialize our IoC container
   */
  DNAIoCInit()

  /**
   * Register stores in IoC container
   */
  const handbooksStore = new HandbooksStoreInjectable()
  register<HandbooksStoreInjectable>(STORE_TYPES.HandbooksStore).toConstantValue(handbooksStore)

  register<RangePickerStoreInjectable>(STORE_TYPES.RangePickerStore).to(RangePickerStoreInjectable).inSingletonScope()

  // settlements
  register<IMerchantSelectStore>(SETTLEMENT_STATEMENTS_STORE_TYPES.merchantSelect).toConstantValue(
    new MerchantSelectStore({ acquirer: 'dna' })
  )
  register<RangePickerStoreInjectable>(SETTLEMENT_STATEMENTS_STORE_TYPES.rangePicker)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ISettlementsFilterStore>(SETTLEMENT_STATEMENTS_STORE_TYPES.filter)
    .to(SettlementsFiltersStore)
    .inSingletonScope()
  register<ISettlementsTableStore>(SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.table)
    .to(SettlementsTestTableStore)
    .inSingletonScope()
  // settlements-test
  register<IMerchantSelectStore>(SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.merchantSelect).toConstantValue(
    new MerchantSelectStore({ acquirer: 'dna' })
  )
  register<RangePickerStoreInjectable>(SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.rangePicker)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ISettlementsFilterStore>(SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.filter)
    .to(SettlementsTestFiltersStore)
    .inSingletonScope()
  register<ISettlementsTableStore>(SETTLEMENT_STATEMENTS_STORE_TYPES.table).to(SettlementsTableStore).inSingletonScope()

  // settlements payments stores
  register<IMerchantSelectStore>(MerchantSelectStoreSettlementsPaymentsSymbol).toConstantValue(
    new MerchantSelectStore({ acquirer: 'dna' })
  )
  register<ISettlementsPaymentsStore>(SettlementsPaymentsStoreSymbol).to(SettlementsPaymentsStore).inSingletonScope()
  register<IRangePickerContainerStore>(SettlementsPaymentsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ISettlementsPaymentsTableContainerStore>(SettlementsPaymentsTableContainerStoreSymbol)
    .to(SettlementsPaymentsTableContainerStore)
    .inSingletonScope()

  // merchants management stores
  register<MerchantsManagementStore>(MerchantsManagementStoreSymbol).to(MerchantsManagementStore).inSingletonScope()
  register<StartChangeRiskLimitsStore>(StartChangeRiskLimitsStoreSymbol).to(StartChangeRiskLimitsStore).inRequestScope()
  register<ChangeRiskLimitsStore>(ChangeRiskLimitsStoreSymbol).to(ChangeRiskLimitsStore).inSingletonScope()
  register<IMotoVtStore>(MotoVtStoreSymbol).to(MotoVtStore).inSingletonScope()
  register<IStoresDossierV2Store>(StoresDossierV2StoreSymbol).to(StoresDossierV2Store).inSingletonScope()
  register<IChangeBankDetailsModalStore>(ChangeBankDetailsModalStoreSymbol)
    .to(ChangeBankDetailsModalStore)
    .inSingletonScope()
  register<IContractStore>(ContractStoreSymbol).to(ContractStore).inSingletonScope()
  const merchantsManagementSelectStore = new ManagementMerchantSelectStore(false)
  register<ManagementMerchantSelectStore>(MerchantsManagementSelectStoreSymbol).toConstantValue(
    merchantsManagementSelectStore
  )
  register<TariffsTableStoreType>(TariffsTableStoreSymbol).to(TariffsTableStore).inSingletonScope()
  register<IBalancesStore>(BalancesStoreSymbol).to(BalancesStore).inSingletonScope()
  register<ISalesForceSearchStore>(SalesForceSearchStoreSymbol).to(SalesForceSearchStore).inSingletonScope()

  // bpm processes merchant select stores
  const bpmMerchantSelectStores = {
    riskLimits: new ManagementMerchantSelectStore(true),
    motoVtRequests: new ManagementMerchantSelectStore(true),
    terminalIssueRequests: new ManagementMerchantSelectStore(true),
    changeBankDetailsDossierV2: new ManagementMerchantSelectStore(true),
    closeMerchant: new ManagementMerchantSelectStore(true),
    closeTerminal: new ManagementMerchantSelectStore(true),
    settlementPeriodRequests: new ManagementMerchantSelectStore(true),
    changeSettlementKind: new ManagementMerchantSelectStore(true),
    changeOfSettlementsStatus: new ManagementMerchantSelectStore(true),
    adjustmentRequests: new ManagementMerchantSelectStore(true),
    editTariffsRequests: new ManagementMerchantSelectStore(true)
  }

  // submitted requests store
  register<DrawerTitleStore>(STORE_TYPES.DrawerTitleStore).to(DrawerTitleStore).inSingletonScope()
  register<SubmittedRequestsStore>(STORE_TYPES.SubmittedRequestsStore).to(SubmittedRequestsStore).inSingletonScope()
  register<IRangePickerContainerStore>(RiskLimitsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(RiskLimitsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.riskLimits
  )

  // moto vt requests store
  register<MotoVtRequestsStore>(MotoVtRequestsStoreSymbol).to(MotoVtRequestsStore).inSingletonScope()
  register<IRangePickerContainerStore>(MotoVtRequestsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(MotoVtRequestsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.motoVtRequests
  )
  register<MotoVtRequestsDrawerTitleStore>(MotoVtRequestsDrawerTitleStoreSymbol)
    .to(MotoVtRequestsDrawerTitleStore)
    .inSingletonScope()

  // terminal issue requests store
  register<IBPMProcessesTerminalIssueStoreDossierV2>(TerminalIssueRequestsStoreSymbol)
    .to(TerminalIssueRequestsStore)
    .inSingletonScope()
  register<IRangePickerContainerStore>(TerminalIssueRequestsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(TerminalIssueRequestsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.motoVtRequests
  )

  // edit tariffs requests store
  register<IBPMProcessesEditTariffsStoreDossierV2>(EditTariffsRequestsStoreSymbol)
    .to(EditTariffsRequestsStore)
    .inSingletonScope()
  register<IRangePickerContainerStore>(EditTariffsRequestsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(EditTariffsRequestsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.editTariffsRequests
  )

  // change bank details dossier v2 store
  register<IChangeBankDetailsDossierV2Store>(ChangeBankDetailsDossierV2StoreSymbol)
    .to(ChangeBankDetailsDossierV2Store)
    .inSingletonScope()
  register<IRangePickerContainerStore>(ChangeBankDetailsDossierV2RangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(ChangeBankDetailsDossierV2MerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.changeBankDetailsDossierV2
  )
  register<IChangeBankDetailsDrawerTitleStore>(ChangeBankDetailsDrawerTitleStoreSymbol)
    .to(ChangeBankDetailsDrawerTitleStore)
    .inSingletonScope()

  // adjustment request
  register<IBPMProcessesStore>(AdjustmentRequestsStoreSymbol).to(AdjustmentRequestsStore).inSingletonScope()
  register<IRangePickerContainerStore>(AdjustmentRequestsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(AdjustmentRequestsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.adjustmentRequests
  )

  // close merchant terminal store
  register<ICloseMerchantStore>(CloseMerchantStoreSymbol).to(CloseMerchantStore).inSingletonScope()
  register<IClosureOfMerchantStore>(ClosureOfMerchantStoreSymbol).to(ClosureOfMerchantStore).inSingletonScope()
  register<IRangePickerContainerStore>(ClosureOfMerchantRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(ClosureOfMerchantMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.closeMerchant
  )
  register<IBPMProcessesStore>(ClosureOfTerminalStoreSymbol).to(ClosureOfTerminalStore).inSingletonScope()
  register<IRangePickerContainerStore>(ClosureOfTerminalRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(ClosureOfTerminalMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.closeTerminal
  )
  register<IClosureOfTerminalDossierV2Store>(ClosureOfTerminalDossierV2StoreSymbol)
    .to(ClosureOfTerminalDossierV2Store)
    .inSingletonScope()

  // Change Risk Limits
  register<IRiskLimitsStore>(RiskLimitsStoreSymbol).to(RiskLimitsStore).inSingletonScope()

  // ChangeOfSettlementPeriodRequestStore
  register<ChangeOfSettlementPeriodStoreType>(ChangeOfSettlementPeriodStoreSymbol)
    .to(ChangeOfSettlementPeriodStore)
    .inSingletonScope()
  register<IChangeOfSettlementPeriodRequestStore>(ChangeOfSettlementPeriodRequestStoreSymbol)
    .to(ChangeOfSettlementPeriodRequestStore)
    .inSingletonScope()
  register<IRangePickerContainerStore>(ChangeOfSettlementsPeriodRequestsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<SettlementPeriodDrawerTitleStore>(STORE_TYPES.SettlementPeriodDrawerTitleStoreSymbol)
    .to(SettlementPeriodDrawerTitleStore)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(SettlementPeriodRequestsMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.settlementPeriodRequests
  )

  // ChangeOfSettlementKindStore
  register<IBPMProcessesStore>(ChangeOfSettlementKindStoreSymbol).to(ChangeOfSettlementKindStore).inSingletonScope()
  register<IRangePickerContainerStore>(ChangeOfSettlementKindRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(ChangeOfSettlementKindMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.changeSettlementKind
  )

  // OfficersStore
  register<IOfficersStore>(OfficersStoreSymbol).to(OfficersStore).inSingletonScope()

  // SettlementStatusModalStore
  register<ISettlementsStatusModalStore>(SettlementsStatusModalStoreSymbol)
    .to(SettlementsStatusModalStore)
    .inSingletonScope()

  // SettlementsStatusTableStore
  register<ISettlementsStatusTableStore>(SettlementsStatusTableStoreSymbol)
    .to(SettlementsStatusTableStore)
    .inSingletonScope()

  // SettlementsStatusSwitchStore
  register<ISettlementsStatusSwitchStore>(SettlementsStatusSwitchStoreSymbol)
    .to(SettlementsStatusSwitchStore)
    .inSingletonScope()

  // RoutingDataStore
  register<IRoutingDataStore>(RoutingDataStoreSymbol).to(RoutingDataStore).inSingletonScope()

  // EditTariffsModalStore
  register<IEditTariffsModalStore>(EditTariffsModalStoreSymbol).to(EditTariffsModalStore).inSingletonScope()

  // EditTariffsTableStore
  register<IEditTariffsTableStore>(EditTariffsTableStoreSymbol).to(EditTariffsTableStore).inSingletonScope()

  // TipJarStore
  register<ITipJarModalStore>(TipJarModalStoreSymbol).to(TipJarModalStore).inSingletonScope()

  // CloseStoreModalStore
  register<ICloseStoreModalStore>(CloseStoreModalStoreSymbol).to(CloseStoreModalStore).inSingletonScope()

  // ViewAttributesModalStore
  register<IViewAttributesModalStore>(ViewAttributesModalStoreSymbol).to(ViewAttributesModalStore).inSingletonScope()

  // FinancialInformationStore
  register<IFinancialInformationStore>(FinancialInformationStoreSymbol).to(FinancialInformationStore).inSingletonScope()

  // ChangeSettlementStatusStore
  register<IChangeSettlementTypeStore>(ChangeSettlementTypeStoreSymbol).to(ChangeSettlementTypeStore).inSingletonScope()

  register<IRangePickerContainerStore>(ChangeOfSettlementsStatusRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ManagementMerchantSelectStore>(ChangeOfSettlementsStatusMerchantSelectStoreSymbol).toConstantValue(
    bpmMerchantSelectStores.changeOfSettlementsStatus
  )
  register<IBPMProcessesStore>(ChangeOfSettlementsStatusStoreSymbol)
    .to(ChangeOfSettlementsStatusStore)
    .inSingletonScope()
  // merchant select
  const merchantSelectStores = {
    acquiring: {
      analytics: {
        dnaTotal: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: null }),
        dnaEcom: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'ecom' }),
        dnaPos: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'pos' }),
        optomanyEcom: new MerchantSelectStore({ acquirer: 'optomany', merchantProduct: 'ecom' })
      },
      monitoring: {
        dnaEcom: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'ecom' }),
        dnaPos: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'pos' }),
        dnaPosTest: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'pos' }),
        dnaPosAmex: new MerchantSelectStore({ acquirer: 'dna', merchantProduct: 'pos' }),
        optomanyEcom: new MerchantSelectStore({ acquirer: 'optomany', merchantProduct: 'ecom' })
      }
    },
    chargeBacks: new MerchantSelectStore({ acquirer: 'dna' }),
    invoices: new MerchantSelectStore({ acquirer: 'dna' })
  }

  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.analytics.dnaTotal).toConstantValue(
    merchantSelectStores.acquiring.analytics.dnaTotal
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.analytics.dnaEcom).toConstantValue(
    merchantSelectStores.acquiring.analytics.dnaEcom
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.analytics.dnaPos).toConstantValue(
    merchantSelectStores.acquiring.analytics.dnaPos
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.analytics.optomanyEcom).toConstantValue(
    merchantSelectStores.acquiring.analytics.optomanyEcom
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.monitoring.dnaEcom).toConstantValue(
    merchantSelectStores.acquiring.monitoring.dnaEcom
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.monitoring.dnaPos).toConstantValue(
    merchantSelectStores.acquiring.monitoring.dnaPos
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.monitoring.dnaPosTest).toConstantValue(
    merchantSelectStores.acquiring.monitoring.dnaPosTest
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.monitoring.dnaPosAmex).toConstantValue(
    merchantSelectStores.acquiring.monitoring.dnaPosAmex
  )
  register<IMerchantSelectStore>(MerchantSelectStoreSymbol.monitoring.optomanyEcom).toConstantValue(
    merchantSelectStores.acquiring.monitoring.optomanyEcom
  )

  register<IMerchantSelectStore>(MerchantSelectStoreInvoicesSymbol).toConstantValue(merchantSelectStores.invoices)

  // analytics
  const acquiringFilterStores = {
    analytics: {
      dnaEcom: new AcquiringFilterStore({
        acquiringTab: 'analytics',
        acquiringType: 'dna',
        acquiringEcomPosType: 'ecom',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.analytics.dnaEcom
      }),
      dnaPos: new AcquiringFilterStore({
        acquiringTab: 'analytics',
        acquiringType: 'dna',
        acquiringEcomPosType: 'pos',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.analytics.dnaPos
      }),
      optomanyEcom: new AcquiringFilterStore({
        acquiringTab: 'analytics',
        acquiringType: 'optomany',
        acquiringEcomPosType: 'ecom',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.analytics.optomanyEcom
      })
    },
    monitoring: {
      dnaEcom: new AcquiringFilterStore({
        acquiringTab: 'statement',
        acquiringType: 'dna',
        acquiringEcomPosType: 'ecom',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.monitoring.dnaEcom
      }),
      dnaPos: new AcquiringFilterStore({
        acquiringTab: 'statement',
        acquiringType: 'dna',
        acquiringEcomPosType: 'pos',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.monitoring.dnaPos
      }),
      dnaPosTest: new AcquiringFilterStore({
        acquiringTab: 'statement',
        acquiringType: 'dna',
        acquiringEcomPosType: 'pos',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.monitoring.dnaPosTest
      }),
      dnaPosAmex: new AcquiringFilterStore({
        acquiringTab: 'statement',
        acquiringType: 'dna',
        acquiringEcomPosType: 'pos',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.monitoring.dnaPosAmex
      }),
      optomanyEcom: new AcquiringFilterStore({
        acquiringTab: 'statement',
        acquiringType: 'optomany',
        acquiringEcomPosType: 'ecom',
        handbooksStore,
        merchantSelectStore: merchantSelectStores.acquiring.monitoring.optomanyEcom
      })
    }
  }

  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.analytics.dnaEcom).toConstantValue(
    acquiringFilterStores.analytics.dnaEcom
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.analytics.dnaPos).toConstantValue(
    acquiringFilterStores.analytics.dnaPos
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.analytics.optomanyEcom).toConstantValue(
    acquiringFilterStores.analytics.optomanyEcom
  )

  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.monitoring.dnaEcom).toConstantValue(
    acquiringFilterStores.monitoring.dnaEcom
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.monitoring.dnaPos).toConstantValue(
    acquiringFilterStores.monitoring.dnaPos
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.monitoring.dnaPosTest).toConstantValue(
    acquiringFilterStores.monitoring.dnaPosTest
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.monitoring.dnaPosAmex).toConstantValue(
    acquiringFilterStores.monitoring.dnaPosAmex
  )
  register<IAcquiringFilterStore>(AcquiringFilterStoreSymbol.monitoring.optomanyEcom).toConstantValue(
    acquiringFilterStores.monitoring.optomanyEcom
  )

  const globalConfigStore = new GlobalConfigStore()
  register<GlobalConfigStore>(STORE_TYPES.GlobalConfigStore).toConstantValue(globalConfigStore)
  const acquiringStores = {
    analytics: {
      dna: {
        ecom: new AnalyticsStore(acquiringFilterStores.analytics.dnaEcom, globalConfigStore, 'dna'),
        pos: new AnalyticsPosStore(acquiringFilterStores.analytics.dnaPos, globalConfigStore)
      },
      optomany: {
        ecom: new AnalyticsStore(acquiringFilterStores.analytics.optomanyEcom, globalConfigStore, 'optomany')
      }
    },
    monitoring: {
      dna: {
        ecom: new TransactionsStatementStore(
          acquiringFilterStores.monitoring.dnaEcom,
          handbooksStore,
          globalConfigStore,
          'dna'
        ),
        pos: new MonitoringPosTransactionStore(
          acquiringFilterStores.monitoring.dnaPos,
          handbooksStore,
          globalConfigStore,
          'dna'
        ),
        posTest: new MonitoringPosTestTransactionStore(
          acquiringFilterStores.monitoring.dnaPosTest,
          handbooksStore,
          globalConfigStore,
          'dna'
        ),
        posAmex: new MonitoringPosAmexTransactionStore(
          acquiringFilterStores.monitoring.dnaPosAmex,
          handbooksStore,
          globalConfigStore,
          'dna'
        )
      },
      optomany: {
        ecom: new TransactionsStatementStore(
          acquiringFilterStores.monitoring.optomanyEcom,
          handbooksStore,
          globalConfigStore,
          'optomany'
        )
      }
    }
  }

  register<ITotalAnalyticsStore>(AnalyticsStoreSymbol.dna.total).to(TotalAnalyticsStore).inSingletonScope()
  register<IAnalyticsStore>(AnalyticsStoreSymbol.dna.ecom).toConstantValue(acquiringStores.analytics.dna.ecom)
  register<IAnalyticsStore>(AnalyticsStoreSymbol.dna.pos).toConstantValue(acquiringStores.analytics.dna.pos)
  register<IAnalyticsStore>(AnalyticsStoreSymbol.optomany.ecom).toConstantValue(acquiringStores.analytics.optomany.ecom)

  register<ITransactionStatementStore>(MonitoringStoreSymbol.dna).toConstantValue(acquiringStores.monitoring.dna.ecom)
  register<ITransactionStatementStore>(MonitoringStoreSymbol.optomany).toConstantValue(
    acquiringStores.monitoring.optomany.ecom
  )
  register<StatementExportsModalStoreType>(StatementExportsModalStoreSymbol)
    .to(StatementExportModalStore)
    .inSingletonScope()

  register<ITransactionStatementStore>(MonitoringDnaPosStoreSymbol.dna).toConstantValue(
    acquiringStores.monitoring.dna.ecom
  )
  register<ITransactionStatementStore>(MonitoringPosStoreSymbol).toConstantValue(acquiringStores.monitoring.dna.pos)
  register<ITransactionStatementStore>(MonitoringPosTestStoreSymbol).toConstantValue(
    acquiringStores.monitoring.dna.posTest
  )
  register<ITransactionStatementStore>(MonitoringPosAmexStoreSymbol).toConstantValue(
    acquiringStores.monitoring.dna.posAmex
  )

  register<ITransactionStatementStore>(MonitoringDnaPosStoreSymbol.optomany).toConstantValue(
    acquiringStores.monitoring.optomany.ecom
  )
  register<TMonitoringPosExportsModalStore>(MonitoringPosExportsModalStoreSymbol)
    .to(MonitoringPosExportsModalStore)
    .inSingletonScope()
  register<TMonitoringPosExportsModalStore>(MonitoringPosTestExportsModalStoreSymbol)
    .to(MonitoringPosTestExportsModalStore)
    .inSingletonScope()
  register<TMonitoringPosExportsModalStore>(MonitoringPosAmexExportsModalStoreSymbol)
    .to(MonitoringPosAmexExportsModalStore)
    .inSingletonScope()

  register<TTeammatesStore>(TeammatesStoreSymbol).to(TeammatesStore).inSingletonScope()

  // applications statistics
  register<IRangePickerContainerStore>(StatisticsRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<StatisticsFiltersStore>(StatisticsFiltersStoreSymbol).to(StatisticsFiltersStore).inSingletonScope()
  register<IStatisticsStore>(StatisticsStoreSymbol).to(StatisticsStore).inSingletonScope()

  // Terminal Issue store
  register<ITerminalIssueStore>(TerminalIssueStoreSymbol).to(TerminalIssueStore).inSingletonScope()

  // Portal Access store
  register<PortalAccessStoreType>(PortalAccessStoreSymbol).to(PortalAccessStore).inSingletonScope()

  // Partner Access store
  register<IPartnerAccessStore>(PartnerAccessStoreSymbol).to(PartnerAccessStore).inSingletonScope()
  register<IPartnerAccessFormStore>(PartnerAccessFormStoreSymbol).to(PartnerAccessFormStore).inSingletonScope()
  register<IPartnerAccessTableStore>(PartnerAccessTableStoreSymbol).to(PartnerAccessTableStore).inSingletonScope()
  register<IPartnerAccessFilterStore>(PartnerAccessFilterStoreSymbol).to(PartnerAccessFilterStore).inSingletonScope()

  // ChargebacksStore stores
  register<IMerchantSelectStore>(MerchantSelectStoreChargebacksSymbol).toConstantValue(merchantSelectStores.chargeBacks)
  register<IRangePickerContainerStore>(ChargeBacksRangePickerStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<ChargebacksStoreType>(ChargebacksStoreSymbol).to(ChargebacksStore).inSingletonScope()

  // Exports store
  register<ExportsStoreType>(ExportsStoreSymbol).to(ExportsStore).inSingletonScope()

  // Invoices stores
  register<IInvoicesFilterStore>(InvoicesFilterStoreSymbol).to(InvoicesFilterStore).inSingletonScope()
  register<IRangePickerContainerStore>(RangePickerInvoicesStoreSymbol).to(RangePickerStoreInjectable).inSingletonScope()
  register<IInvoicesStore>(InvoicesStoreSymbol).to(InvoicesStore).inSingletonScope()

  // NetSuite Invoices stores
  register<INetSuiteInvoicesFilterStore>(NetSuiteInvoicesFilterStoreSymbol)
    .to(NetSuiteInvoicesFilterStore)
    .inSingletonScope()
  register<IRangePickerContainerStore>(RangePickerNetSuiteInvoicesStoreSymbol)
    .to(RangePickerStoreInjectable)
    .inSingletonScope()
  register<INetSuiteInvoicesStore>(NetSuiteInvoicesStoreSymbol).to(NetSuiteInvoicesStore).inSingletonScope()

  // Profile store
  register<ProfileStoreInterface>(ProfileStoreSymbol).to(ProfileStore).inSingletonScope()
  register<TwoFAStoreInterface>(TwoFAStoreSymbol).to(TwoFAStore).inSingletonScope()
  register<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol).to(TwoFAModalStore).inSingletonScope()
  register<TwoFAEmailConfirmationModalStoreInterface>(TwoFAEmailConfirmationModalStoreSymbol)
    .to(TwoFAEmailConfirmationModalStore)
    .inSingletonScope()
  register<TwoFADevicesStoreInterface>(TwoFADevicesStoreSymbol).to(TwoFADevicesStore).inSingletonScope()

  //Real-time transactions
  register<RealTimeTransactionMapStoreInterface>(RealTimeTransactionMapStoreSymbol)
    .to(RealTimeTransactionMapStore)
    .inSingletonScope()
  register<RealTimeTransactionWebsocketStoreInterface>(RealTimeTransactionWebsocketStoreSymbol)
    .to(RealTimeTransactionWebsocketStore)
    .inSingletonScope()
  register<RealTimeTransactionDataStoreInterface>(RealTimeTransactionDataStoreSymbol)
    .to(RealTimeTransactionDataStore)
    .inSingletonScope()

  //Partner commission

  register<PartnerCommissionsStoreType>(PartnerCommissionStoreSymbol).to(PartnerCommissionsStore).inSingletonScope()

  // Notification management
  register<NotificationTemplatesStore>(NotificationTemplatesStoreSymbol)
    .to(NotificationTemplatesStore)
    .inSingletonScope()

  register<IManageNotificationTemplateStore>(ManageNotificationTemplateStoreSymbol)
    .to(ManageNotificationTemplateStore)
    .inSingletonScope()

  register<IManageNotificationsStore>(ManageNotificationsStoreSymbol).to(ManageNotificationsStore).inSingletonScope()

  register<IPublishNotificationsStore>(PublishNotificationStoreSymbol).to(PublishNotificationStore).inSingletonScope()
}
