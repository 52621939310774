import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid, Tabs, Card } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import {
  MerchantSelect,
  RiskLimits,
  MerchantApplication,
  MotoVT,
  ClosureOfMerchant,
  ClosureOfTerminal,
  ChangeOfSettlementPeriod,
  TerminalIssue,
  ChangeSettlementType
} from './components'
import translations from './translations'
import styles from './MerchantsManagement.scss'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { TabType } from '~/code/models'
import { AdditionalStoreIssue } from './components/AdditionalStoreIssue'
import { MerchantHighlight } from './components/MerchantHighlight'
import { SettlementsStatusModal } from './components/Stores/components/SettlementsStatusModal'

export const MerchantsManagementStoreSymbol = Symbol('MerchantsManagementStore')

export const MerchantsManagement = observer(() => {
  const store = useInjection<MerchantsManagementStore>(MerchantsManagementStoreSymbol)
  const screen = Grid.useBreakpoint()

  // TODO: REFACTOR AppStore and RouterStore with DI
  useEffect(() => {
    store.reload()
  }, [])

  const renderHeader = (disabled: boolean = false) => {
    return (
      <div className={styles.header}>
        <MerchantSelect disabled={disabled} />
      </div>
    )
  }

  const renderCurrentRiskLimitsBlock = () => store.selectedMerchant?.id && <RiskLimits />
  const renderMotoVtBlock = () => store.selectedMerchant?.id && <MotoVT />

  const tabItems: TabType[] = [{ key: 'profile', label: translations().profile, children: <MerchantApplication /> }]

  if (hasPermissions([PermissionMap.merchants.processes.moto.read])) {
    tabItems.push({
      key: 'terminals',
      label: translations().terminals,
      children: renderMotoVtBlock(),
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.risk_limits.read])) {
    tabItems.push({
      key: 'riskLimits',
      label: translations().riskLimits,
      children: renderCurrentRiskLimitsBlock(),
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.merchant_close.read])) {
    tabItems.push({
      key: 'closureOfMerchant',
      label: translations().closureOfMerchant,
      children: <ClosureOfMerchant />
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.terminal_close.read])) {
    tabItems.push({
      key: 'closureOfTerminal',
      label: translations().closureOfTerminal,
      children: <ClosureOfTerminal />
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.settlement_period.read])) {
    tabItems.push({
      key: 'changeOfSettlementPeriod',
      label: translations().changeOfSettlementPeriod,
      children: <ChangeOfSettlementPeriod />,
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.additional_terminal.read])) {
    tabItems.push({
      key: 'terminalIssue',
      label: translations().terminalIssue,
      children: <TerminalIssue />,
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.settlement_type.read])) {
    tabItems.push({
      key: 'settlementType',
      label: translations().changeSettlementType,
      children: <ChangeSettlementType />,
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.hold_settlement.read])) {
    tabItems.push({
      key: 'holdOrReleaseSettlements',
      label: translations().holdOrReleaseSettlements,
      children: <SettlementsStatusModal />,
      disabled: store.isSubsidiary
    })
  }

  if (hasPermissions([PermissionMap.merchants.processes.additional_store.read])) {
    tabItems.push({
      key: 'additionalStore',
      label: translations().additionalStore,
      children: <AdditionalStoreIssue />,
      disabled: store.isSubsidiary
    })
  }

  return (
    <div className={styles.MerchantsManagement}>
      <>
        {renderHeader(store.isFullDossierV2Loading)}
        {store.selectedMerchant?.id && (
          <>
            {!store.isFullDossierV2Loading && (
              <div className={styles.basicInfoBlock}>
                <MerchantHighlight data={store.metchantHiglightInformation} />
              </div>
            )}
            <Card className={styles.card} loading={store.isFullDossierV2Loading}>
              <div className={styles.content}>
                <Tabs
                  defaultActiveKey='merchant'
                  tabPosition={screen.xs ? 'top' : 'left'}
                  items={tabItems}
                  onTabClick={key => store.onTabPaneClick(key)}
                />
              </div>
            </Card>
          </>
        )}
      </>
    </div>
  )
})
