import React from 'react'
import translations from './translations'
import styles from '../styles.scss'

translations.add('en', {
  title: 'Settlement terms',
  settlementPayment: 'Settlement Payment',
  perCompanyUberId: 'Per Company / UBER ID',
  settlementPaymentNote: isAdditionalStoreProcess => (
    <>
      <br />
      <div>Please note that settlement configurations has certain limitations.</div>
      <div className={styles.styledDisclaimerTitle}>
        <ul>
          {isAdditionalStoreProcess ? (
            <>
              <li>
                If Settlement Payment is by UBER ID, Settlements Account and Direct Debit Account must match those from
                existing stores
              </li>
              <li>
                If Settlement Type is Gross by UBER ID, the Direct Debit Account must match those from existing stores
              </li>
            </>
          ) : (
            <>
              <li>
                Settlement Payment per UBER ID is not available if different Settlement Accounts are linked to the
                Outlets
              </li>
              <li>
                Gross Settlement per UBER ID is not available if different Direct Debit accounts are linked to the
                Outlets
              </li>
              <li>Gross Settlement per MID is not available if Settlement Payment is set by UBER ID</li>
              <li>Gross Settlement is not available if Merchant uses only ECOMM payment solutions</li>
            </>
          )}
        </ul>
      </div>
    </>
  ),
  perOutlerMid: 'Per Outlet / MID',
  settlementType: 'Settlement Type',
  settlementTypeNote: 'Note: Gross settlements type is a subject to additional manual review and approval',
  dailyNetSettlements: 'Daily Net settlements',
  monthlyNetSettlements: 'Monthly Net settlements',
  grossSettlementsByMid: 'Gross settlements by MID level',
  grossSettlementsByUberId: 'Gross settlements by UBER ID level',
  posStandarPeriod: 'POS. Standard Period. Settlements Terms',
  posStandardPeriodNote:
    'Note: these are the standard settlement period and fees, which will be applied if Next Day Funding is not requested AND/OR is not approved by Underwriters',
  settlementPeriod: 'Settlement Period (business days)',
  zeroPlaceholder: '0.00',
  settlementFee: 'Settlement fee (per settlement) (£)',
  posNextDay: 'POS. Next Day Funding. Settlements Terms',
  posNextDayNote: (
    <>
      Please only use this if NEXT DAY FUNDING (T+1) was requested. Otherwise proceed to the next section.
      <br />
      Next Day Funding fees are only applied ONLY IF Next Day Funding is requested AND approved
    </>
  ),
  nextDayFundingRequested: 'Next day funding requested ',
  nextDayFundingFeePerSettlement: 'Next day funding fee (per settlement) (£)',
  feeAppliedForEachSettlement: 'Fee applied for processing of each Settlement',
  nextDayFundingFeePerMonth: 'Next day funding fee (monthly) (£)',
  ecomStandardPeriod: 'ECOM. Standard Period. Settlements Terms',
  ecomNextDay: 'ECOM. Next Day Funding. Settlements Terms',
  save: 'Save',
  saveForLater: 'Save for later',
  cancel: 'Cancel',
  feeReducing: 'Fee applied for reducing the Remittance Timescale to 1 Business Day'
})
