import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  profile: string
  accounts: string
  terminals: string
  riskLimits: string
  info: string
  closureOfMerchant: string
  closureOfTerminal: string
  changeOfSettlementPeriod: string
  terminalIssue: string
  documentsRequired: string
  additionalStore: string
  changeSettlementType: string
  holdOrReleaseSettlements: string
}>()
