import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  editTariffs: string
  getTabLabel: (id: string) => string
  pos: string
  ecom: string
  cancel: string
  back: string
  terminalTariffs: string
  editViewTariffs: string
  tids: string
}>()
