import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Typography, Checkbox, Select, Input, Button } from 'antd'
import { settlementCodes } from '../Stores/constants'
import { IChangeSettlementTypeStore } from './IChangeSettlementTypeStore'
import translations from './translations'
import styles from './ChangeSettlementType.scss'

export const ChangeSettlementTypeStoreSymbol = Symbol('ChangeSettlementTypeStore')
export const ChangeSettlementType = observer(() => {
  const store = useInjection<IChangeSettlementTypeStore>(ChangeSettlementTypeStoreSymbol)
  const [settlementForm] = Form.useForm()

  useEffect(() => {
    store.reset()
    settlementForm.resetFields()
  }, [store.merchantsManagementStore.selectedMerchant])

  return (
    <div className={styles.formContainer}>
      <Form form={settlementForm} onFinish={store.handleSubmitSettlement}>
        <Typography.Title level={5}>
          {!store.settlementType.code
            ? translations().errorDefiningSettlementType
            : `${translations().settlementType} ${store.settlementType.label}`}
        </Typography.Title>
        <div className={styles.checkboxGrid}>
          {store.settlementType.code === settlementCodes.dailyNet && (
            <Form.Item>
              <Checkbox checked={store.isChangeOfGrossSettlement} onChange={() => store.setIsChangeOfGrossSettlement()}>
                {translations().changeGrossSettlementConfirmation}
              </Checkbox>
            </Form.Item>
          )}
          {(store.settlementType.code === settlementCodes.dailyNet ||
            store.settlementType.code === settlementCodes.monthlyNet) && (
            <Form.Item>
              <Checkbox checked={store.isChangeOfDirectDebit} onChange={() => store.setIsChangeOfDirectDebit()}>
                {translations().changeDirectDebitConfirmation}
              </Checkbox>
            </Form.Item>
          )}
          {store.isChangeOfDirectDebit && (
            <>
              <Form.Item label={translations().chargeMerchant}>
                <Select defaultValue='MID' onChange={store.handleChargeMethod} options={store.changeMethodsOptions} />
              </Form.Item>
              <Form.Item label={translations().mandate} required={store.isChangeOfDirectDebit}>
                <Input onChange={e => store.handleMandate(e.target.value)} value={store.mandate} />
              </Form.Item>
            </>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type='primary'
            onClick={() => settlementForm.submit()}
            disabled={
              (!store.isChangeOfGrossSettlement && !store.isChangeOfDirectDebit) ||
              (store.isChangeOfDirectDebit && !store.mandate) ||
              !store.storesDossierV2Store.isMerchantActive
            }
            loading={store.isChangeSettlementLoading}
          >
            {translations().submit}
          </Button>
        </div>
      </Form>
    </div>
  )
})
